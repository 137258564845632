import { Outlet } from "react-router-dom";
import DiscoverTabs from "../../components/DiscoverTabs.jsx";

function Discover() {
  return (
    <>
      {/*<div className="w-full flex-1 gap-4 text-center">*/}
      <Outlet />
      {/*</div>*/}
      <DiscoverTabs />
    </>
  );
}

export default Discover;
