import React from "react";
import { useSelector, useDispatch } from "react-redux";
import property_perks from "../../data/property_perks.js";
import SelectableTag from "../SelectableTag.jsx";
import SelectPerks from "@src/components/selectPerks/SelectPerks.jsx";
import { togglePropertyPerk } from "../../store/features/search/slice.js";

function SearchPerks() {
  const { selectedPropertyPerks } = useSelector((state) => state.search);
  const dispatch = useDispatch();

  const handleTagChange = ({ key }) => {
    dispatch(togglePropertyPerk(key));
  };

  const isPerkSelected = (id) => {
    return selectedPropertyPerks.includes(id);
  };

  return (
    <div className="mt-6">
      <SelectPerks
        prefix="search"
        onChange={handleTagChange}
        values={selectedPropertyPerks}
      />
    </div>
  );
}

export default SearchPerks;
