import React from 'react'
import * as Slider from "@radix-ui/react-slider";
import {useState, useEffect} from "react";

function MultiSelectRadioGroup({label, values, items, allowMultiple, onChange, note}) {


    const [selections, setSelections] = useState([0]);

    const onItemClicked = (e) => {

        const index = parseInt(e.target.dataset.index);

        if(!allowMultiple){
            setSelections([index]);
            return;
        }

        if (index === 0) {
            setSelections([0]);
        } else {
            let newSelectedIndexes = [...selections];

            if (newSelectedIndexes.includes(0)) {
                newSelectedIndexes = [];
            }

            if (newSelectedIndexes.includes(index)) {
                newSelectedIndexes = newSelectedIndexes.filter((item) => item !== index);
            } else {
                newSelectedIndexes.push(index);
            }

            const numSelectedIndexes = newSelectedIndexes.filter((item) => item !== 0);

            if (numSelectedIndexes.length > 0) {
                const min = Math.min(...numSelectedIndexes);
                const max = Math.max(...numSelectedIndexes);
                newSelectedIndexes = Array.from({ length: max - min + 1 }, (_, i) => i + min).filter((item) => item !== 0);
            }else{
                newSelectedIndexes = [0];
            }

            setSelections(newSelectedIndexes);
        }
    }

    useEffect(() => {
        onChange(selections);
    }, [selections])


    return (
        <div className="flex flex-col gap-4">
            <header className="flex flex-row items-center justify-between">
                <label className="text-gray-600 font-semibold">{label}</label>
                {allowMultiple && <span className="font-light text-xs text-gray-400">Tap 2 to select range</span>}
                {!allowMultiple && note && <span className="font-light text-xs text-gray-400">{note}</span>}
            </header>
            <div className="flex flex-row text-sm text-gray-700">
                {items.map((item, index) => (
                    <button onClick={(e) => onItemClicked(e)} data-index={index} key={index} className={`flex-1 w-full first:rounded-tl-md first:rounded-bl-md border border-gray-300 border-l-transparent first:border-l-gray-300 p-3 py-4 last:rounded-tr-md last:rounded-br-md ${values.includes(item) && 'bg-primary-500 text-white'}`}>{item}</button>
                ))}
            </div>
        </div>
    )
}

export default MultiSelectRadioGroup
