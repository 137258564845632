import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getRoutedPropertyById } from "../store/features/property/service.js";
import PropertyProfileContent from "../components/propertyProfile/propertyProfileContent/PropertyProfileContent.jsx";
import { isFulfilled } from "@reduxjs/toolkit";
import NotFound from "./notFound/NotFound.jsx";
import { AnimatePresence, motion } from "framer-motion";
import SearchSpinner from "@src/components/searchSpinner/SearchSpinner.jsx";

function PropertyProfile() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [notFound, setNotFound] = useState(false);
  const { currentRoutedProperty } = useSelector((state) => state.property);

  const getPropertyDetails = async () => {
    const response = await dispatch(getRoutedPropertyById(id));

    if (!isFulfilled(response)) setNotFound(true);
  };

  useEffect(() => {
    setNotFound(false);
    getPropertyDetails();
  }, [id]);

  if (notFound) return <NotFound />;

  return (
    <div className="h-full">
      {currentRoutedProperty ? (
        <PropertyProfileContent property={currentRoutedProperty} />
      ) : (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute left-0 top-0 h-full w-full bg-white/90"
          >
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
              <SearchSpinner label="Fetching property..." />
            </div>
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
}

export default PropertyProfile;
