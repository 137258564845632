import { getRequest, patchRequest, postRequest } from "@src/utils/axiosClient";

export async function createProposal(proposal) {
  try {
    const response = await postRequest({
      url: "/proposals/",
      payload: {
        buyer_message: proposal.buyerMessage,
        move_timeline_max: proposal.timelineMax,
        move_timeline_min: proposal.timelineMin,
        package_type: proposal.packageType,
        property_id: proposal.propertyId,
      },
    });

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function updateProposal({ proposalId, proposal }) {
  try {
    const response = await patchRequest({
      url: `/proposal/${proposalId}`,
      payload: proposal,
    });

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function getProposalPackages() {
  try {
    const response = await getRequest({ url: "/packages/" });

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function completeProposalWithZeroPayment(proposalId) {
  try {
    const response = await postRequest({
      url: `/proposals/${proposalId}/complete/`
    });

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}
