import {
  MdOutlineBathtub,
  MdOutlineBed,
  MdOutlineDirectionsCar,
  MdOutlineSquareFoot,
  MdOutlineStairs,
} from "react-icons/md";

// This array is currently based on the properties that come back with a home from the back end
const home_details = [
  {
    id: "bedrooms",
    owner_id: "owner_given_bedrooms",
    icon: MdOutlineBed,
    labelSingular: "bed",
    labelPlural: "beds",
    min: 1,
  },
  {
    id: "bathrooms",
    owner_id: "owner_given_bathrooms",
    icon: MdOutlineBathtub,
    labelSingular: "bath",
    labelPlural: "baths",
    min: 1,
    interval: 0.5,
  },
  {
    id: "building_area",
    owner_id: "owner_given_area",
    icon: MdOutlineSquareFoot,
    labelSingular: "sqft",
    labelPlural: "sqft",
    min: 25,
    interval: 25,
    roundTo: 25,
  },
  // {
  //   id: "stories",
  //   icon: MdOutlineStairs,
  //   labelSingular: "story",
  //   labelPlural: "stories",
  //   min: 1,
  // },
];

export default home_details;
