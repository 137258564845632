import React from "react";

function PropertyProfileSubheading({ property, className }) {
  if (!property.owner_headline) return null;
  return (
    <p
      className={`${className} capitalize`}
    >{`${property.city ? property.city.toLowerCase() + ", " : " "}${property.state ? property.state + " " : ""}${property?.zip ? property.zip : ""}`}</p>
  );
}

export default PropertyProfileSubheading;
