import { addPropertyOwner } from "@src/api/property";
import BaseModal from "@src/components/baseModal/BaseModal";
import { setPropertyOwner } from "@src/store/features/property/slice";
import { setRandomAndSearchPropertyOwner } from "@src/store/features/search/slice";
import { getUsersClaimedHomes } from "@src/store/features/user/service";
import React, { useState } from "react";
import { MdFlag, MdOutlineCottage, MdSearch } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

export default function ClaimHomeVerificationModal({
  open,
  onClickOverlay,
  propertyId,
  property,
}) {
  const dispatch = useDispatch();
  const homes = useSelector((state) => state.user.homes);
  const loggedInUser = useSelector((state) => state.user.user);
  const [isLoading, setisLoading] = useState(false);

  const addOwnerOfProperty = async () => {
    setisLoading(true);
    try {
      const response = await addPropertyOwner(propertyId);

      if (response?.status === "ownership updated") {
        toast.success("You have successfully claimed this home!");
        dispatch(setPropertyOwner({ propertyId, ownerId: loggedInUser.id }));
        dispatch(
          setRandomAndSearchPropertyOwner({
            propertyId,
            ownerId: loggedInUser.id,
          }),
        );
        if (homes?.length < 2) {
          dispatch(getUsersClaimedHomes({ page: 1, page_size: 2 }));
        }
      } else if (response?.error) toast.error(response.error);
    } catch (error) {
      toast.error("Sorry, we couldn't add this property to you claimed homes");
    }
    setisLoading(false);
    onClickOverlay();
  };

  return (
    <BaseModal open={open} onClickOverlay={onClickOverlay}>
      <div className="modal-shadow flex flex-col items-center rounded-2xl bg-grey-100 p-[21px] pt-12">
        <div className="absolute left-1/2 top-0 flex h-[70px] w-[70px] -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full border border-white bg-neutral-100 ring-1 ring-neutral-200">
          <MdFlag className="h-9 w-9 text-primary-500" />
        </div>
        <h2 className="mb-2 mt-2 font-attention text-2xl text-neutral-700">
          Claim this home
        </h2>
        <span className="mt-1 text-balance text-center font-content text-sm text-grey-800">
          You are about to take ownership of{" "}
          <span className="font-semibold capitalize text-black">{`${property.address_line_1 ? property.address_line_1.toLowerCase() : ", "}${property.address_line_2 ? property.address_line_2.toLowerCase() : ", "} ${property.city ? property.city.toLowerCase() + ", " : " "}${property.state ? property.state + " " : ""}${property?.zip ? property.zip : ""}`}</span>
          . Please confirm that you want to proceed with the claim.
        </span>
        <button
          disabled={isLoading}
          onClick={addOwnerOfProperty}
          className="mx-auto mb-3 mt-6 flex w-full max-w-60 justify-center gap-2 rounded-full bg-primary-500 p-3 px-6 font-semibold text-white shadow-lg shadow-primary-500/10"
        >
          {isLoading ? (
            <div className="flex items-center justify-center">
              <div className="h-6 w-6 animate-spin rounded-full border-2 border-white border-t-transparent" />
            </div>
          ) : (
            "Yes, I own this home"
          )}
        </button>

        <button
          disabled={isLoading}
          className="mb-4 mt-3 text-balance text-sm text-[#737459] underline"
          onClick={onClickOverlay}
        >
          My mistake, I do not own this home
        </button>
      </div>
    </BaseModal>
  );
}
