// src/components/MainLayout.jsx
import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Outlet } from "react-router-dom";
import PropertyProfileModal from "../components/propertyProfile/propertyProfileModal/PropertyProfileModal.jsx";
import { useSelector, useDispatch } from "react-redux";
import { getDisplayPropertyById } from "../store/features/property/service.js";
import { AnimatePresence, motion } from "framer-motion";

const MainLayout = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [isShowingProfileCard, setIsShowingProfileCard] = useState(false);
  const { currentDisplayProperty } = useSelector((state) => state.property);
  const [setShowingProperty] = useState(null);
  useEffect(() => {
    const propertyParam = searchParams.get("showing");
    if (propertyParam) {
      dispatch(getDisplayPropertyById(propertyParam));
    } else {
      dispatch(getDisplayPropertyById(null));

      setIsShowingProfileCard(false);
    }
  }, [searchParams, dispatch]);

  useEffect(() => {
    if (currentDisplayProperty) {
      setIsShowingProfileCard(true);
    } else {
      setIsShowingProfileCard(false);
    }
  }, [currentDisplayProperty]);

  return (
    <>
      <Outlet />

      <AnimatePresence>
        {currentDisplayProperty && isShowingProfileCard && (
          <motion.div
            initial={{ y: "100%" }}
            animate={{ y: 0 }}
            exit={{ y: "100%" }}
            transition={{ duration: 0.2, spring: 1.5, damping: 1 }}
            className="fixed left-0 top-0 z-20 h-full w-full"
          >
            <PropertyProfileModal property={currentDisplayProperty} />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default MainLayout;
