import { useState } from "react";
import EmailFormModal from "@src/components/emailFormModal/EmailFormModal";
import EmailConfirmation from "@src/components/emailConfirmation/EmailConfirmation";
import PopupContainer from "@src/components/popupContainer/PopupContainer";
import BaseModal from "@src/components/baseModal/BaseModal";

export default function SignInModal({
  isOpen,
  handleClose,
  redirectTo,
  type,
  isSavePropertyWrapper,
}) {
  const [email, setEmail] = useState("");
  const [isFirstStep, setIsFirstStep] = useState(true);

  return (
    <BaseModal open={isOpen} onClickOverlay={handleClose}>
      {isFirstStep ? (
        <EmailFormModal
          setIsFirstStep={setIsFirstStep}
          email={email}
          setEmail={setEmail}
          redirectTo={redirectTo}
          type={type}
          isSavePropertyWrapper={isSavePropertyWrapper}
        />
      ) : (
        <PopupContainer>
          <EmailConfirmation email={email} />
        </PopupContainer>
      )}
    </BaseModal>
  );
}
