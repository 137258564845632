import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

import { completeProposalWithZeroPayment } from "@src/api/proposal";
import OverlayLoader from "@src/components/overlayLoader/OverlayLoader";
import TermsOfUseModal from "@src/components/termsOfUseModal/TermsOfUseModal";
import { SHOW_CONVERSATION_SUCCESS } from "@src/utils/constants";

export default function PaymentCardDetails({ proposalId, paymentId, price }) {
  const navigate = useNavigate();

  const [isProcessing, setProcessingTo] = useState(false);
  const [firstTimeBound, setFirstTimeBound] = useState(true);

  const [nameValue, setNameValue] = useState("");
  const [nameValueValidation, setNameValueValidation] = useState("");

  const [isCardValid, setIsCardValid] = useState(false);
  const [isCardExpiryValid, setIsCardExpiryValid] = useState(false);
  const [isCardCvcValid, setIsCardCvcValid] = useState(false);

  const [cardNumInputValidation, setCardNumInputValidation] = useState("");
  const [cardExpiryInputValidation, setCardExpiryInputValidation] =
    useState("");
  const [cardCvcInputValidation, setCardCvcInputValidation] = useState("");

  const [isTermsOfUseVisible, setIsTermsOfUseVisible] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const titleStyles = "font-content text-lg mb-2 font-medium";

  let cardElement;
  let cardExpiryElement;
  let cardCvcElement;

  const handleNameField = (name) => {
    setNameValueValidation("");
    setNameValue(name);
    if (!name) {
      setNameValueValidation("Please enter full name!");
    } else if (!String(name).match(/^[a-zA-Z() ]+$/)) {
      setNameValueValidation(
        "Please enter valid name. Only alphabets allowed!",
      );
    } else if (name.length > 80) {
      setNameValueValidation("Please enter upto 80 alphabets only!");
    }
  };

  const handleFormWithPayment = async () => {
    setProcessingTo(true);

    if (!stripe || !elements || !paymentId) {
      setProcessingTo(false);
      return;
    }

    cardElement = elements.getElement(CardNumberElement);

    try {
      // Creating Payment Method
      const paymentMethodResponse = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });
      if (paymentMethodResponse.error) {
        toast.error(paymentMethodResponse.error.message);
        setProcessingTo(false);
        return;
      }

      // Confirming Payment / Making Payment against Payment Intent
      const confirmPaymentResponse = await stripe.confirmCardPayment(
        paymentId,
        {
          payment_method: paymentMethodResponse.paymentMethod.id,
        },
      );
      if (confirmPaymentResponse.error) {
        toast.error(confirmPaymentResponse.error.message);
        setProcessingTo(false);
        return;
      }

      localStorage.setItem(SHOW_CONVERSATION_SUCCESS, JSON.stringify(true));
      navigate(`/conversation/${proposalId}`);
    } catch {
      toast.error("We couldn't confirm your payment. Try again?");
    }
    setProcessingTo(false);
  };

  const handleFormWithZeroPayment = async () => {
    setProcessingTo(true);
    try {
      await completeProposalWithZeroPayment(proposalId);

      navigate("/");
    } catch {
      toast.error("We couldn't confirm your payment. Try again?");
    }
    setProcessingTo(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (price) {
      handleFormWithPayment();
    } else {
      handleFormWithZeroPayment();
    }
  };

  const addStripeCardElementsValidations = () => {
    // Card Number Input Validator
    cardElement.on("change", (event) => {
      setIsCardValid(!event.empty && !event.error && event.complete);
      if (event.error) {
        setCardNumInputValidation(event.error.message);
      } else {
        setCardNumInputValidation("");
      }
    });

    // Card Expiry Input Validator
    cardExpiryElement.on("change", (event) => {
      setIsCardExpiryValid(!event.empty && !event.error && event.complete);
      if (event.error) {
        setCardExpiryInputValidation(event.error.message);
      } else {
        setCardExpiryInputValidation("");
      }
    });

    // Card CVC Input Validator
    cardCvcElement.on("change", (event) => {
      setIsCardCvcValid(!event.empty && !event.error && event.complete);
      if (event.error) {
        setCardCvcInputValidation(event.error.message);
      } else {
        setCardCvcInputValidation("");
      }
    });

    setFirstTimeBound(false);
  };

  useEffect(() => {
    if (stripe && elements && firstTimeBound) {
      cardElement = elements.getElement(CardNumberElement);
      cardExpiryElement = elements.getElement(CardExpiryElement);
      cardCvcElement = elements.getElement(CardCvcElement);

      addStripeCardElementsValidations();
    }
  }, [stripe, elements]);

  return (
    <form onSubmit={handleFormSubmit}>
      {price ? (
        <div>
          <p className="mt-6 text-center font-attention text-xl font-semibold">
            Credit/Debit Card Details
          </p>
          <div className="mt-2">
            <p className={titleStyles}>Name on Card*</p>
            <input
              name="name"
              type="text"
              value={nameValue}
              onChange={(e) => handleNameField(e.target.value)}
              className="disabled:transparent h-[42px] w-full rounded-lg border-2 border-grey-200 bg-gold-200 p-4 font-content focus:outline-none"
            />
            <p className="text-[red]">{nameValueValidation}</p>
          </div>

          <div className="mt-2">
            <p className={titleStyles}>Card Number*</p>
            <CardNumberElement
              options={{ placeholder: "0000 - 0000 - 0000 - 0000" }}
              className="rounded-lg border-2 border-grey-200 bg-gold-200 p-3 font-content"
            />
            <p className="text-[red]">{cardNumInputValidation}</p>
          </div>
          <div className="mt-2 flex gap-8">
            <div className="w-[50%]">
              <p className={titleStyles}>Expiry*</p>
              <CardExpiryElement className="mt-2 w-[84px] rounded-lg border-2 border-grey-200 bg-gold-200 p-3 font-content" />
              <p className="text-[red]">{cardExpiryInputValidation}</p>
            </div>
            <div className="w-[50%]">
              <p className={titleStyles}>CVV*</p>
              <CardCvcElement className="w-[58px] rounded-lg border-2 border-grey-200 bg-gold-200 p-3 font-content" />
              <p className="text-[red]">{cardCvcInputValidation}</p>
            </div>
          </div>
        </div>
      ) : null}
      <div className="mt-2 flex flex-col justify-end">
        <p className="mr-1 flex items-center gap-1 self-end">
          <p className="font-content text-xl font-semibold">Total:</p>
          <p className="font-content text-xl">{`${price} $`}</p>
        </p>
        <button
          type="submit"
          disabled={
            (!stripe ||
              isProcessing ||
              !(isCardValid && isCardExpiryValid && isCardCvcValid) ||
              !nameValue ||
              nameValue.length > 80 ||
              !String(nameValue).match(/^[a-zA-Z() ]+$/)) &&
            price
          }
          className="mt-4 self-end rounded-xl border-[1px] bg-sage-400 px-4 py-3 font-medium uppercase text-black"
        >
          Send your package
        </button>
      </div>
      <p className="mt-4 font-content">
        By sending your package you agree to the{" "}
        <p
          className="text-sage-600 no-underline"
          onClick={() => setIsTermsOfUseVisible(true)}
        >
          terms and conditions.
        </p>
      </p>
      {isProcessing ? <OverlayLoader /> : null}
      {isTermsOfUseVisible ? (
        <TermsOfUseModal
          open={isTermsOfUseVisible}
          onClickOverlay={() => setIsTermsOfUseVisible(false)}
        />
      ) : null}
    </form>
  );
}
