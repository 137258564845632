import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { addPropertyOwner } from "@src/api/property";
import { PROPERTY_TO_SAVE } from "@src/utils/constants";
import SignInModal from "@src/components/signInModal/SignInModal";
import { getUsersClaimedHomes } from "@src/store/features/user/service";
import { toast } from "react-toastify";

export default function SaveProperty({ children, modalType }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const showLoginPrompt = searchParams.get("showLoginPrompt");
  const location = useLocation();
  const redirectTo = location.pathname?.split("?")?.[0]?.replace(/\/$/, "");

  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const homes = useSelector((state) => state.user.homes);

  const [showSigninModal, setShowSigninModal] = useState(false);

  useEffect(() => {
    const propertyId = JSON.parse(localStorage.getItem(PROPERTY_TO_SAVE));

    const addOwnerOfProperty = async () => {
      try {
        const response = await addPropertyOwner(propertyId);

        if (response?.status === "ownership updated") {
          toast.success("You have successfully claimed this home!");
        }
        else if (response?.error) toast.error(response.error);

        if (homes?.length < 2) {
          dispatch(getUsersClaimedHomes({ page: 1, page_size: 2 }));
        }
        localStorage.removeItem(PROPERTY_TO_SAVE);
        navigate(redirectTo, { replace: true });
      } catch (error) {
        navigate("home");
      }
    };

    if (showLoginPrompt && propertyId) {
      if (isLoggedIn) addOwnerOfProperty();
      else setShowSigninModal(true);
    }
  }, []);

  return (
    <>
      {children}
      {showSigninModal ? (
        <SignInModal
          isOpen={showSigninModal}
          redirectTo={redirectTo}
          type={modalType}
          isSavePropertyWrapper
        />
      ) : null}
    </>
  );
}
