import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import PropertyProfileImage from "../propertyProfileImage/PropertyProfileImage.jsx";
import {
  MdFlag,
  MdOutlineEdit,
  MdOutlineIosShare,
} from "react-icons/md";
import PropertyProfileEditor from "@src/components/propertyProfile/propertyProfileEditor/PropertyProfileEditor.jsx";
import PropertyProfileDetails from "@src/components/propertyProfile/propertyProfileDetails/PropertyProfileDetails.jsx";
import { toast } from "react-toastify";
import PropertyProfileChatButton from "@src/components/propertyProfile/propertyProfileButtons/PropertyProfileChatButton.jsx";
import { SIGNIN_MODAL_TYPE } from "@src/utils/constants";
import SignInModal from "@src/components/signInModal/SignInModal.jsx";
import PropertyProfileFavoriteButton from "@src/components/propertyProfile/propertyProfileButtons/PropertyProfileFavoriteButton";
import PropertyProfileEstimatedValueTag from "@src/components/propertyProfile/propertyProfileEstimatedValueTag/PropertyProfileEstimatedValueTag.jsx";
import PropertyProfileHeadline from "@src/components/propertyProfile/propertyProfileHeadline/PropertyProfileHeadline.jsx";
import PropertyProfileSubheading from "@src/components/propertyProfile/propertyProfileSubheading/PropertyProfileSubheading.jsx";
import ClaimHomeVerificationModal from "./components/claimHomeVerificationModal/ClaimHomeVerificationModal.jsx";

function PropertyProfileContent({ property }) {
  const navigate = useNavigate();
  //const streetViewUrl = `https://maps.googleapis.com/maps/api/streetview?size=640x640&location=${property.latitude},${property.longitude}&fov=80&heading=70&pitch=0&scale=2&key=${import.meta.env.VITE_GOOGLE_MAPS_API_KEY}`;
  const [searchParams, setSearchParams] = useSearchParams();
  const shouldClaimHome = searchParams.get("claimHome");
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const loggedInUser = useSelector((state) => state.user.user);
  const homes = useSelector((state) => state.user.homes);

  const [isEditing, setIsEditing] = useState(false);
  const [signinModalType, setSigninModalType] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");
  const [isClaimHomeModalVisible, setIsClaimHomeModalVisible] = useState(false);

  function handleEdit() {
    const newSearchParams = new URLSearchParams(searchParams);
    const hasEditParam = searchParams.get("edit");

    if (hasEditParam) {
      newSearchParams.delete("edit");
      setIsEditing(false);
    } else {
      newSearchParams.set("edit", "true");
      setIsEditing(true);
    }

    setSearchParams(newSearchParams);
  }

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: property.address_line_1,
          text: `Check out this property at ${property.address_line_1}, ${property.city}, ${property.state}`,
          url: `${window.location.origin}/profile/${property.id}`,
        });
      } catch (error) {
        console.error("Error sharing", error);
      }
    } else {
      alert("Sharing is not supported in this browser.");
    }
  };

  const handleChat = () => {
    setRedirectUrl(`/conversations/create/${property?.id}`);
    if (!isLoggedIn) {
      setSigninModalType(SIGNIN_MODAL_TYPE.CHAT);
      return;
    }

    if (property?.owner_id !== loggedInUser.id)
      navigate(`/conversations/create/${property?.id}`);
    else toast.error("You own this property!");
  };

  const handleClaimHome = () => {
    if (!isLoggedIn) {
      setRedirectUrl(`/profile/${property.id}?claimHome=true`);
      setSigninModalType(SIGNIN_MODAL_TYPE.PROPERTY);
      return;
    }
    setIsClaimHomeModalVisible(true);
  };

  const onClickSignModalOverlay = () => {
    setSigninModalType("");
    setRedirectUrl("");
  };

  const onClickClaimHomeModalOverlay = () => {
    setIsClaimHomeModalVisible(false);
    setRedirectUrl("");
  };

  useEffect(() => {
    if (!searchParams.get("edit")) {
      setIsEditing(false);
    }
  }, [searchParams]);

  useEffect(() => {
    if (shouldClaimHome && loggedInUser.id) {
      setSearchParams({});
      if (!property?.owner_id) setIsClaimHomeModalVisible(true);
      else if (property?.owner_id === loggedInUser.id) {
        toast.error("You already own this property!");
      }
    }
  }, [loggedInUser.id]);

  return (
    <motion.div className="relative flex h-full flex-col gap-4 p-0">
      <div className="relative flex h-full w-full">
        <PropertyProfileImage property={property} />
        <div className="absolute bottom-0 left-0 right-0 flex h-1/2 flex-col items-start justify-end bg-gradient-to-t from-black/70 to-transparent p-4 pb-6 text-left">
          <PropertyProfileEstimatedValueTag property={property} />

          <PropertyProfileHeadline
            property={property}
            className="mb-1 mt-2 font-attention text-2xl font-normal capitalize text-white"
          />
          <PropertyProfileSubheading
            property={property}
            className="mb-2 text-sm text-white"
          />
          <div className="mt-1">
            <PropertyProfileDetails property={property} />
          </div>

          <div className="mt-3 flex w-full items-center justify-between">
            <div className="flex flex-row gap-3">
              {property?.owner_id !== loggedInUser.id ? (
                <PropertyProfileChatButton onClick={handleChat} />
              ) : null}
              <PropertyProfileFavoriteButton
                propertyId={property?.id}
                isLiked={property?.liked}
              />
              <button
                className="flex aspect-square h-10 w-10 items-center justify-center rounded-full bg-black/60 p-1.5"
                onClick={handleShare}
              >
                <MdOutlineIosShare className="h-6 w-6 -translate-y-[1px] text-white/60" />
              </button>
            </div>
            {(!loggedInUser && !property?.owner_id) ||
            (loggedInUser && !property?.owner_id && homes.length < 1) ? (
              <button
                onClick={handleClaimHome}
                className="flex items-center justify-center gap-2 rounded-lg border border-white/40 bg-black/60 p-1.5 px-5 pl-3 text-white/60"
              >
                <MdFlag className="" /> <span>Claim</span>
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <AnimatePresence>
        {isEditing ? <PropertyProfileEditor property={property} /> : null}
      </AnimatePresence>

      {loggedInUser?.id === property?.owner_id && (
        <button
          className={`fixed bottom-5 right-5 z-20 flex h-12 ${isEditing ? "w-fit px-6" : "w-12"} items-center justify-center rounded-full bg-black/80 shadow-md transition-all duration-300`}
          onClick={handleEdit}
        >
          {isEditing ? (
            <span className="text-white">Done</span>
          ) : (
            <MdOutlineEdit className="h-6 w-6 fill-white" />
          )}
        </button>
      )}

      {signinModalType ? (
        <SignInModal
          isOpen={!!signinModalType}
          handleClose={onClickSignModalOverlay}
          redirectTo={redirectUrl}
          type={signinModalType}
        />
      ) : null}
      {isClaimHomeModalVisible ? (
        <ClaimHomeVerificationModal
          open={isClaimHomeModalVisible}
          onClickOverlay={onClickClaimHomeModalOverlay}
          propertyId={property?.id}
          property={property}
        />
      ) : null}
    </motion.div>
  );
}

export default PropertyProfileContent;
