import React from "react";

function CardsViewIntroIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 15"
      style={{ enableBackground: "new 0 0 15 15" }}
    >
      <style type="text/css">
        {`.st1 { fill: #FFFFFF; stroke: #000000; stroke-width: 0.75; stroke-linecap: round; stroke-linejoin: round; stroke-miterlimit: 10; }`}
      </style>
      <g>
        <path d="M7.6,0.8L7.6,0.8c0.1,0,0.2,0,0.3,0L12,1.6c0.9,0.2,1.4,1,1.3,1.9l-2,9.4c-0.2,0.7-0.8,1.3-1.6,1.3c-0.1,0-0.2,0-0.3,0 l-4.1-0.9c-0.9-0.2-1.4-1-1.3-1.9L6,2C6.1,1.3,6.8,0.8,7.6,0.8 M7.6,0C6.5,0,5.5,0.8,5.2,1.9l-2,9.4c-0.3,1.3,0.6,2.5,1.8,2.8 L9.3,15c0.2,0,0.3,0,0.5,0c1.1,0,2.1-0.8,2.3-1.9l2-9.4c0.3-1.3-0.6-2.5-1.8-2.8L8,0C7.9,0,7.7,0,7.6,0L7.6,0z" />
      </g>
      <g>
        <path d="M2.5,12.1c-0.9,0-1.6-0.7-1.6-1.6V3.6c0-0.9,0.7-1.6,1.6-1.6h1.9l0,0.6L3.9,2.6H2.5c-0.6,0-1,0.5-1,1v6.9 c0,0.4,0.2,0.7,0.5,0.9c0.2,0.1,0.5,0.1,0.5,0.1C2.5,11.8,2.5,11.9,2.5,12.1L2.5,12.1L2.5,12.1z" />
        <path d="M4.5,2L4.4,2.5l-0.5,0H2.5C1.9,2.5,1.5,3,1.5,3.6v6.9c0,0.4,0.2,0.8,0.6,0.9c0.2,0.1,0.5,0.1,0.5,0.1c0,0.2,0,0.3,0,0.5 C1.6,12,1,11.3,1,10.5V3.6C1,2.7,1.7,2,2.5,2H4.5 M4.6,1.9H4.5H2.5c-0.9,0-1.7,0.8-1.7,1.7v6.9c0,0.9,0.7,1.7,1.6,1.7l0.1,0l0-0.1 c0-0.1,0-0.3,0-0.5l0-0.1l-0.1,0c0,0-0.2,0-0.4-0.1c-0.3-0.2-0.5-0.5-0.5-0.8V3.6c0-0.5,0.4-1,1-1h1.3l0.5,0l0.1,0l0-0.1L4.6,2 L4.6,1.9L4.6,1.9z" />
      </g>
      <polyline className="st1" points="7.4,7.3 8.1,8.4 10.3,6.8" />
    </svg>
  );
}

export default CardsViewIntroIcon;
