import React from "react";
import home_details from "@src/data/home_details.js";
import { formatNumber } from "@src/utils/misc.js";

function PropertyProfileDetails({ property, fontSize, textColor, gapSize }) {
  const foundDetails = home_details
    .filter(
      (prop) => property[prop.id] !== null || property[prop.owner_id] !== null,
    )
    //then map over the filtered details and return an object with the icon and value
    .map((prop) => {
      //we know of the two is not null, so we can return the one that is not null, giving preference to the owner_id
      const value = property[prop.owner_id] || property[prop.id];
      //return the object with the icon and value
      return {
        id: prop.id,
        value: value,
        label: value > 1 ? prop.labelPlural : prop.labelSingular,
      };
      // }
    });

  const bedrooms = foundDetails.find(({ id }) => id === "bedrooms");
  const bathrooms = foundDetails.find(({ id }) => id === "bathrooms");
  const building_area = foundDetails.find(({ id }) => id === "building_area");

  return (
    <div
      className={`flex flex-row ${gapSize ? gapSize : "gap-3"} pb-3 font-content opacity-90 ${fontSize ? fontSize : "text-sm"} ${textColor ? textColor : "text-white"}`}
    >
      {bedrooms?.value > 0 && (
        <Detail value={bedrooms.value} label={bedrooms.label} />
      )}
      {bathrooms?.value > 0 && (
        <Detail value={bathrooms.value} label={bathrooms.label} />
      )}
      {building_area?.value > 0 && (
        <Detail
          value={formatNumber(building_area.value)}
          label={building_area.label}
        />
      )}
    </div>
  );
}

const Detail = ({ value, label = "" }) => {
  return (
    <div className="flex flex-row items-center justify-end gap-1">
      <span className="whitespace-nowrap">
        <span className="text font-semibold tracking-tighter">{value}</span>{" "}
        <span className="font-light">{label}</span>
      </span>
    </div>
  );
};

export default PropertyProfileDetails;
