import React from "react";
import { useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";

function MoreListItem({ itemName, navigationUrl }) {
  const navigate = useNavigate();
  return (
    <div
      className="h-[60%] border-b"
      onClick={() => navigate(`${navigationUrl}`)}
    >
      <div className="flex h-[60%] flex-row py-6">
        <div className="w-[90%] font-content text-base font-semibold text-black">
          {itemName}
        </div>
        <button className="cursor-pointer">
          <IoIosArrowForward className="self-center text-2xl text-grey-600" />
        </button>
      </div>
    </div>
  );
}

export default MoreListItem;
