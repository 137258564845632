import MoreListItem from "@src/components/moreListItem/MoreListItem";

function More() {
  const items = [
    { itemName: "FAQs", navigationUrl: "/faqs" },
    { itemName: "Privacy policy", navigationUrl: "/privacypolicy" },
    { itemName: "California privacy policy", navigationUrl: "/californiaprivacypolicy" },
    { itemName: "Terms and conditions", navigationUrl: "/terms-of-use" },
    { itemName: "Report abuse", navigationUrl: "/reportabuse" },
    { itemName: "Contact Us", navigationUrl: "/contactus" },
  ];
  return (
    <div className="px-6 pt-20">
      <div>
        {items.map((item, index) => (
          <MoreListItem
            key={index}
            itemName={item.itemName}
            navigationUrl={item.navigationUrl}
          />
        ))}
      </div>
    </div>
  );
}
export default More;
