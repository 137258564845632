import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { MdCheck, MdChevronRight, MdOutlineCottage } from "react-icons/md";
import PropertyProfileImage from "@src/components/propertyProfile/propertyProfileImage/PropertyProfileImage.jsx";
import TagsValueDisplay from "@src/components/TagsValueDisplay.jsx";
import SelectableTag from "@src/components/SelectableTag.jsx";
import { toast } from "react-toastify";
import { setPropertyPropValue } from "@src/store/features/property/slice.js";
import PropertyProfileEditableItemPanel from "@src/components/propertyProfile/propertyProfileEditor/PropertyProfileEditableItemPanel.jsx";
import PropertyProfileEditorTextInput from "@src/components/propertyProfile/propertyProfileEditor/propertyProfileEditorFormItems/propertyProfileEditorTextInput/PropertyProfileEditorTextInput.jsx";
import { useSearchParams } from "react-router-dom";
import PropertyProfileEditorCurrencyInput from "@src/components/propertyProfile/propertyProfileEditor/propertyProfileEditorFormItems/propertyProfileEditorCurrencyInput/PropertyProfileEditorCurrencyInput.jsx";
import { formatNumber } from "@src/utils/misc.js";
import SearchArchitecturalStyles from "@src/components/search/SearchArchitecturalStyles.jsx";
import SelectArchitecturalStyles from "@src/components/selectArchitecturalStyles/SelectArchitecturalStyles.jsx";
import architectural_styles from "@src/data/architectural_styles.js";
import home_details from "@src/data/home_details.js";
import property_perks from "@src/data/property_perks.js";
import selling_choices from "@src/data/selling_choices.js";
import PropertyProfileEditorIncrementer from "@src/components/propertyProfile/propertyProfileEditor/propertyProfileEditorFormItems/propertyProfileEditorIncrementer/PropertyProfileEditorIncrementer.jsx";
import SelectPerks from "@src/components/selectPerks/SelectPerks.jsx";
import PropertyProfileEditorTexAreaInput from "@src/components/propertyProfile/propertyProfileEditor/propertyProfileEditorFormItems/propertyProfileEditorTextAreaInput/propertyProfileEditorTextAreaInput.jsx";
import {
  getDisplayPropertyById,
  getRoutedPropertyById,
  updateProperty,
} from "@src/store/features/property/service.js";
import * as RadioGroup from "@radix-ui/react-radio-group";
import PropertyProfileMainImageUpdater from "@src/components/propertyProfile/propertyProfileEditor/propertyProfileEditorFormItems/propertyProfileMainImageUpdater/PropertyProfileMainImageUpdater.jsx";
import UpdatePropertyProfilePerks from "@src/components/updatePropertyProfilePerks/UpdatePropertyProfilePerks.jsx";

function PropertyProfileEditor({ property }) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentEditableItemId } = useSelector((state) => state.property);
  const [editableItem, setEditableItem] = useState(null);
  const [isEditingItem, setIsEditingItem] = useState(false);
  const [updatedProperty, setUpdatedProperty] = useState(false);

  const editableItems = [
    {
      id: "address",
      toastLabel: "Address",
      displayComponent: createEditableItem({
        id: "address",
        label: "Address (Private)",
        values: (() => {
          const valueToReturn =
            `${property?.address_line_1?.toLowerCase() ? property?.address_line_1?.toLowerCase() + ", " : ""} ${property.city ? property.city.toLowerCase() + ", " : " "}${property.state ? property.state + " " : ""}${property?.zip ? property.zip : ""}` ||
            "Not Added";
          return [{ value: valueToReturn }];
        })(),
        canEdit: false,
      }),
    },
    {
      id: "headline",
      toastLabel: "Headline",
      displayComponent: createEditableItem({
        id: "headline",
        label: "Headline",
        capitalize: false,
        placeholder: "Give your home a name or headline",
        // canEdit: false,
        values: [{ value: property.owner_headline || property.headline || "" }],
        onClick: () => handleEditableItemClick("headline"),
      }),
      inputComponent: createTextInputComponent({
        id: "headline",
        title: "Give your home a captivating headline or unique name",
        placeholder: "My Humble Homestead",
        value: property.owner_headline || property.headline || "",
        onChange: (value) => {
          setUpdatedProperty(true);
          dispatch(setPropertyPropValue({ key: "owner_headline", value }));
        },
      }),
    },
    {
      id: "owner_expected_value",
      toastLabel: "Estimated Price",
      displayComponent: createEditableItem({
        id: "owner_expected_value",
        label: "Estimated Price",
        values: [
          {
            value: `$${formatNumber(property.owner_expected_value || property.estimated_value || 0)}`,
          },
        ],
        onClick: () => handleEditableItemClick("owner_expected_value"),
      }),
      inputComponent: createCurrencyInputComponent({
        id: "owner_expected_value",
        title: "If you sold your home today, how much would you ask for?",
        placeholder: "250,000",
        value: property.owner_expected_value || property.estimated_value || 0,
        onChange: (value) => {
          setUpdatedProperty(true);
          dispatch(
            setPropertyPropValue({ key: "owner_expected_value", value }),
          );
        },
      }),
    },
    {
      id: "cover_photo_url",
      displayComponent: <PropertyProfileMainImageUpdater property={property} />,
    },
    // {
    //   id: "all_photos",
    //
    //   displayComponent: createEditableItem({
    //     id: "all_photos",
    //     label: "All Photos",
    //     canEdit: false,
    //     values: [{ icon: null, value: `${12} added` }],
    //     onClick: () => handleEditableItemClick("all_photos"),
    //   }),
    // },
    {
      id: "style",
      toastLabel: "Architectural Style",
      displayComponent: createEditableItem({
        id: "style",
        label: "Architectural Style",
        capitalize: true,
        placeholder: "Not added",
        values: [
          {
            icon: MdOutlineCottage,
            value: (() => {
              if (
                (property.owner_given_style || property.style) &&
                architectural_styles.find(
                  (style) =>
                    style.id === property.owner_given_style ||
                    style.id === property.style,
                )
              ) {
                if (property.owner_given_style) {
                  const styleNames = [property.owner_given_style].map((id) => {
                    const style = architectural_styles.find(
                      (style) => style.id === id,
                    );
                    return style.label;
                  });
                  return styleNames.join(", ");
                } else if (property.style) {
                  const styleNames = [property.style].map((id) => {
                    const style = architectural_styles.find(
                      (style) => style.id === id,
                    );
                    return style.label;
                  });

                  return styleNames.join(", ");
                }
              }
              return "Other";
            })(),
          },
        ],
        onClick: () => handleEditableItemClick("style"),
      }),
      inputComponent: (
        <div className="flex w-full flex-1 flex-col items-center justify-center overflow-hidden">
          <h1 className="text-balance p-4 px-8 pt-0 text-center font-attention text-2xl font-light leading-[130%] text-neutral-600 sm:text-3xl md:pt-8">
            What is your home&apos;s architectural style?
          </h1>

          <div className="w-full flex-1 overflow-y-auto pb-12">
            <SelectArchitecturalStyles
              id="style"
              title="Select Architectural Styles"
              prefix="set"
              allowMultiple={false}
              value={[property.owner_given_style] || [property.style] || []}
              onChange={(styles) => {
                setUpdatedProperty(true);
                dispatch(
                  setPropertyPropValue({
                    key: "owner_given_style",
                    value: styles[0],
                  }),
                );
              }}
            />
          </div>
        </div>
      ),
    },
    {
      id: "home_details",
      toastLabel: "Home Details",
      displayComponent: createEditableItem({
        id: "home_details",
        label: "Home Details",
        //generate values with a self calling function that
        //filters out the home details existing on the property that are not null...
        values: (() => {
          const foundDetails = home_details
            // .filter(
            //   (prop) =>
            //     property[prop.id] !== null || property[prop.owner_id] !== null,
            // )
            //then map over the filtered details and return an object with the icon and value
            .map((prop) => {
              //we know of the two is not null, so we can return the one that is not null, giving preference to the owner_id
              const value = property[prop.owner_id] || property[prop.id] || 0;
              //return the object with the icon and value
              return {
                icon: prop.icon,
                value: `${formatNumber(value)} ${value > 1 ? prop.labelPlural : prop.labelSingular}`,
              };
              // }
            });
          return foundDetails;
        })(),
        onClick: () => handleEditableItemClick("home_details"),
      }),
      inputComponent: (() => {
        //filter out the home details that are not null
        const foundDetails = home_details
          // .filter(
          //   (prop) =>
          //     property[prop.id] !== null || property[prop.owner_id] !== null,
          // )
          //then map over the filtered details and return an object with the icon and value
          .map((prop) => {
            return {
              ...prop,
              value: property[prop.owner_id] || property[prop.id] || 0,
            };
          });
        return (
          <div className="flex h-full w-full max-w-[min(calc(100%-6rem),28rem)] flex-col gap-12">
            <div className="flex flex-col gap-4">
              <h1 className="mt-20 text-balance p-2 px-8 pt-0 text-center font-attention text-2xl font-light leading-[130%] text-neutral-600 sm:text-3xl md:pt-8">
                Home Details
              </h1>
              <p className="text-center text-sm text-neutral-600">
                Take control. Update your property data to ensure accuracy.
              </p>
            </div>

            <div className="flex flex-col gap-8">
              {foundDetails.map((prop, index) => (
                <PropertyProfileEditorIncrementer
                  prop={prop}
                  key={index}
                  onChange={(value) => {
                    setUpdatedProperty(true);
                    dispatch(
                      setPropertyPropValue({ key: prop.owner_id, value }),
                    );
                  }}
                />
              ))}
            </div>
          </div>
        );
      })(),
    },

    {
      id: "owner_blurb",
      toastLabel: "About",
      displayComponent: createEditableItem({
        id: "owner_blurb",
        label: "About",
        capitalize: false,
        placeholder: "No description added",
        truncate: false,
        textStyle: "paragraph",
        values: [
          {
            value: property.owner_blurb || "",
          },
        ],
        onClick: () => handleEditableItemClick("owner_blurb"),
      }),
      inputComponent: createTextAreaInputComponent({
        id: "owner_blurb",
        title: "Share what makes your home special",
        content:
          "Tell us about your home! Don’t forget to mention any special or unique qualities.",
        placeholder: "No description added",
        value: property.owner_blurb || "",
        onChange: (value) => {
          setUpdatedProperty(true);
          dispatch(setPropertyPropValue({ key: "owner_blurb", value }));
        },
      }),
    },
    {
      id: "owner_selling_choice",
      toastLabel: "Your Timeline",
      displayComponent: createEditableItem({
        id: "owner_selling_choice",
        label: "Your Timeline",
        capitalize: false,
        placeholder: "When you might sell your home",
        values: [
          {
            value: property.owner_selling_choice
              ? selling_choices.find(
                  ({ id }) => id === property.owner_selling_choice,
                ).value
              : "",
          },
        ],
        onClick: () => handleEditableItemClick("owner_selling_choice"),
      }),
      inputComponent: (
        <SellingChoiceRadioGroup
          value={property.owner_selling_choice}
          onChange={(newValue) => {
            setUpdatedProperty(true);
            dispatch(
              setPropertyPropValue({
                key: "owner_selling_choice",
                value: newValue,
              }),
            );
          }}
        />
      ),
    },
    {
      id: "perks",
      toastLabel: "Perks",
      displayComponent: (
        <EditableTagsItem
          label="Perks"
          canEdit={true}
          placeholder="None selected"
          values={(() => {
            return property_perks
              .filter(
                (prop) =>
                  property.owner_perks?.[prop.id] === true ||
                  (property[prop.id] === true &&
                    property.owner_perks?.[prop.id] !== false),
              )
              .map((perk) => {
                return { emoji: perk.emoji, text: perk.text };
              });
          })()}
          onClick={() => handleEditableItemClick("perks")}
        />
      ),
      inputComponent: (() => {
        return (
          <>
            <h1 className="text-balance p-4 px-8 pt-0 text-center font-attention text-2xl font-light leading-[130%] text-neutral-600 sm:text-3xl md:pt-8">
              What bonus features make your home special?
            </h1>
            <h1 className="text-balance text-center font-attention text-2xl font-light leading-[130%] text-neutral-600"></h1>
            <p className="text-center text-sm text-neutral-600"></p>
            <div className="w-full flex-1 overflow-y-auto pb-12">
              <UpdatePropertyProfilePerks
                property={property}
                onChange={() => setUpdatedProperty(true)}
              />
            </div>
          </>
        );
      })(),
    },
  ];

  const handleEditableItemClick = (id) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("item", id);
    setSearchParams(newSearchParams);
  };

  // useEffect(() => {
  //   const itemId = searchParams.get("item");
  //   const isDisplayingProperty = searchParams.get("showing");
  //   const editableItem = editableItems.find(({ id }) => id === itemId);
  //   if (editableItem) {
  //     setEditableItem(editableItem);
  //     setIsEditingItem(true);
  //   } else {
  //     setEditableItem(null);
  //     setIsEditingItem(false);
  //     dispatch(updateProperty(property));
  //   }
  // }, [searchParams]);

  useEffect(() => {
    const fetchAndUpdateProperty = async () => {
      const itemId = searchParams.get("item");
      const isDisplayingProperty = searchParams.get("showing");
      const isEditing = searchParams.get("edit");
      const editableItem = editableItems.find(({ id }) => id === itemId);

      if (editableItem) {
        console.log("reached 1");
        setEditableItem(editableItem);
        setIsEditingItem(true);
      } else {
        setEditableItem(null);
        setIsEditingItem(false);

        if (!isEditing) {
          await dispatch(
            updateProperty({ propertyData: property, isDraft: false }),
          );
        }
      }
    };

    // Call the async function
    fetchAndUpdateProperty();
  }, [searchParams]);

  const hanldeOnEditPanelExit = () => {
    const fetchAndUpdateProperty = async () => {
      const itemId = searchParams.get("item");
      const isDisplayingProperty = searchParams.get("showing");
      const isEditing = searchParams.get("edit");
      const editableItem = editableItems.find(({ id }) => id === itemId);

      let itemLabel = "Home Profile";

      if (editableItem && editableItem.toastLabel)
        itemLabel = editableItem.toastLabel;

      // Dispatch the async actions
      try {
        await dispatch(
          updateProperty({ propertyData: property, isDraft: true }),
        );
        if (isEditing && updatedProperty) {
          toast.success(`Updated ${itemLabel} successfully.`);
          setUpdatedProperty(false);
        }
        if (isDisplayingProperty) {
          await dispatch(getDisplayPropertyById(property.id));
        } else {
          await dispatch(getRoutedPropertyById(property.id));
        }
      } catch (error) {
        console.error("Failed to update and fetch property:", error);
      }
    };

    // Call the async function
    fetchAndUpdateProperty();
  };

  return (
    <>
      <motion.div
        initial={{ y: "100%" }}
        animate={{ y: 0 }}
        exit={{ y: "100%" }}
        transition={{ type: "tween", ease: "easeInOut", duration: 0.25 }}
        className="fixed left-0 top-0 z-20 flex h-full w-full flex-col gap-2 overflow-y-auto bg-white p-5 pb-20"
      >
        {editableItems.map((item, index) => (
          <div key={index}>{item.displayComponent}</div>
        ))}

        {/*<EditableItem values={[{ value: null }]} label={"About"} />*/}
      </motion.div>
      <AnimatePresence>
        {isEditingItem && editableItem && (
          <PropertyProfileEditableItemPanel
            editableItem={editableItem}
            onExit={hanldeOnEditPanelExit}
          />
        )}
      </AnimatePresence>
    </>
  );
}

const EditableItem = ({
  capitalize,
  icon: Icon,
  label,
  value,
  textStyle,
  truncate,
  placeholder,
  values,
  canEdit,
  onClick,
}) => {
  return (
    <div
      className="flex flex-row items-center justify-between border-b border-b-neutral-200 py-3"
      onClick={onClick}
    >
      <div className="flex flex-col gap-2">
        {label && (
          <label className="font-semibold text-neutral-400">{label}</label>
        )}
        {values && (
          <div className="flex flex-col gap-3">
            {values.map(({ icon: Icon, value }, index) => (
              <div key={index} className="flex items-center gap-2">
                {Icon && <Icon className="h-5 w-5 fill-neutral-500" />}
                <span
                  className={`max-w-[calc(100vw-5rem)] ${truncate ? "truncate" : ""} ${textStyle == "paragraph" ? "whitespace-pre-line font-normal leading-6" : "font-semibold"} ${capitalize ? "capitalize" : ""} ${
                    !value ? "text-neutral-300" : "text-neutral-800"
                  }`}
                >
                  {value ? value : placeholder}
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
      {canEdit && (
        <MdChevronRight className="h-7 w-7 shrink-0 fill-neutral-500" />
      )}
    </div>
  );
};

const EditableTagsItem = ({
  label,
  values = [],
  onClick,
  canEdit = true,
  placeholder,
}) => {
  return (
    <div
      onClick={() => {
        if (canEdit) onClick();
      }}
      className="flex flex-row items-center justify-between border-b border-b-neutral-200 py-3"
    >
      <div className="flex flex-col gap-2">
        {label && (
          <label className="font-semibold text-neutral-400">{label}</label>
        )}
        {values.length === 0 && (
          <p className="text-left font-semibold text-neutral-300">
            {placeholder}
          </p>
        )}
        {values.length > 0 && (
          <div className="flex flex-row flex-wrap gap-2">
            {values.map((tag, index) => {
              return (
                <SelectableTag emoji={tag.emoji} text={tag.text} key={index} />
              );
            })}
          </div>
        )}
      </div>
      {canEdit && (
        <MdChevronRight className="h-7 w-7 shrink-0 fill-neutral-500" />
      )}
    </div>
  );
};

const SellingChoiceRadioGroup = ({ value, onChange }) => {
  const [selectedValue, setSelectedValue] = useState(value);
  return (
    <>
      <h1 className="text-balance p-2 px-8 pt-0 text-center font-attention text-2xl font-light leading-[130%] text-neutral-600 sm:text-3xl md:pt-8">
        When if ever, do you plan to sell your home?
      </h1>
      <RadioGroup.Root
        className="mt-14 flex w-full max-w-[min(calc(100%-5rem),30rem)] flex-col gap-3"
        value={selectedValue}
        aria-label="View density"
        onValueChange={(newValue) => {
          setSelectedValue(newValue);
          onChange(newValue);
        }}
      >
        {selling_choices.map(({ id, value }, index) => (
          <RadioGroup.Item
            key={index}
            className="relative flex w-full rounded-lg border border-primary-500 p-4 font-attention text-lg text-neutral-800 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-[3px] focus-visible:outline-primary-500 data-[state=checked]:bg-primary-500 data-[state=checked]:text-white"
            value={id} // Ensure the value is a string
          >
            {value}
            <RadioGroup.Indicator className="absolute right-3 top-1/2 flex -translate-y-1/2 items-center justify-center rounded-full p-1 ring-1 ring-white/40">
              <MdCheck className="h-4 w-4 fill-white" />
            </RadioGroup.Indicator>
          </RadioGroup.Item>
        ))}
      </RadioGroup.Root>
    </>
  );
};

const createEditableItem = ({
  id,
  label,
  placeholder,
  values,
  capitalize = true,
  truncate = true,
  textStyle = "label",
  canEdit = true,
  onClick,
}) => (
  <EditableItem
    capitalize={capitalize}
    label={label}
    values={values}
    truncate={truncate}
    textStyle={textStyle}
    canEdit={canEdit}
    placeholder={placeholder}
    onClick={canEdit ? onClick : null}
  />
);

const createTextInputComponent = ({
  id,
  title,
  placeholder,
  value,
  onChange,
}) => (
  <PropertyProfileEditorTextInput
    id={id}
    title={title}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
  />
);

const createTextAreaInputComponent = ({
  id,
  title,
  content,
  placeholder,
  value,

  onChange,
}) => (
  <PropertyProfileEditorTexAreaInput
    id={id}
    title={title}
    content={content}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
  />
);

const createCurrencyInputComponent = ({
  id,
  title,
  placeholder,
  value,
  onChange,
}) => (
  <PropertyProfileEditorCurrencyInput
    id={id}
    title={title}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
  />
);

export default PropertyProfileEditor;
