import { useLocation, useNavigate } from "react-router-dom";
import ListItem from "@src/components/chatView/listItem/ListItem";
import { useEffect, useState } from "react";
import { getProposalSent, getProposalReceived } from "@src/api/chat";

function ChatList() {
  const location = useLocation();
  const navigate = useNavigate();

  const [chatList, setChatList] = useState([]);

  const searchParams = new URLSearchParams(location.search);
  const activeTab = searchParams.get("tab") || "homes";

  const handleTabChange = (tab) => {
    navigate(`?tab=${tab}`);
  };

  const getChatProposals = async () => {
    try {
      const response =
        activeTab == "homes"
          ? await getProposalSent()
          : await getProposalReceived();
      const chats = [];
      if (activeTab == "homes") {
        for (let proposal of response.proposals) {
          chats.push({
            property: proposal.property,
            name: proposal.property.address_line_1,
            lastMsg: proposal.last_message_text ?? proposal.buyer_message,
            lastMsgTime: proposal.last_message_time,
            unreadCount: proposal.unread_messages_count,
            isPropertyMode: true,
            proposal_id: proposal.id,
            property_name: proposal.address_line_1,
            isOwner: false,
          });
        }
      } else {
        for (let proposal of response.proposals) {
          chats.push({
            name: "Interested Party",
            lastMsg: proposal.last_message ?? proposal.buyer_message,
            lastMsgTime: proposal.last_message_time,
            unreadCount: proposal.unread_count,
            isPropertyMode: false,
            proposal_id: proposal.id,
            property_name: proposal.address_line_1,
            isOwner: true,
          });
        }
      }
      setChatList(chats);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getChatProposals();
  }, [activeTab]);

  return (
    <div className="px-6 pt-20">
      <div className="flex flex-row items-center justify-between pb-6">
        <h1 className="font-attention text-2xl text-grey-800">Chats</h1>
        <div className="flex flex-row justify-center">
          <button
            onClick={() => handleTabChange("buyers")}
            className={`px-4 py-2 text-base font-semibold ${
              activeTab === "buyers"
                ? "border-b-2 border-teal-700 text-teal-700"
                : "text-grey-600"
            }`}
          >
            Buyers
          </button>
          <button
            onClick={() => handleTabChange("homes")}
            className={`px-4 py-2 text-base font-semibold ${
              activeTab === "homes"
                ? "border-b-2 border-teal-700 text-teal-700"
                : "text-grey-600"
            }`}
          >
            Homes
          </button>
        </div>
      </div>
      <div>
        {chatList.map((item, index) => (
          <ListItem
            key={index}
            name={item.name}
            lastMsg={item.lastMsg}
            lastMsgTime={item.lastMsgTime}
            property={item.property}
            unreadCount={item.unreadCount}
            isPropertyMode={item.isPropertyMode}
            proposal_id={item.proposal_id}
            property_name={item.property_name}
            isOwner={item.isOwner}
          />
        ))}
      </div>
    </div>
  );
}

export default ChatList;
