export default function FindMyHomeIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      viewBox="0 0 55 55"
      fill="none"
    >
      <path
        d="M43.5418 34.375V17.9896L30.5939 8.9375L17.646 17.9896V24.9792H16.0418V17.2448L30.5939 6.875L45.146 17.2448V34.375H43.5418ZM32.0262 19.651H33.745V17.9323H32.0262V19.651ZM27.4429 19.651H29.1616V17.9323H27.4429V19.651ZM32.0262 24.2344H33.745V22.5156H32.0262V24.2344ZM27.4429 24.2344H29.1616V22.5156H27.4429V24.2344ZM14.2658 42.8542L32.0262 48.125L45.7762 43.8854C45.7762 43.0833 45.5889 42.52 45.2142 42.1953C44.8399 41.8707 44.4161 41.7083 43.9429 41.7083H33.2798C32.3295 41.7083 31.4342 41.6701 30.5939 41.5938C29.7536 41.5174 28.8943 41.3264 28.0158 41.0208L23.0887 39.4167L23.7189 37.8125L28.3595 39.5312C29.1234 39.8368 29.9828 40.0087 30.9377 40.0469C31.8925 40.0851 33.172 40.1042 34.7762 40.1042C34.7762 39.3021 34.6425 38.6719 34.3752 38.2135C34.1078 37.7552 33.7068 37.4306 33.172 37.2396L20.0522 32.4271C19.9377 32.3889 19.8326 32.3602 19.7371 32.3411C19.6417 32.3221 19.5366 32.3125 19.422 32.3125H14.2658V42.8542ZM6.47412 47.4375V30.7083H19.3544C19.5523 30.7083 19.7582 30.7313 19.972 30.7771C20.1859 30.8229 20.3845 30.8764 20.5679 30.9375L33.745 35.75C34.4325 36.0174 35.0436 36.5043 35.5783 37.2109C36.113 37.9175 36.3804 38.8819 36.3804 40.1042H43.9429C45.1651 40.1042 46.0531 40.4766 46.6069 41.2214C47.1608 41.9661 47.4377 42.8924 47.4377 44V45.1458L32.1408 49.8438L14.2658 44.5729V47.4375H6.47412ZM8.07829 45.8333H12.6616V32.3125H8.07829V45.8333Z"
        fill="#5F6368"
      />
    </svg>
  );
}
