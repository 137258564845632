import React, { useEffect, useState } from "react";
import * as Slider from "@radix-ui/react-slider";
import numeral from "numeral";

function RangeSelector({
  sliderValues,
  label,
  prepend,
  steps,
  min,
  max,
  onChange,
  initialValueMin, // Actual min value in the same units as min/max
  initialValueMax, // Actual max value in the same units as min/max
}) {
  // Generate the array of values based on the steps
  const generateValuesArray = (steps) => {
    const values = [];
    let currentValue = steps[0].gt;

    for (let i = 0; i < steps.length; i++) {
      const { gt, step } = steps[i];
      let nextThreshold = i < steps.length - 1 ? steps[i + 1].gt : max;
      while (currentValue < nextThreshold) {
        values.push(currentValue);
        currentValue += step;
      }
    }

    // Ensure the final value is the specified maximum (10,000,000)
    values.push(max);

    return values;
  };

  const valuesArray = generateValuesArray(steps);

  // Helper to find the closest index in the valuesArray for a given value
  const findClosestIndex = (value) => {
    let closestIndex = 0;
    let closestDiff = Math.abs(value - valuesArray[0]);

    for (let i = 1; i < valuesArray.length; i++) {
      let diff = Math.abs(value - valuesArray[i]);
      if (diff < closestDiff) {
        closestIndex = i;
        closestDiff = diff;
      }
    }

    return closestIndex;
  };

  // Convert initialValueMin and initialValueMax to corresponding index in the valuesArray
  const initialValue1 =
    initialValueMin !== undefined
      ? findClosestIndex(initialValueMin)
      : Math.ceil(valuesArray.length / 4);

  const initialValue2 =
    initialValueMax !== undefined
      ? findClosestIndex(initialValueMax)
      : Math.floor(valuesArray.length - valuesArray.length / 4);

  const [values, setValues] = useState([initialValue1, initialValue2]);
  const [rangeLabel, setRangeLabel] = useState("");

  const handleValueChange = (newValues) => {
    setValues(newValues);
  };

  useEffect(() => {
    const prefix = prepend ? prepend : "";

    const translateValue = (value) => {
      return valuesArray[value];
    };

    const formatValue = (value) => {
      let formatter = prefix === "$" ? "0.[00]a" : "0,0";
      let formatted = numeral(value).format(formatter);
      formatted = formatted.replace("m", "M"); // Replace 'm' with 'M'
      return formatted;
    };

    const formattedRangeLabel = `${prefix}${formatValue(translateValue(sliderValues[0]))} to ${prefix}${formatValue(translateValue(sliderValues[1]))}`;

    setRangeLabel(formattedRangeLabel);
  }, [sliderValues, valuesArray, prepend, onChange]);

  useEffect(() => {
    onChange(values, valuesArray);
  }, [values]);

  return (
    <div className="flex flex-col gap-6">
      <header className="flex flex-row justify-between">
        <label className="font-semibold text-gray-600">{label}</label>
        <span className="font-light text-gray-400">{rangeLabel}</span>
      </header>

      <Slider.Root
        onValueChange={handleValueChange}
        minStepsBetweenThumbs={6}
        min={0}
        max={valuesArray.length - 1}
        step={1}
        value={sliderValues}
        className="relative flex h-5 w-full touch-none select-none items-center"
        defaultValue={[0, valuesArray.length - 1]}
      >
        <Slider.Track className="relative h-2 grow rounded-full bg-gray-200">
          <Slider.Range className="absolute h-full rounded-full bg-primary-500" />
        </Slider.Track>
        <Slider.Thumb className="block h-7 w-7 rounded-full border border-gray-300 bg-white" />
        <Slider.Thumb className="block h-7 w-7 rounded-full border border-gray-300 bg-white" />
      </Slider.Root>
    </div>
  );
}

export default RangeSelector;
