import { motion } from "framer-motion";
import * as Slider from "@radix-ui/react-slider";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { createProposal, updateProposal } from "@src/api/proposal";
import { createPaymentIntent } from "@src/api/payment";

export default function ProposalDetails({
  packageOptions,
  proposalFormData,
  proposalId,
  setCurrentProposalData,
  setIsReadyForPayment,
  setPaymentId,
  setProposalFormData,
}) {
  const buyerMessageRef = useRef(null);
  const packageRef = useRef(null);
  const [rangeLabel, setRangeLabel] = useState(
    `${proposalFormData.timelineMin} to ${proposalFormData.timelineMax} years from now`,
  );
  const [buyerMessageError, setBuyerMessageError] = useState("");
  const [packageError, setPackageError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const property = useSelector((state) => state.property.currentRoutedProperty);

  const onChangeValue = (event) => {
    setBuyerMessageError("");
    setProposalFormData({
      ...proposalFormData,
      buyerMessage: event.target.value,
    });
  };

  const handleValueChange = (newValue) => {
    setProposalFormData({
      ...proposalFormData,
      timelineMin: newValue[0],
      timelineMax: newValue[1],
    });

    const [start, end] = newValue;
    let prefix = "";
    if (start !== end) {
      prefix += `${start} to `;
    }
    setRangeLabel(`${prefix}${end} year${end !== 1 ? "s" : ""} from now`);
  };

  const handleProposalSubmit = async (e) => {
    e.preventDefault();
    if (proposalFormData.buyerMessage === "") {
      setBuyerMessageError("Please enter your proposal message.");
    }
    if (!proposalFormData.packageType) {
      setPackageError("Please select a package to send.");
    }
    if (proposalFormData.buyerMessage === "") {
      buyerMessageRef?.current?.scrollIntoView({ behavior: "smooth" });
      return;
    }
    if (!proposalFormData.packageType) {
      packageRef?.current?.scrollIntoView({ behavior: "smooth" });
      return;
    }

    try {
      setIsLoading(true);
      let response;
      if (!proposalId) {
        response = await createProposal(proposalFormData);

        const payment = await createPaymentIntent(response?.id);
        setPaymentId(payment?.payment_id);
      } else {
        response = await updateProposal({
          proposalId: proposalId ?? response?.id,
          proposal: {
            package_type: proposalFormData?.packageType,
            buyer_message: proposalFormData.buyerMessage,
            move_timeline_max: proposalFormData.timelineMax,
            move_timeline_min: proposalFormData.timelineMin,
          },
        });
      }

      setCurrentProposalData(response);

      setIsReadyForPayment(true);
    } catch (error) {
      setIsReadyForPayment(false);
    }

    setIsLoading(false);
  };

  return (
    <form onSubmit={handleProposalSubmit} className="mx-6">
      <p className="text-center font-attention text-2xl">
        Contact Homeowner of {property?.address_line_1}
      </p>
      <div className="mt-8 flex flex-col gap-4">
        <div ref={buyerMessageRef}>
          <p className="font-attention text-lg font-semibold">
            Start a Conversation
          </p>
          <p className="mt-4 text-grey-500">
            Write a personal note describing your interest in the home. Please
            do not reveal personal information about yourself or your family.
          </p>
          {buyerMessageError ? (
            <motion.div
              key="popup-container"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.3 }}
            >
              <p className="mt-1 text-red-500">{buyerMessageError}</p>
            </motion.div>
          ) : null}
          <textarea
            value={proposalFormData.buyerMessage}
            onChange={onChangeValue}
            placeholder="Your message to the home owner"
            className={`${buyerMessageError ? "mt-2" : "mt-4"} h-[140px] w-full rounded-lg border-2 border-[#D3D3D3] bg-[#F2F2F2] p-2 font-content opacity-80 focus:outline-none`}
          />
        </div>
        <div>
          <p className="mb-4 font-attention text-lg font-semibold">
            When are you hoping to move?
          </p>
          <p className="mb-3 flex font-content text-sage-600">{rangeLabel}</p>
          <Slider.Root
            onValueChange={handleValueChange}
            min={0}
            max={10}
            step={1}
            value={[proposalFormData.timelineMin, proposalFormData.timelineMax]}
            className="relative flex h-5 w-full touch-none select-none items-center"
            defaultValue={[
              proposalFormData.timelineMin,
              proposalFormData.timelineMax,
            ]}
          >
            <Slider.Track className="relative h-2 grow rounded-full bg-gray-200">
              <Slider.Range className="absolute h-full rounded-full bg-primary-500" />
            </Slider.Track>
            <Slider.Thumb className="block h-7 w-7 rounded-full border border-gray-300 bg-white" />
            <Slider.Thumb className="block h-7 w-7 rounded-full border border-gray-300 bg-white" />
          </Slider.Root>
        </div>
        <div ref={packageRef}>
          <p className="font-attention text-lg font-semibold">
            Package Options
          </p>
          <p className="mt-4 text-grey-500">
            Send neighborly cheer to your favorite homes with UNLISTED! Each
            package contains the personal note you crafted, a unique invitation
            code for the homeowner to connect directly with you online via
            UNLISTED, and the thoughtful, locally sourced goodies of your
            choice. All are prepared by hand with care and mailed in UNLISTED’s
            attention-grabbing signature gold packaging.
          </p>
          <div className="mt-4 flex flex-col gap-4">
            {packageError ? (
              <motion.div
                key="popup-container"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.3 }}
              >
                <p className="text-red-500">{packageError}</p>
              </motion.div>
            ) : null}
            {packageOptions?.map((item) => (
              <div
                key={item?.id}
                onClick={() => {
                  setPackageError("");
                  setProposalFormData({
                    ...proposalFormData,
                    packageType: item?.id,
                  });
                }}
                className={`max-w-sm overflow-hidden rounded-2xl border-[1px] font-attention transition-all duration-300 ease-in-out ${proposalFormData.packageType === item?.id ? "bg-gold-200 shadow-lg" : "bg-white shadow"}`}
              >
                {item?.sample_link ? (
                  <img
                    src={item?.sample_link}
                    alt={item?.title}
                    className="h-auto w-full rounded-t-2xl border-b-[1px]"
                  />
                ) : null}
                <div className="px-6 py-4">
                  <p className="mb-2 text-xl font-semibold">{item?.title}</p>
                  <p className="mb-4 text-base text-gray-700">
                    {item?.display_text}
                  </p>
                  <span className="text-xl text-grey-700">{item?.price} $</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <button
          type="submit"
          value="Submit"
          className="mt-12 min-w-[189px] rounded-xl border-[1px] bg-sage-400 px-4 py-3 font-medium text-black"
          disabled={isLoading}
        >
          {isLoading ? (
            <div className="flex items-center justify-center">
              <div className="h-6 w-6 animate-spin rounded-full border-2 border-grey-700 border-t-transparent"></div>
            </div>
          ) : (
            "Continue to payment"
          )}
        </button>
      </div>
    </form>
  );
}
