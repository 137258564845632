import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useCallback,
} from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  motion,
  useMotionValue,
  useTransform,
  animate,
  AnimatePresence,
} from "framer-motion";
import { useSelector } from "react-redux";
import {
  openSearchPanel,
  setPropertyAsSwipedTo,
} from "../../store/features/search/slice.js";
import { fetchRandomProperties } from "@src/store/features/search/service.js";
import PropertyProfileCardContent from "../../components/propertyProfile/propertyProfileCardContent/PropertyProfileCardContent.jsx";
import { IoIosCheckmarkCircle, IoIosCloseCircle } from "react-icons/io";
import SearchSpinner from "@src/components/searchSpinner/SearchSpinner.jsx";
import CardsViewIntroIcon from "@src/assets/svgs/CardsViewIntroIcon";
import { setCardsOnboardingComplete } from "@src/store/features/user/slice.js";
import { MdSearch } from "react-icons/md";

function Cards() {
  const componentRef = useRef(null);
  const { randomProperties, properties, isFetchingRandomProperties } =
    useSelector((state) => state.search);
  const dispatch = useDispatch();
  const { cardsOnboardingComplete } = useSelector((state) => state.user);
  const [cards, setCards] = useState([]);
  const x = useMotionValue(0);
  const [swipeRightConfirmed, setSwipeRightConfirmed] = useState(false);
  const [swipeLeftConfirmed, setSwipeLeftConfirmed] = useState(false);
  const [introAnimationDone, setIntroAnimationDone] = useState(false);
  const [introAnimationOffset, setIntroAnimationOffset] = useState(0);
  const iconOpacity = useTransform(
    x,
    [-window.innerWidth / 2, 0, window.innerWidth / 2],
    [1, 0, 1],
  );

  const rightIconX = useTransform(x, [0, window.innerWidth / 2], [260, 0]);
  const rightIconRotate = useTransform(x, [0, window.innerWidth / 2], [45, 0]);
  const leftIconX = useTransform(x, [0, -window.innerWidth / 2], [-260, 0]);
  const leftIconRotate = useTransform(x, [0, -window.innerWidth / 2], [-45, 0]);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const preventDefault = (e) => {
      if (componentRef.current && componentRef.current.contains(e.target)) {
        e.preventDefault();
      }
    };

    // e.preventDefault();

    document.addEventListener("touchmove", preventDefault, { passive: false });
    // console.log(properties);
    if (properties.length === 0 && randomProperties.length === 0) {
      dispatch(fetchRandomProperties());
    }

    return () => {
      document.removeEventListener("touchmove", preventDefault);
    };
  }, []);

  useLayoutEffect(() => {
    //when the redux store value of randomProperties changes, set the cards state to the new properties
    //we want to be dealing with the first 5 randomProperties at a time so we need to slice the properties array

    if (properties.length > 0) {
      setCards(
        properties
          .filter((property) => !property.hasOwnProperty("swipedTo"))
          .slice(0, 3)
          .reverse(),
      );
    } else if (randomProperties.length > 0) {
      setCards(
        randomProperties
          .filter((property) => !property.hasOwnProperty("swipedTo"))
          .slice(0, 3)
          .reverse(),
      );
    }
  }, [randomProperties, properties]);

  useEffect(() => {
    const runIntroAnimation = async () => {
      if (cards.length > 0 && !introAnimationDone) {
        // First swipe to 170px
        await animate(x, 100, {
          type: "spring",
          stiffness: 150, // Lower stiffness for a more pronounced spring
          damping: 20, // Lower damping for more oscillation
          duration: 1, // Slightly longer duration for the springy effect
          delay: 1.5, // Delay before starting the swipe
        });

        // Animate swipe back to 0px after a short delay
        // await animate(x, 0, {
        //   type: "spring",
        //   stiffness: 90, // Higher stiffness for a quicker settle
        //   damping: 20,
        //   duration: 0.8,
        //   delay: 0.8, // Delay before returning to 0
        // });

        setIntroAnimationOffset(100);

        // Mark the intro animation as done
        setIntroAnimationDone(true);
      }
    };

    if (!cardsOnboardingComplete) runIntroAnimation();

    // Clean up the animation on unmount
    return () => {
      x.stop();
    };
  }, [cards, introAnimationDone, x]);

  // Use useCallback to memoize the handleCardLeaving function
  const handleCardLeaving = useCallback(
    (swipedInfo) => {
      dispatch(setPropertyAsSwipedTo(swipedInfo));
    },
    [dispatch],
  );

  const handleConfirmCardSwipeIntro = async () => {
    if (!cardsOnboardingComplete) dispatch(setCardsOnboardingComplete(true));
    dispatch(openSearchPanel());
    // Animate swipe back to 0px after a short delay
    await animate(x, 0, {
      type: "spring",
      stiffness: 90, // Higher stiffness for a quicker settle
      damping: 20,
      duration: 0.8,
    });

    setIntroAnimationOffset(0);
  };

  const handleSwipeStart = useCallback(async () => {
    if (!cardsOnboardingComplete) dispatch(setCardsOnboardingComplete(true));
    console.log("swipe start");
  }, []);
  const handleCardRelease = useCallback(() => {
    //animate(x, 240, { type: "spring", stiffness: 300, damping: 30 });
    console.log("swipe end");
  }, []);

  return (
    <div
      ref={componentRef}
      className="flex w-full flex-1 flex-col justify-stretch overflow-x-hidden overflow-x-clip pt-16"
    >
      {isFetchingRandomProperties && (
        <div className="absolute left-1/2 top-1/2 z-20 -translate-x-1/2 -translate-y-full">
          <SearchSpinner />
        </div>
      )}
      <div className="relative flex h-full w-full flex-1 flex-col justify-stretch sm:hidden">
        {cards.length > 1 &&
          cards.map((property, index) => (
            <Card
              key={index}
              property={property}
              cards={cards}
              introAnimationOffset={introAnimationOffset}
              setIntroAnimationOffset={setIntroAnimationOffset}
              x={x} // Pass down the x motion value
              isTopCard={index === cards.length - 1}
              isSecondCard={index === cards.length - 2}
              onLeave={handleCardLeaving}
              onSwipeStart={handleSwipeStart}
              onRelease={handleCardRelease}
              setSwipeRightConfirmed={setSwipeRightConfirmed}
              setSwipeLeftConfirmed={setSwipeLeftConfirmed}
            />
          ))}
      </div>
      <div className="card-grid pointer-events-none absolute mx-auto h-full max-h-[calc(100dvh-8rem)] w-[min(calc(100%-4rem),80rem)] w-full max-w-5xl gap-6 overflow-y-hidden p-8 pt-20 opacity-0 sm:pointer-events-auto sm:relative sm:grid sm:overflow-y-auto sm:opacity-100">
        {cards.length > 1 &&
          cards.map((property, index) => (
            <motion.div
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              key={`grid_${property.id}`}
              className="flex h-full max-h-[650px] flex-col"
            >
              <div
                className="relative flex-1 cursor-pointer overflow-hidden rounded-xl shadow-lg"
                onClick={() => {
                  //add showing search param ?showing=property.id to current url
                  if (!cardsOnboardingComplete)
                    dispatch(setCardsOnboardingComplete(true));
                  setSearchParams({ showing: property.id });
                }}
              >
                <PropertyProfileCardContent property={property} />
              </div>
              <div className="flex flex-row items-center justify-center gap-6 p-3">
                <button
                  className="relative rounded-full text-[#e57373] hover:text-[#e15b5b]"
                  onClick={() => {
                    if (!cardsOnboardingComplete)
                      dispatch(setCardsOnboardingComplete(true));
                    dispatch(
                      setPropertyAsSwipedTo({
                        id: property.id,
                        swipedTo: "left",
                      }),
                    );
                  }}
                >
                  {/*<div className="absolute left-1/2 top-1/2 h-3/4 w-3/4 -translate-x-1/2 -translate-y-1/2 rounded-full bg-black/50"></div>*/}
                  <IoIosCloseCircle className="isolate h-16 w-16 rounded-full fill-current transition-all" />
                </button>
                <button
                  className="relative rounded-full text-[#94ad9b] hover:text-[#86a28e]"
                  onClick={() => {
                    if (!cardsOnboardingComplete)
                      dispatch(setCardsOnboardingComplete(true));
                    dispatch(
                      setPropertyAsSwipedTo({
                        id: property.id,
                        swipedTo: "right",
                      }),
                    );
                  }}
                >
                  {/*<div className="absolute left-1/2 top-1/2 h-3/4 w-3/4 -translate-x-1/2 -translate-y-1/2 rounded-full bg-black/50"></div>*/}
                  <IoIosCheckmarkCircle className="isolate h-16 w-16 rounded-full fill-current transition-all" />
                </button>
              </div>
            </motion.div>
          ))}
      </div>
      <motion.div
        key="swipe-right-confirmation"
        initial={{ opacity: 0 }}
        style={
          !swipeRightConfirmed && {
            x: rightIconX,
            opacity: iconOpacity,
            rotate: rightIconRotate,
          }
        }
        animate={
          swipeRightConfirmed ? { x: 240, opacity: 0.5, rotate: 45 } : {}
        }
        transition={{ duration: 0.15, ease: "circIn" }}
        className="pointer-events-none fixed left-1/2 top-1/2 z-10 translate-x-[50vw] text-[#BDCCC1] sm:hidden"
      >
        <div className="fixed -translate-x-1/2 -translate-y-1/2 rounded-full bg-black/20">
          <div className="absolute left-1/2 top-1/2 h-3/4 w-3/4 -translate-x-1/2 -translate-y-1/2 rounded-full bg-black/50"></div>
          <IoIosCheckmarkCircle className="isolate h-24 w-24 rounded-full fill-current" />
        </div>
      </motion.div>
      <motion.div
        key="swipe-left-confirmation"
        initial={{ opacity: 0 }}
        style={
          !swipeLeftConfirmed && {
            x: leftIconX,
            opacity: iconOpacity,
            rotate: leftIconRotate,
          }
        }
        animate={
          swipeLeftConfirmed ? { x: -240, opacity: 0.5, rotate: -45 } : {}
        }
        transition={{ duration: 0.15, ease: "circIn" }}
        className="text-[#About]/90 pointer-events-none fixed left-1/2 top-1/2 z-10 translate-x-[50vw] text-[#e57373] sm:hidden"
      >
        <div className="fixed -translate-x-1/2 -translate-y-1/2 rounded-full bg-black/20">
          <div className="absolute left-1/2 top-1/2 h-3/4 w-3/4 -translate-x-1/2 -translate-y-1/2 rounded-full bg-black/50"></div>
          <IoIosCloseCircle className="isolate h-24 w-24 rounded-full fill-current" />
        </div>
      </motion.div>

      <AnimatePresence>
        {cards.length > 0 && !cardsOnboardingComplete && (
          <motion.div
            initial={{
              opacity: 0,
              y: 100,
              x: "-50%",
            }}
            animate={{
              opacity: 1,
              y: 0, // Move to final position
              x: "-50%",
            }}
            exit={{
              opacity: 0,
              y: 100,
              transition: { duration: 0.5 },
            }}
            transition={{
              duration: 0.5,
              delay: 0.8, // Delay only for the animate state
            }}
            className="fixed bottom-28 left-1/2 z-20 flex w-[min(100%-5rem,24rem)] -translate-x-1/2 flex-col items-center justify-center gap-3 rounded-xl bg-[#efefef] p-4 pb-5 pt-12 text-center shadow-lg sm:bottom-1/2"
          >
            <div className="absolute left-1/2 top-0 -translate-x-1/2 -translate-y-1/2 rounded-full border border-white bg-neutral-100 p-4 shadow-lg ring-1 ring-neutral-200">
              <CardsViewIntroIcon className="h-10 w-10 fill-neutral-800" />
            </div>

            <div className="flex flex-col gap-2 px-2 font-content text-sm text-neutral-700">
              <h2 className="mb-2 font-attention text-2xl text-neutral-800">
                Welcome to Unlisted
              </h2>
              <p className="hidden text-balance sm:block">
                Explore off-market homes for the first time ever. Let us know
                which homes you like and which you don’t.
              </p>
              <p className="text-balance sm:hidden">
                Explore off-market homes for the first time ever. Swipe right if
                you like what you see and left if you don’t.
              </p>

              <button
                onClick={handleConfirmCardSwipeIntro}
                className="mx-auto mt-4 flex w-fit justify-center gap-2 rounded-full bg-primary-500 p-3 px-6 pl-4 font-semibold text-white shadow-lg shadow-primary-500/10"
              >
                <MdSearch className="h-[1.25rem] w-[1.25rem] fill-current opacity-80" />
                <span>Start your search</span>
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

const Card = ({
  property,
  cards,
  introAnimationOffset,
  setIntroAnimationOffset,
  x,
  isTopCard,
  isSecondCard,
  onLeave,
  onSwipeStart,
  onRelease,
  setSwipeRightConfirmed,
  setSwipeLeftConfirmed,
}) => {
  const navigate = useNavigate();
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [hasDirectionBeenSet, setHasDirectionBeenSet] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const rotate = useTransform(
    x,
    [-window.innerWidth / 2, window.innerWidth / 2],
    [-15, 15],
  );

  const scale = useTransform(
    x,
    [-window.innerWidth / 2, 0, window.innerWidth / 2],
    [1, 0.9, 1],
  );

  const opacity = useTransform(
    x,
    [-window.innerWidth / 2, 0, window.innerWidth / 2],
    [1, 0, 1],
  );

  const [leaveX, setLeaveX] = useState(0);
  const [isLeaving, setIsLeaving] = useState(false);

  const onPanStart = (e, info) => {
    // console.log("start x get", x.get());
    // console.log("start info point", info.point.y);
    // console.log("start offest", info.offset.y);
    setDragStart({ x: info.offset.x, y: info.point.y });
    setHasDirectionBeenSet(false);
    onSwipeStart();
  };

  const onPan = (e, info) => {
    if (!hasDirectionBeenSet) {
      // console.log("pan x get", x.get());
      console.log("info.point.x", info.point.x);
      console.log("info.point.y", info.point.y);
      const deltaX = Math.abs(info.point.x - dragStart.x);
      const deltaY = Math.abs(info.point.y - dragStart.y);
      console.log("deltaX", deltaX);
      console.log("deltaY", deltaY);

      if (deltaX > 15 || deltaY > 50) {
        console.log("reached 1");
        // Threshold of 10 pixels
        if (deltaX > deltaY) {
          console.log("reached 2");
          setIsDragging(true);
          x.set(info.offset.x + introAnimationOffset);
          e.preventDefault(); // Prevent scrolling
        } else {
          console.log("reached 3");
          x.set(introAnimationOffset);
          setIsDragging(false);
        }
        setHasDirectionBeenSet(true);
      }
    } else if (isDragging) {
      console.log("reached 4");
      x.set(info.offset.x + introAnimationOffset);
    }
  };

  const onPanEnd = (e, info) => {
    if (isDragging) {
      if (info.offset.x > 200) {
        setLeaveX(400);
        setIsLeaving(true);
        if (isTopCard) setSwipeRightConfirmed(true);
      } else if (info.offset.x < -200) {
        setLeaveX(-400);
        setIsLeaving(true);
        if (isTopCard) setSwipeLeftConfirmed(true);
      } else {
        setIsLeaving(false);
        setLeaveX(0);
        animate(x, 0, {
          type: "spring",
          stiffness: 300,
          damping: 30,
          ease: "circIn",
        });
      }
      setIntroAnimationOffset(0);
      onRelease();
    }
  };

  const handleAnimationComplete = () => {
    if (isLeaving) {
      setIsLeaving(false);
      setSwipeRightConfirmed(false);
      setSwipeLeftConfirmed(false);
      onLeave({ id: property.id, swipedTo: leaveX > 0 ? "right" : "left" });
    }
  };

  return (
    <motion.div
      key={property.id}
      x={isTopCard ? x : 0}
      onClick={() => {
        //add showing search param ?showing=property.id to current url
        setSearchParams({ showing: property.id });
      }}
      onPanStart={onPanStart}
      onPan={onPan}
      onPanEnd={onPanEnd}
      dragMomentum={false}
      style={{
        x: isTopCard ? x : 0,
        rotate: isTopCard ? rotate : 0,
        scale: isSecondCard ? scale : isTopCard ? 1 : 0.9,
        opacity: isTopCard || isSecondCard ? (isSecondCard ? opacity : 1) : 0,
        touchAction: "pan-y",
      }}
      initial={{
        scale: isTopCard ? 1 : 0.9,
        x: 0,
        rotate: 0,
      }}
      animate={
        isLeaving
          ? {
              x: leaveX,
              y: 0,
              opacity: 0,
              scale: 0.9,
              transition: { duration: 0.15 },
            }
          : {
              x: 0,
              y: 0,
              opacity: parseInt(
                isTopCard || isSecondCard ? (isSecondCard ? opacity : 1) : 0,
              ),
              transition: { duration: 0.15 },
            }
      }
      onAnimationComplete={handleAnimationComplete}
      className={`hide-scrollbar absolute bottom-4 left-4 top-1 z-10 w-[calc(100%-30px)] origin-center overflow-y-auto overflow-x-hidden rounded-2xl bg-white shadow-md ring-1 ring-neutral-700/20 md:overflow-y-auto`}
      data-testid="active-card"
    >
      <PropertyProfileCardContent property={property} />
    </motion.div>
  );
};

export default Cards;
