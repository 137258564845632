function CaliforniaPrivacyPolicy() {
  const sectionTitleStyles = "text-xl mt-2 font-attention text-gray-900";
  const sectionDescriptionStyles =
    "py-2 pl-3 font-content text-sm font-normal text-gray-900";
  const sectionListStyle = "mb-2 list-disc pl-10 text-sm";

  return (
    <div className="mx-auto w-[min(calc(100%-4rem),80ch)] px-6 pb-12 pt-32">
      <h1 className="font-attention text-3xl leading-tight md:text-4xl md:leading-[4rem]">
        California Privacy Policy
      </h1>

      <p className="py-2 font-content text-sm font-normal text-gray-900">
        This Privacy Notice for California Residents supplements the information
        contained in Unlisted Software, Inc.’s{" "}
        <a
          href="/privacypolicy"
          target="_blank"
          className="text-sky-600 underline"
        >
          Privacy Policy
        </a>{" "}
        and applies solely to all visitors, users, and others who reside in the
        State of California ("consumers" or “you"). Unlisted Software, Inc.
        ("we" or "Company") adopts this notice to comply with the California
        Consumer Privacy Act of 2018 (CCPA) and any capitalized terms used but
        not defined herein shall have the meaning ascribed to them in CCPA.
      </p>
      <h2 className={sectionTitleStyles}>Information We Collect</h2>
      <p className={sectionDescriptionStyles}>
        Our Website and Services collect information that identifies, relates
        to, describes, references, is capable of being associated with, or could
        reasonably be linked, directly or indirectly, with a particular consumer
        or device ("personal information"). In particular, our Website collects
        the following categories of personal information from its consumers:
      </p>
      <div className="mb-8 overflow-x-auto">
        <table className="mb-2 w-full min-w-[700px] border-collapse">
          <thead>
            <tr>
              <th
                className={`border border-gray-400 p-4 text-left font-normal ${sectionTitleStyles}`}
              >
                Category
              </th>
              <th
                className={`border border-gray-400 p-4 text-left font-normal ${sectionTitleStyles}`}
              >
                Examples
              </th>
              <th
                className={`border border-gray-400 p-4 text-center font-normal ${sectionTitleStyles}`}
              >
                Collected?
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                className={`border border-gray-400 p-4 ${sectionDescriptionStyles}`}
              >
                A. Identifiers.
              </td>
              <td
                className={`border border-gray-400 p-4 ${sectionDescriptionStyles}`}
              >
                A real name, alias, postal address, unique personal identifier,
                online identifier, Internet Protocol address, email address,
                account name, Social Security number, driver’s license number,
                passport number, or other similar identifiers.
              </td>
              <td
                className={`border border-gray-400 p-4 text-center ${sectionDescriptionStyles}`}
              >
                YES
              </td>
            </tr>
            <tr>
              <td
                className={`border border-gray-400 p-4 ${sectionDescriptionStyles}`}
              >
                B. Personal information categories listed in the California
                Customer Records statute (Cal. Civ. Code § 1798.80(e)).
              </td>
              <td
                className={`border border-gray-400 p-4 ${sectionDescriptionStyles}`}
              >
                <p>
                  A name, signature, Social Security number, physical
                  characteristics or description, address, telephone number,
                  passport number, driver’s license or state identification card
                  number, insurance policy number, education, employment,
                  employment history, bank account number, credit card number,
                  debit card number, or any other financial information, medical
                  information, or health insurance information.
                </p>
                <p>
                  Some personal information included in this category may
                  overlap with other categories.
                </p>
              </td>
              <td
                className={`border border-gray-400 p-4 text-center ${sectionDescriptionStyles}`}
              >
                YES
              </td>
            </tr>
            <tr>
              <td
                className={`border border-gray-400 p-4 ${sectionDescriptionStyles}`}
              >
                C. Protected classification characteristics under California or
                federal law.
              </td>
              <td
                className={`border border-gray-400 p-4 ${sectionDescriptionStyles}`}
              >
                Age (40 years or older), race, color, ancestry, national origin,
                citizenship, religion or creed, marital status, medical
                condition, physical or mental disability, sex (including gender,
                gender identity, gender expression, pregnancy or childbirth and
                related medical conditions), sexual orientation, veteran or
                military status, genetic information (including familial genetic
                information).
              </td>
              <td
                className={`border border-gray-400 p-4 text-center ${sectionDescriptionStyles}`}
              >
                YES
              </td>
            </tr>
            <tr>
              <td
                className={`border border-gray-400 p-4 ${sectionDescriptionStyles}`}
              >
                D. Commercial information.
              </td>
              <td
                className={`border border-gray-400 p-4 ${sectionDescriptionStyles}`}
              >
                Records of personal property, products or services purchased,
                obtained, or considered, or other purchasing or consuming
                histories or tendencies.
              </td>
              <td
                className={`border border-gray-400 p-4 text-center ${sectionDescriptionStyles}`}
              >
                YES
              </td>
            </tr>
            <tr>
              <td
                className={`border border-gray-400 p-4 ${sectionDescriptionStyles}`}
              >
                E. Biometric information.
              </td>
              <td
                className={`border border-gray-400 p-4 ${sectionDescriptionStyles}`}
              >
                Genetic, physiological, behavioral, and biological
                characteristics, or activity patterns used to extract a template
                or other identifier or identifying information, such as,
                fingerprints, faceprints, and voiceprints, iris or retina scans,
                keystroke, gait, or other physical patterns, and sleep, health,
                or exercise data.
              </td>
              <td
                className={`border border-gray-400 p-4 text-center ${sectionDescriptionStyles}`}
              >
                NO
              </td>
            </tr>
            <tr>
              <td
                className={`border border-gray-400 p-4 ${sectionDescriptionStyles}`}
              >
                F. Internet or other similar network activity.
              </td>
              <td
                className={`border border-gray-400 p-4 ${sectionDescriptionStyles}`}
              >
                Browsing history, search history, information on a consumer’s
                interaction with a website, application, or advertisement.
              </td>
              <td
                className={`border border-gray-400 p-4 text-center ${sectionDescriptionStyles}`}
              >
                YES
              </td>
            </tr>
            <tr>
              <td
                className={`border border-gray-400 p-4 ${sectionDescriptionStyles}`}
              >
                G. Geolocation data.
              </td>
              <td
                className={`border border-gray-400 p-4 ${sectionDescriptionStyles}`}
              >
                Physical location or movements.
              </td>
              <td
                className={`border border-gray-400 p-4 text-center ${sectionDescriptionStyles}`}
              >
                YES
              </td>
            </tr>
            <tr>
              <td
                className={`border border-gray-400 p-4 ${sectionDescriptionStyles}`}
              >
                H. Sensory data.
              </td>
              <td
                className={`border border-gray-400 p-4 ${sectionDescriptionStyles}`}
              >
                Audio, electronic, visual, thermal, olfactory, or similar
                information.
              </td>
              <td
                className={`border border-gray-400 p-4 text-center ${sectionDescriptionStyles}`}
              >
                NO
              </td>
            </tr>
            <tr>
              <td
                className={`border border-gray-400 p-4 ${sectionDescriptionStyles}`}
              >
                I. Professional or employment-related information.
              </td>
              <td
                className={`border border-gray-400 p-4 ${sectionDescriptionStyles}`}
              >
                Current or past job history or performance evaluations.
              </td>
              <td
                className={`border border-gray-400 p-4 text-center ${sectionDescriptionStyles}`}
              >
                NO
              </td>
            </tr>
            <tr>
              <td
                className={`border border-gray-400 p-4 ${sectionDescriptionStyles}`}
              >
                J. Non-public education information (per the Family Educational
                Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part
                99)).
              </td>
              <td
                className={`border border-gray-400 p-4 ${sectionDescriptionStyles}`}
              >
                Education records directly related to a student maintained by an
                educational institution or party acting on its behalf, such as
                grades, transcripts, class lists, student schedules, student
                identification codes, student financial information, or student
                disciplinary records.
              </td>
              <td
                className={`border border-gray-400 p-4 text-center ${sectionDescriptionStyles}`}
              >
                NO
              </td>
            </tr>
            <tr>
              <td
                className={`border border-gray-400 p-4 ${sectionDescriptionStyles}`}
              >
                K. Inferences drawn from other personal information.
              </td>
              <td
                className={`border border-gray-400 p-4 ${sectionDescriptionStyles}`}
              >
                Profile reflecting a person’s preferences, characteristics,
                psychological trends, predispositions, behavior, attitudes,
                intelligence, abilities, and aptitudes.
              </td>
              <td
                className={`border border-gray-400 p-4 text-center ${sectionDescriptionStyles}`}
              >
                NO
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h2 className={sectionTitleStyles}>
        Personal Information Does Not Include:
      </h2>
      <ul className={sectionListStyle}>
        <li>Publicly available information from government records.</li>
        <li>Deidentified or aggregated consumer information.</li>
        <li>
          Information excluded from the CCPA’s scope, like:
          <ul className={sectionListStyle}>
            <li>
              Health or medical information covered by HIPAA and the California
              Confidentiality of Medical Information Act (CMIA) or clinical
              trial data.
            </li>
            <li>
              Personal information covered by sector-specific privacy laws,
              including the Fair Credit Reporting Act (FRCA), the
              Gramm-Leach-Bliley Act (GLBA) or California Financial Information
              Privacy Act (FIPA), and the Driver’s Privacy Protection Act of
              1994.
            </li>
          </ul>
        </li>
      </ul>

      <h2 className={sectionTitleStyles}>
        We Obtain the Categories of Personal Information Listed Above From the
        Following Sources:
      </h2>
      <ul className={sectionListStyle}>
        <li>
          Directly from you. For example, from forms you complete or products
          and services you purchase.
        </li>
        <li>
          Indirectly from you. For example, from observing your actions on our
          Website.
        </li>
        <li>
          From Third Parties, such as Consumer Reporting Agencies and others
          having information related to your request for products or services.
        </li>
      </ul>
      <h2 className={sectionTitleStyles}>Use of Personal Information</h2>
      <p className={sectionDescriptionStyles}>
        We may use or disclose the personal information we collect for one or
        more of the following purposes:
      </p>
      <ul className={sectionListStyle}>
        <li>
          To fulfill or meet the reason you provided the information. For
          example, if you share your name and contact information to ask a
          question about our products or services, we will use that personal
          information to respond to your inquiry. If you provide your personal
          information to purchase a product or service, we will use that
          information to process your payment and facilitate delivery. We may
          also save your information to facilitate new product orders or process
          returns.
        </li>
        <li>
          To provide, support, personalize, and develop our Website, products,
          and services.
        </li>
        <li>
          To create, maintain, customize, and secure your account with us.
        </li>
        <li>
          To process your requests, purchases, transactions, and payments and
          prevent transactional fraud.
        </li>
        <li>
          To provide you with support and to respond to your inquiries,
          including to investigate and address your concerns and monitor and
          improve our responses.
        </li>
        <li>
          To personalize your Website experience and to deliver content and
          product and service offerings relevant to your interests, including
          targeted offers and ads through our Website, third-party sites, and
          via email or text message (with your consent, where required by law).
        </li>
        <li>
          To help maintain the safety, security, and integrity of our Website,
          products and services, databases and other technology assets, and
          business.
        </li>
        <li>
          For testing, research, analysis, and product development, including to
          develop and improve our Website, products, and services.
        </li>
        <li>
          To respond to law enforcement requests and as required by applicable
          law, court order, or governmental regulations.
        </li>
        <li>
          As described to you when collecting your personal information or as
          otherwise set forth in the CCPA.
        </li>
        <li>
          To evaluate or conduct a merger, divestiture, restructuring,
          reorganization, dissolution, or other sale or transfer of some or all
          of Unlisted Software, Inc.’s assets, whether as a going concern or as
          part of bankruptcy, liquidation, or similar proceeding, in which
          personal information held by Unlisted Software, Inc. about our Website
          users is among the assets transferred.
        </li>
      </ul>
      <p className={sectionDescriptionStyles}>
        We will not collect additional categories of personal information or use
        the personal information we collected for materially different,
        unrelated, or incompatible purposes without providing you notice.
      </p>

      <h2 className={sectionTitleStyles}>Sharing Personal Information</h2>
      <p className={sectionDescriptionStyles}>
        We may disclose your personal information to a third party for a
        business purpose or share your personal information, subject to your
        right to opt-out of those sales (see Personal Information Sales Opt-Out
        and Opt-In Rights). When we disclose personal information for a business
        purpose, we enter a contract that describes the purpose and requires the
        recipient to both keep that personal information confidential and not
        use it for any purpose except performing the contract. The CCPA
        prohibits third parties who purchase the personal information we hold
        from reselling it unless you have received explicit notice and an
        opportunity to opt-out of further sales.
      </p>
      <p className={sectionDescriptionStyles}>
        We share your personal information with the following categories of
        third parties:
      </p>
      <ul className={sectionListStyle}>
        <li>Service providers.</li>
        <li>Data aggregators.</li>
        <li>Government agencies.</li>
      </ul>

      <h2 className={sectionTitleStyles}>
        Disclosures of Personal Information for a Business Purpose
      </h2>
      <p className={sectionDescriptionStyles}>
        In the preceding twelve (12) months, Company has disclosed the following
        categories of personal information for a business purpose:
      </p>
      <ul className={`${sectionListStyle} list-none`}>
        <li>Category A: Identifiers.</li>
        <li>
          Category B: California Customer Records personal information
          categories.
        </li>
        <li>Category D: Commercial Information.</li>
        <li>Category F: Internet or other similar network activity.</li>
        <li>Category G: Geolocation data.</li>
      </ul>
      <p className={sectionDescriptionStyles}>
        We disclose your personal information for a business purpose to the
        following categories of third parties:
      </p>
      <ul className={sectionListStyle}>
        <li>Service providers.</li>
      </ul>

      <h2 className="mb-2 list-disc pl-10 font-attention text-sm">
        Sales of Personal Information
      </h2>
      <p className={`${sectionListStyle} ml-4 pl-10`}>
        In the preceding twelve (12) months, Company has not sold (or planned to
        sell) the following categories of personal information:
      </p>
      <ul className={`${sectionListStyle} ml-7 list-none`}>
        <li>F. Internet or other similar network activity.</li>
        <li>G. Geolocation data.</li>
      </ul>
      <p className={`${sectionListStyle} ml-4`}>
        We do not sell your personal information to any party for any reason.
      </p>
      <h2 className={sectionTitleStyles}>Your Rights and Choices</h2>
      <p className={sectionDescriptionStyles}>
        The CCPA provides consumers (California residents) with specific rights
        regarding their personal information. This section describes your CCPA
        rights and explains how to exercise those rights.
      </p>

      <h3 className={`${sectionTitleStyles} ml-3`}>
        Access to Specific Information and Data Portability Rights
      </h3>
      <p className={`${sectionDescriptionStyles} ml-3`}>
        You have the right to request that we disclose certain information to
        you about our collection and use of your personal information over the
        past 12 months. Once we receive and confirm your verifiable consumer
        request (see Exercising Access, Data Portability, and Deletion Rights),
        we will disclose to you:
      </p>

      <ul className={`${sectionListStyle} ml-3`}>
        <li>The categories of personal information we collected about you.</li>
        <li>
          The categories of sources for the personal information we collected
          about you.
        </li>
        <li>
          Our business or commercial purpose for collecting or selling that
          personal information.
        </li>
        <li>
          The categories of third parties with whom we share that personal
          information.
        </li>
        <li>
          The specific pieces of personal information we collected about you
          (also called a data portability request).
        </li>
        <li>
          If we sold or disclosed your personal information for a business
          purpose, two separate lists disclosing:
          <ul className={`${sectionListStyle} ml-3`}>
            <li>
              Sales, identifying the personal information categories that each
              category of recipient purchased.
            </li>
            <li>
              Disclosures for a business purpose, identifying the personal
              information categories that each category of recipient obtained.
            </li>
          </ul>
        </li>
      </ul>
      <h3 className={`${sectionTitleStyles} ml-3`}>Deletion Request Rights</h3>
      <p className={`${sectionDescriptionStyles} ml-3`}>
        You have the right to request that we delete any of your personal
        information that we collected from you and retained, subject to certain
        exceptions. Once we receive and confirm your verifiable consumer request
        (see Exercising Access, Data Portability, and Deletion Rights), we will
        delete (and direct our service providers to delete) your personal
        information from our records, unless an exception applies.
      </p>

      <p className={`${sectionDescriptionStyles} ml-3`}>
        We may deny your deletion request if retaining the information is
        necessary for us or our service provider(s) to:
      </p>

      <ol className={`${sectionListStyle} ml-3 list-decimal`}>
        <li>
          Complete the transaction for which we collected the personal
          information, provide a good or service that you requested, take
          actions reasonably anticipated within the context of our ongoing
          business relationship with you, or otherwise perform our contract with
          you.
        </li>
        <li>
          Detect security incidents, protect against malicious, deceptive,
          fraudulent, or illegal activity, or prosecute those responsible for
          such activities.
        </li>
        <li>
          Debug products to identify and repair errors that impair existing
          intended functionality.
        </li>
        <li>
          Exercise free speech, ensure the right of another consumer to exercise
          their free speech rights, or exercise another right provided for by
          law.
        </li>
        <li>
          Comply with the California Electronic Communications Privacy Act (Cal.
          Penal Code § 1546 et. seq.).
        </li>
        <li>
          Engage in public or peer-reviewed scientific, historical, or
          statistical research in the public interest that adheres to all other
          applicable ethics and privacy laws, when the information’s deletion
          may likely render impossible or seriously impair the research’s
          achievement, if you previously provided informed consent.
        </li>
        <li>
          Enable solely internal uses that are reasonably aligned with consumer
          expectations based on your relationship with us.
        </li>
        <li>
          Comply with a legal obligation, including applicable record retention
          laws.
        </li>
        <li>
          Make other internal and lawful uses of that information that are
          compatible with the context in which you provided it.
        </li>
      </ol>
      <h3 className={`${sectionTitleStyles} ml-3`}>
        Exercising Access, Data Portability, and Deletion Rights
      </h3>
      <p className={`${sectionDescriptionStyles} ml-3`}>
        To exercise the access, data portability, and deletion rights described
        above, please submit a verifiable consumer request to us by emailing
        <a
          href="mailto:compliance@unlistedinc.com"
          target="_blank"
          className="text-sky-600 underline"
        >
          compliance@unlistedinc.com
        </a>
        .
      </p>

      <p className={`${sectionDescriptionStyles} ml-3`}>
        Only you, or someone legally authorized to act on your behalf, may make
        a verifiable consumer request related to your personal information. You
        may also make a verifiable consumer request on behalf of your minor
        child.
      </p>

      <p className={`${sectionDescriptionStyles} ml-3`}>
        You may only make a verifiable consumer request for access or data
        portability twice within a 12-month period. The verifiable consumer
        request must:
      </p>

      <ol className={`${sectionListStyle} ml-3`}>
        <li>
          Provide sufficient information that allows us to reasonably verify you
          are the person about whom we collected personal information or an
          authorized representative.
        </li>
        <li>
          Describe your request with sufficient detail that allows us to
          properly understand, evaluate, and respond to it.
        </li>
      </ol>

      <p className={`${sectionDescriptionStyles} ml-3`}>
        We cannot respond to your request or provide you with personal
        information if we cannot verify your identity or authority to make the
        request and confirm the personal information relates to you.
      </p>

      <p className={`${sectionDescriptionStyles} ml-3`}>
        Making a verifiable consumer request does not require you to create an
        account with us. However, we do consider requests made through your
        password-protected account sufficiently verified when the request
        relates to personal information associated with that specific account.
      </p>

      <p className={`${sectionDescriptionStyles} ml-3`}>
        We will only use personal information provided in a verifiable consumer
        request to verify the requestor’s identity or authority to make the
        request.
      </p>

      <p className={`${sectionDescriptionStyles} ml-3`}>
        For instructions on exercising sale opt-out rights, see Personal
        Information Sales Opt-Out and Opt-In Rights .
      </p>

      <h3 className={`${sectionTitleStyles} ml-3`}>
        Response Timing and Format
      </h3>
      <p className={`${sectionDescriptionStyles} ml-3`}>
        We endeavor to respond to a verifiable consumer request within
        forty-five (45) days of its receipt. If we require more time (up to 90
        days), we will inform you of the reason and extension period in writing.
      </p>

      <p className={`${sectionDescriptionStyles} ml-3`}>
        If you have an account with us, we will deliver our written response to
        that account. If you do not have an account with us, we will deliver our
        written response by mail or electronically, at your option.
      </p>

      <p className={`${sectionDescriptionStyles} ml-3`}>
        Any disclosures we provide will only cover the 12-month period preceding
        the verifiable consumer request’s receipt. The response we provide will
        also explain the reasons we cannot comply with a request, if applicable.
        For data portability requests, we will select a format to provide your
        personal information that is readily useable and should allow you to
        transmit the information from one entity to another entity without
        hindrance.
      </p>

      <p className={`${sectionDescriptionStyles} ml-3`}>
        We do not charge a fee to process or respond to your verifiable consumer
        request unless it is excessive, repetitive, or manifestly unfounded. If
        we determine that the request warrants a fee, we will tell you why we
        made that decision and provide you with a cost estimate before
        completing your request.
      </p>

      <h3 className={`${sectionTitleStyles} ml-3`}>
        Personal Information Sales Opt-Out and Opt-In Rights
      </h3>
      <p className={`${sectionDescriptionStyles} ml-3`}>
        If you are 16 years of age or older, you have the right to direct us to
        not sell your personal information at any time (the "right to opt-out").
        We do not sell the personal information of consumers we actually know
        are less than 16 years of age.
      </p>

      <p className={`${sectionDescriptionStyles} ml-3`}>
        To exercise the right to opt-out, you (or your authorized
        representative) may submit a request to us by emailing{" "}
        <a
          href="mailto:compliance@unlistedinc.com"
          target="_blank"
          className="text-sky-600 underline"
        >
          compliance@unlistedinc.com
        </a>
        .
      </p>

      <p className={`${sectionDescriptionStyles} ml-3`}>
        Once you make an opt-out request, we will wait at least twelve (12)
        months before asking you to reauthorize personal information sales.
      </p>

      <p className={`${sectionDescriptionStyles} ml-3`}>
        You do not need to create an account with us to exercise your opt-out
        rights. We will only use personal information provided in an opt-out
        request to review and comply with the request.
      </p>
      <h2 className={sectionTitleStyles}>Non-Discrimination</h2>
      <p className={`${sectionDescriptionStyles} pl-3`}>
        We will not discriminate against you for exercising any of your CCPA
        rights. Unless permitted by the CCPA, we will not:
      </p>

      <ol className={`${sectionListStyle} pl-3`}>
        <li>Deny you goods or services.</li>
        <li>
          Charge you different prices or rates for goods or services, including
          through granting discounts or other benefits, or imposing penalties.
        </li>
        <li>Provide you a different level or quality of goods or services.</li>
        <li>
          Suggest that you may receive a different price or rate for goods or
          services or a different level or quality of goods or services.
        </li>
      </ol>

      <p className={`${sectionDescriptionStyles} pl-3`}>
        However, we may offer you certain financial incentives permitted by the
        CCPA that can result in different prices, rates, or quality levels. Any
        CCPA-permitted financial incentive we offer will reasonably relate to
        your personal information’s value and contain written terms that
        describe the program’s material aspects. Participation in a financial
        incentive program requires your prior opt-in consent, which you may
        revoke at any time.
      </p>

      <h2 className={sectionTitleStyles}>Other California Privacy Rights</h2>
      <p className={`${sectionDescriptionStyles} pl-3`}>
        California’s "Shine the Light" law (Civil Code Section § 1798.83)
        permits users of our Website that are California residents to request
        certain information regarding our disclosure of personal information to
        third parties for their direct marketing purposes. To make such a
        request, please send an email to{" "}
        <a
          href="mailto:compliance@unlistedinc.com"
          className="text-sky-600 underline"
        >
          compliance@unlistedinc.com
        </a>
        .
      </p>

      <h2 className={sectionTitleStyles}>Changes to This Privacy Notice</h2>
      <p className={`${sectionDescriptionStyles} pl-3`}>
        We reserve the right to amend this privacy notice at our discretion and
        at any time. When we make changes to this privacy notice, we will post
        the updated notice on the Website and update the notice’s effective
        date. Your continued use of our Website following the posting of changes
        constitutes your acceptance of such changes.
      </p>

      <h2 className={sectionTitleStyles}>Contact Information</h2>
      <p className={`${sectionDescriptionStyles} pl-3`}>
        If you have any questions or comments about this notice, the ways in
        which Unlisted Software, Inc. collects and uses your information
        described below and in our Privacy Policy, your choices and rights
        regarding such use, or wish to exercise your rights under California
        law, please do not hesitate to contact us at:
      </p>

      <p className={`${sectionDescriptionStyles} pl-3`}>
        Email:{" "}
        <a
          href="mailto:compliance@unlistedinc.com"
          className="text-sky-600 underline"
        >
          compliance@unlistedinc.com
        </a>
      </p>
    </div>
  );
}

export default CaliforniaPrivacyPolicy;
