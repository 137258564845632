import React from "react";

function ReportAbuse() {
  return (
    <div className="mx-auto w-[min(calc(100%-4rem),80ch)] px-6 pb-12 pt-32">
      <h1 className="py-2 font-attention text-3xl text-gray-900">
        Report Abuse
      </h1>
      <p className="py-2 font-content text-sm font-normal text-gray-900">
        Unlisted is a neighborly way for people to connect about their property
        plans; we do not tolerate abuse, harassment, or inappropriate behavior
        of any kind. Please help Unlisted uphold our neighborly values and make
        us aware of any inappropriate conduct at{" "}
        <a
          href="mailto:hello@unlistedhomes.com"
          className="text-sage-800 underline"
        >
          hello@unlistedhomes.com
        </a>
        .
      </p>
    </div>
  );
}

export default ReportAbuse;
