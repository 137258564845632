import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useApiIsLoaded, Map, useMap } from "@vis.gl/react-google-maps";
import { useSearchParams } from "react-router-dom";
import MapItem from "./MapItem";
import { abbreviateNumber } from "@src/utils/misc.js";
import { fetchRandomProperties } from "@src/store/features/search/service.js";
import SearchSpinner from "@src/components/searchSpinner/SearchSpinner.jsx";

function MapTab() {
  const dispatch = useDispatch();
  const { properties, randomProperties, isFetchingRandomProperties } =
    useSelector((state) => state.search);
  const [searchParams, setSearchParams] = useSearchParams();
  const [cards, setCards] = useState([]);
  const markersRef = useRef([]); // Reference to store markers
  const isLoaded = useApiIsLoaded();
  const mapRef = useMap();

  // Track current search params
  const searchParamsRef = useRef(searchParams);

  useEffect(() => {
    if (properties.length === 0 && randomProperties.length === 0) {
      dispatch(fetchRandomProperties());
    }
  }, [dispatch, properties.length, randomProperties.length]);

  useEffect(() => {
    if (properties.length > 0) {
      setCards(properties);
    } else if (randomProperties.length > 0) {
      setCards(randomProperties);
    }
  }, [randomProperties, properties]);

  // Add markers only when cards (properties) change
  useEffect(() => {
    if (!isLoaded || !mapRef || !cards.length) {
      return;
    }

    const loadMarkers = async () => {
      try {
        const { AdvancedMarkerElement } =
          await window.google.maps.importLibrary("marker");
        const bounds = new window.google.maps.LatLngBounds();

        // Clear existing markers before adding new ones
        markersRef.current.forEach((marker) => marker.setMap(null));
        markersRef.current = [];

        cards.forEach((property) => {
          if (property?.latitude && property?.longitude) {
            const priceTag = document.createElement("div");
            const estimatedValue =
              property.estimated_value || property.estimated_value_one || 0;
            priceTag.className = "price-tag";
            priceTag.textContent = abbreviateNumber(estimatedValue);

            const marker = new AdvancedMarkerElement({
              map: mapRef,
              position: { lat: property?.latitude, lng: property?.longitude },
              content: priceTag,
            });

            marker.addListener("click", () => {
              // Update the URL search parameters without re-rendering the entire component
              searchParamsRef.current = { showing: property?.id };
              setSearchParams({ showing: property?.id }, { replace: true });
            });

            markersRef.current.push(marker);
            bounds.extend(
              new window.google.maps.LatLng(
                property.latitude,
                property.longitude,
              ),
            );
          }
        });

        if (!bounds.isEmpty()) {
          mapRef.fitBounds(bounds);
        }
      } catch (error) {
        console.error("Error loading markers:", error);
      }
    };

    loadMarkers();
  }, [isLoaded, mapRef, cards]); // Only re-run this effect when cards change

  // Cleanup old markers when cards (properties) change
  useEffect(() => {
    return () => {
      markersRef.current.forEach((marker) => marker.setMap(null));
      markersRef.current = [];
    };
  }, [cards]);

  if (!isLoaded) {
    return <div>Loading Google Maps library...</div>;
  }

  if (isFetchingRandomProperties) {
    return (
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-full">
        <SearchSpinner />
      </div>
    );
  }

  return (
    <div className="pt-18 flex w-full flex-1 flex-col justify-stretch overflow-y-auto">
      <div className="relative mx-auto flex h-full w-full flex-1 flex-col gap-6 overflow-y-auto">
        {cards && cards.length > 0 && (
          <div className="flex h-full flex-col items-center justify-center">
            <div className="h-full w-full overflow-hidden rounded-lg">
              <Map
                ref={mapRef}
                mapId={"bf51a910020fa25a"}
                defaultZoom={2}
                defaultCenter={{ lat: 22.54992, lng: 0 }}
                gestureHandling={"greedy"}
                disableDefaultUI={true}
                renderingType="VECTOR"
              >
                {/* Markers are added via useEffect */}
              </Map>
            </div>
            <div className="hide-scrollbar absolute bottom-0 left-0 flex h-fit w-full snap-x snap-mandatory flex-row items-end gap-1.5 overflow-y-auto p-2">
              {cards.map((property) => (
                <MapItem key={property?.id} property={property} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MapTab;
