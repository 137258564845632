import React from "react";

function PrivacyPolicy() {
  return (
    <div className="mx-auto w-[min(calc(100%-4rem),80ch)] px-6 pb-12 pt-32">
      <h1 className="font-attention text-3xl leading-tight md:text-4xl md:leading-[4rem]">
        Privacy Policy
      </h1>

      <section className="py-1">
        <p className="py-2 font-content text-sm font-normal text-gray-900">
          Thank you for visiting the UNLISTED website, contacting UNLISTED,
          and/or using any UNLISTED mobile applications or services (the
          Services), operated by UNLISTED Software, Inc. (UNLISTED). This
          Privacy Policy is intended to describe how UNLISTED handles
          information that you provide, or that we learn about the individuals
          who: visit or use our website, mobile app or services, contact us by
          mail, email or telephone or in person, or who provide us with
          information through any other means. We recommend that you carefully
          review this notice before providing us with any information. By
          accessing and using this Site, you agree to this Privacy Policy and
          our Terms of Service.
        </p>
        <p className="mt-2 font-content text-sm font-normal text-gray-900">
          BY VISITING OR USING THE SERVICES, YOU EXPRESSLY CONSENT TO THE
          PROCESSING OF YOUR PERSONAL INFORMATION ACCORDING TO THIS PRIVACY
          POLICY. IF YOU DO NOT AGREE WITH OUR POLICIES AND PRACTICES, YOUR
          CHOICE IS TO NOT USE THE SERVICES. YOUR PERSONAL INFORMATION MAY BE
          PROCESSED IN THE COUNTRY WHERE IT WAS COLLECTED AS WELL AS OTHER
          COUNTRIES (INCLUDING THE UNITED STATES) WHERE LAWS REGARDING
          PROCESSING OF PERSONAL INFORMATION MAY BE LESS STRINGENT THAN THE LAWS
          IN YOUR COUNTRY. BY UPLOADING PERSONAL INFORMATION TO THE SERVICES YOU
          WARRANT THAT YOU HAVE THE RIGHT TO TRANSFER SUCH INFORMATION OUTSIDE
          YOUR COUNTRY AND INTO THE UNITED STATES.
        </p>
      </section>

      <section className="py-1">
        <h2 className="font-attention text-lg text-gray-900">
          Children’s Privacy
        </h2>
        <p className="mt-2 pl-3 font-content text-sm font-normal text-gray-900">
          The UNLISTED website and Services are not intended for children under
          the age of 13 and UNLISTED does not knowingly collect any information
          from children under 13 years old through its website. If the parent or
          guardian of a child under 13 believes that the child has provided us
          with any information, the parent or guardian of that child should
          contact us if they want this information deleted from our files. If
          UNLISTED obtains knowledge from any source that it has information
          about a child under 13 in retrievable form in its files, we will
          delete that information from our existing files. Children over 13 but
          under 18 years of age may only access or use the Services under the
          direct guidance and supervision of a parent or guardian whom has
          accepted the Agreement on their behalf.
        </p>
      </section>

      <section className="py-1">
        <h2 className="font-attention text-lg text-gray-900">
          What Information Does UNLISTED Collect and How is it Used?
        </h2>

        <h3 className="pl-3 pt-2 font-attention text-base leading-6">
          Personal Information
        </h3>
        <p className="mt-2 pl-6 font-content text-sm font-normal text-gray-900">
          UNLISTED will only collect Personal Information that you voluntarily
          provide to us or our service providers. The Personal Information we
          may collect includes, among other things, your name, city, e-mail
          address, telephone number, and your interest in specific types of
          products and/or services. You may also voluntarily provide Personal
          Information when you choose to engage in the following activities:
        </p>
        <ul className="mb-2 list-disc pl-10 text-sm">
          <li>request information;</li>
          <li>participate in a survey;</li>
          <li>interact with our social media pages;</li>
          <li>subscribe to one of our newsletters;</li>
          <li>
            participate in other activities offered by UNLISTED that require
            your Personal Information;
          </li>
          <li>
            post content where permitted on our website or our other online
            locations.
          </li>
        </ul>
        <p className="mt-2 pl-6 font-content text-sm font-normal text-gray-900">
          If you're using the Services on your mobile device, you may may also
          choose to provide us with location data or with access to your camera
          roll. If you grant us with access to your camera roll, we will scan
          your camera roll to identify images that we contain food pictures that
          you may wish to post to the Services. We will not post or otherwise
          share any other images from your photo roll without your express
          permission. You can still use the Services if you do not grant us
          permission to access your location data or camera roll, however, you
          may not be able to use all the features of the Services.
        </p>

        <h3 className="pl-3 pt-2 font-attention text-base leading-6">
          Public Posting
        </h3>
        <p className="mt-2 pl-6 font-content text-sm font-normal text-gray-900">
          You may also choose to post or upload additional information, such as
          videos, photos, comments, reviews or other content (“User Content”) to
          publicly available portions of the Services. User Content you post on
          a public forum or make publicly available will be available to other
          users of the Website and may be retrievable by third party search
          engines. Third parties may also be able to download or share your User
          Content to social media sites such as Facebook, Pinterest, Twitter,
          Google+ as well as via email. We recommend that you guard your privacy
          and anonymity and not upload any User Content that you are not
          comfortable being available to the general public. Any third party
          with access to your information via the Services will be permitted to
          use the information in the same manner as if you submitted the
          information directly to that third party. Publicly posting any
          information on the Services is entirely voluntary on your part and we
          recommend you carefully consider the information you choose to make
          publicly available.
        </p>

        <h3 className="pl-3 pt-2 font-attention text-base leading-6">
          How We Use Your Information
        </h3>
        <p className="mt-2 pl-6 font-content text-sm font-normal text-gray-900">
          We use the information we collect to provide you full access and
          functionality of the Services. Accordingly, your information may be
          used for the following purposes:
        </p>
        <ul className="mb-2 list-disc pl-10 text-sm">
          <li>To provide and improve our services, features, and content;</li>
          <li>To administer your use of our services and accounts;</li>
          <li>To enable users to enjoy and easily navigate the Services;</li>
          <li>To better understand your needs and interests;</li>
          <li>
            To fulfill requests or to respond to questions or comments you may
            make;
          </li>
          <li>To personalize your experience;</li>
          <li>
            To provide you with announcements, notifications, and advertisements
            related to your interests and use of the Services and other
            communications such as electronic newsletters, promotional e-mails,
            or similar messaging;
          </li>
          <li>To provide service announcements;</li>
          <li>To protect against users seeking to hack into the Services;</li>
          <li>To assess the level of general interest in the Services;</li>
          <li>For any other purpose with your consent.</li>
        </ul>
      </section>
      <section className="py-1">
        <h2 className="font-attention text-lg text-gray-900">
          With Whom Do We Share Your Information?
        </h2>

        <h3 className="pl-3 pt-2 font-attention text-base leading-6">
          Personal Information and Purchase Information
        </h3>
        <p className="mt-2 pl-6 font-content text-sm font-normal text-gray-900">
          UNLISTED may share Personal Information with certain third parties
          such as our agents, service providers, and other representatives
          acting on our behalf. For example, we may share Personal Information
          with third parties to perform services on our behalf such as:
        </p>
        <ul className="mb-2 list-disc pl-10 text-sm">
          <li>providing you access to the Services;</li>
          <li>fulfilling your requests;</li>
          <li>administering your account;</li>
          <li>sending marketing communications;</li>
          <li>conducting other business activities as needed.</li>
        </ul>

        <p className="mt-2 pl-6 font-content text-sm font-normal text-gray-900">
          The third parties with whom we conduct business are authorized to use
          your information only to perform the service for which they are hired.
          They are required to abide by the terms of our Privacy Policy,
          including taking reasonable measures to ensure your personal
          information is secure. On occasion, we contract with trusted
          third-party providers who may receive your Personal Information and
          conduct anonymized aggregate analyses of the data. We require our
          contracting partners to maintain adequate security of Personal
          Information provided to them. We do not permit such third parties to
          sell your Personal Information to other third parties.
        </p>

        <h3 className="pl-3 pt-2 font-attention text-base leading-6">
          Other Ways We May Share Your Personal Information
        </h3>
        <p className="mt-2 pl-6 font-content text-sm font-normal text-gray-900">
          We may disclose non-personally identifiable aggregated information
          about our users without restriction. Other than to its employees,
          contractors, and affiliated organizations or as described above, we
          disclose personally-identifying information only when required to do
          so by law, or when we believe in good faith that disclosure is
          reasonably necessary to protect the property or rights of UNLISTED,
          third parties, or the public at large.
        </p>
        <p className="mt-2 pl-6 font-content text-sm font-normal text-gray-900">
          In addition, in some cases we may choose to buy or sell assets. In
          these types of transactions, user information is typically one of the
          business assets that is transferred. Moreover, if UNLISTED or
          substantially all of its assets were acquired, or in the unlikely
          event that UNLISTED goes out of business or enters bankruptcy, user
          information would be one of the assets that is transferred or acquired
          by a third party. You acknowledge that such transfers may occur, and
          that any acquirer of UNLISTED may continue to use your personal and
          non-personal information only as set forth in this policy.
        </p>
      </section>
      <section className="py-1">
        <h2 className="font-attention text-lg text-gray-900">Security</h2>
        <p className="mt-2 pl-3 font-content text-sm font-normal text-gray-900">
          We are committed to protecting your Personal Information. We regularly
          test our facilities and use a variety of security technologies and
          procedures to help protect your personal information from unauthorized
          access, use, or disclosure.
        </p>
      </section>
      <section className="py-1">
        <h2 className="font-attention text-lg text-gray-900">
          We Do Not Sell Your Personal Information
        </h2>
        <p className="mt-2 pl-3 font-content text-sm font-normal text-gray-900">
          UNLISTED does not, to the best of our knowledge, sell or rent personal
          information that we have collected or retain about you to any other
          third-party for any purpose. Accordingly, we do not offer individuals
          the ability to 'opt-out' of the selling or renting of personal
          information because we do not engage in those practices.
        </p>
      </section>
      <section className="py-1">
        <h2 className="font-attention text-lg text-gray-900">
          Our Data Retention Criteria
        </h2>
        <p className="mt-2 pl-3 font-content text-sm font-normal text-gray-900">
          The period during which we store your personal information varies
          depending on the purpose for the processing. For example, we store
          personal information needed to provide you with products and services,
          or to facilitate transactions you have requested, for so long as you
          are a customer of UNLISTED. We store your personal information for
          marketing purposes until you have opted-out of receiving further
          direct marketing communications in accordance with applicable law. In
          all other cases, we store your personal information for as long as is
          needed to fulfill the purposes outlined in this Privacy Policy,
          following which time it is either anonymized (where permitted by
          applicable law), deleted or destroyed.
        </p>
      </section>
      <section className="py-1">
        <h2 className="font-attention text-lg text-gray-900">
          Our Use of Cookies and Analytical Tools
        </h2>
        <p className="mt-2 pl-3 font-content text-sm font-normal text-gray-900">
          Cookies are text files containing small amounts of information which
          are downloaded to your hard disk or to your browser's memory when you
          visit one of our Sites. Cookies are useful because they help arrange
          the content and layout of our Sites and allow us to recognize those
          computers or other devices that have been to our Sites before. Cookies
          do many different jobs, such as allowing our Sites to remember your
          preference settings and helping us to enhance the usability and
          performance of our Sites and your experience using them. Our Sites
          also may contain electronic images known as web beacons – sometimes
          called single-pixel gifs – that allow us to count the number of users
          who have visited specific pages. We may also include web beacons in
          promotional e-mail messages or newsletters in order to determine
          whether messages have been opened and acted upon. The type of cookie
          or similar technology that may be used on our Sites can be categorized
          as follows: Strictly Necessary, Performance, Functionality & Profile
          and Advertising.
        </p>
        <ul className="mb-2 list-disc pl-6 text-sm">
          <li>
            <h4 className="py-2 font-attention text-sm leading-6">
              Performance Cookies
            </h4>
            These cookies collect information about how you use our Sites, for
            example which pages you go to most often and if you get any error
            messages from certain pages. These cookies collect information that
            is used to improve how our Sites work. Without these cookies we
            cannot learn how our Sites are performing and make relevant
            improvements that could better your browsing experience. Examples of
            performance cookies that our Sites use include cookies from Google
            and Adobe Analytics (see further discussion below).
          </li>

          <li>
            <h4 className="py-2 font-attention text-sm leading-6">
              Functionality & Profile Cookies
            </h4>
            These cookies allow our Sites to store information that you provide,
            such as preferences, and to store technical information useful for
            your interactions with our Sites. For instance, they remember your
            user ID and elements of your user profile. They also ensure that
            your experience using the Sites is relevant to you. They may also be
            used to provide services you have asked for such as watching a video
            or commenting on a blog. These cookies will not be used to track
            your browsing activity on other websites. Without these cookies, a
            website cannot remember choices you have previously made or
            personalize your browsing experience. For example, we use a cookie
            to store your language preferences, which allows us to present you
            with product search results in the correct language, and we use a
            cookie to store your choice about the appearance of the cookie
            information banner that we display on our Sites. This cookie will
            help us remember your choice about the appearance of the cookie
            information banner when you subsequently visit the same site where
            you made your choice about the banner and any other UNLISTED sites
            with the same domain or the same top-level domain.
          </li>

          <li>
            <h4 className="py-2 font-attention text-sm leading-6">
              Advertising Cookies and Similar Technologies
            </h4>
            These cookies or similar technologies may be used to deliver
            advertisements that are more relevant to you and your interests.
            They may also be used to limit the times you see an advertisement as
            well as help to measure the effectiveness of the advertising
            campaign. These cookies may track your visits to other websites.
            Without these cookies or other technologies, online advertisements
            you encounter will be less relevant to you and your interests.
          </li>
        </ul>
      </section>
      <section className="py-1">
        <h2 className="font-attention text-lg text-gray-900">
          Setting Your Cookie Preference
        </h2>
        <p className="mt-2 pl-3 font-content text-sm font-normal text-gray-900">
          You can usually modify your browser settings to decline cookies and
          you can withdraw your consent at any time by modifying the settings of
          your browser to reject or disable cookies or by opting out of specific
          cookies through the opt-out options shared below. If you choose to
          decline cookies altogether, you may not be able to fully experience
          the features of the Sites that you visit.
        </p>

        <h2 className="font-attention text-lg text-gray-900">
          Our Use of Web Analytics
        </h2>
        <p className="mt-2 pl-3 font-content text-sm font-normal text-gray-900">
          We use different analytic tools which serve the purpose of measuring,
          analyzing and optimizing our marketing measures and provide you with
          customized advertisements that could be of particular interest to you.
          In particular, we use the following tools:
        </p>
        <ul className="mb-2 list-disc pl-6 text-sm">
          <li>
            <h4 className="py-2 font-attention text-sm leading-6">
              Google Analytics
            </h4>
            These cookies enable an analysis of your use of the Sites. The
            information collected (IP address, browsing activities, and other
            data linked to your usage of the Sites) is usually transferred to a
            Google server in the USA and stored there. You can prevent Google
            Analytics from recognizing you on return visits to the Sites by
            disabling cookies on your browser. To see how you can opt-out of
            certain Google features, visit:{" "}
            <a
              href="https://tools.google.com/dlpage/gaoptout"
              target="_blank"
              className="text-sage-500 underline"
            >
              Google Analytics Opt-Out Link.
            </a>
          </li>

          <li>
            <h4 className="py-2 font-attention text-sm leading-6">
              Google Tag Manager
            </h4>
            Helps to manage website tags through a single interface. The tool
            itself (implementing the tags) triggers other tags that may
            themselves collect (personal) data. However, Google Tag Manager does
            not access this data.
          </li>

          <li>
            <h4 className="py-2 font-attention text-sm leading-6">
              LinkedIn Insight Tag
            </h4>
            The LinkedIn Insight Tag is a lightweight JavaScript tag that powers
            conversion tracking, website audiences, and website demographics for
            LinkedIn ad campaigns. The Insight Tag is incorporated as a standard
            component of our Site to enable these LinkedIn features. To see how
            you can opt-out of certain LinkedIn features, visit:{" "}
            <a
              href="https://www.linkedin.com/psettings/guest-controls"
              target="_blank"
              className="text-sage-500 underline"
            >
              LinkedIn Opt-Out Link.
            </a>
          </li>
        </ul>
        <section className="py-1">
          <h2 className="font-attention text-lg text-gray-900">
            Your Rights and Responsibilities
          </h2>
          <p className="mt-2 pl-3 font-content text-sm font-normal text-gray-900">
            You are permitted, and hereby agree, to only provide personal
            information to UNLISTED if such personal information is accurate,
            reliable, and relevant to our relationship.
          </p>
          <ul className="mb-2 list-disc pl-6 text-sm">
            <li>
              <h4 className="py-2 font-attention text-sm leading-6">
                Marketing
              </h4>
              You have the right to opt-out of receiving electronic direct
              marketing communications from us. All electronic direct marketing
              communications that you may receive from us, such as email
              messages, will give you an option of not receiving such
              communications from us in the future.
            </li>

            <li>
              <h4 className="py-2 font-attention text-sm leading-6">
                California Privacy Rights
              </h4>
              California Civil Code Section § 1798.83 permits users of the Sites
              that are California residents to request certain information
              regarding our disclosure of personal information to third parties
              for their direct marketing purposes. Pursuant to the California
              Consumer Privacy Act of 2018, as amended ('CCPA'), California
              residents may have certain data privacy rights, such as the right
              to be notified about what information categories are collected
              about you, and our intended use and purpose for collecting your
              personal information. You have the right to request access to your
              personal information and, to the extent feasible, request that it
              be transmitted in certain forms and formats. You may have the
              right to request that we (and any applicable service provider)
              delete your personal information. You have the right not to be
              subject to discrimination for asserting your rights under the
              CCPA. If you make, or an authorized agent on your behalf makes,
              any request related to your personal information, UNLISTED will
              ascertain your identity to the degree of certainty required under
              the CCPA before addressing your request. UNLISTED may require you
              to match at least three pieces of personal information we have
              previously collected from you before granting you access or
              otherwise responding to your request.
            </li>

            <li>
              <h4 className="py-2 font-attention text-sm leading-6">
                Do Not Track
              </h4>
              Some web browsers may transmit “do-not-track” signals to the Sites
              with which the user communicates. Because of differences in how
              web browsers incorporate and activate this feature, it is not
              always clear whether users intend for these signals to be
              transmitted, or whether they even are aware of them. We currently
              do not take action in response to these signals.
            </li>
          </ul>
        </section>
        <section className="py-1">
          <h2 className="font-attention text-lg text-gray-900">
            Nevada Privacy Disclaimer
          </h2>
          <p className="mt-2 pl-3 font-content text-sm font-normal text-gray-900">
            Pursuant to Nevada law, a Nevada "consumer" (as the term is defined
            therein), may, at any time, submit a verified request through a
            designated request address to an "operator" directing the operator
            not to make any sale of his or her personal information that the
            operator has collected or will collect about the consumer. For
            clarity purposes, UNLISTED does not sell or exchange your personal
            information for monetary consideration to a third party for the
            third party to license or sell the information to additional persons
            or parties.
          </p>
        </section>

        <section className="py-1">
          <h2 className="font-attention text-lg text-gray-900">
            Links to Third-Party Websites
          </h2>
          <p className="mt-2 pl-3 font-content text-sm font-normal text-gray-900">
            The Services may contain links to other sites that are not operated
            by us. If you click on a third-party link, you will be directed to
            that third party's site. We strongly advise you to review the
            Privacy Policy of every site you visit. We have no control over, and
            assume no responsibility for the content, privacy policies or
            practices of any third-party sites or services.
          </p>
        </section>

        <section className="py-1">
          <h2 className="font-attention text-lg text-gray-900">
            Changes to This Privacy Policy
          </h2>
          <p className="mt-2 pl-3 font-content text-sm font-normal text-gray-900">
            We may occasionally update this Privacy Policy. When we do, we will
            revise the "last updated" date at the top of the Privacy Policy and
            take such additional steps as may be required by law.
          </p>
        </section>

        <section className="py-1">
          <h2 className="font-attention text-lg text-gray-900">Contact Us</h2>
          <p className="mt-2 pl-3 font-content text-sm font-normal text-gray-900">
            If you have questions regarding this Privacy Policy, our handling of
            your personal information, or would like to request more information
            or exercise a data right, please contact us via email at
            compliance@unlistedinc.com.
          </p>
        </section>
      </section>
    </div>
  );
}

export default PrivacyPolicy;
