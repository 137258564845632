import { getRequest, patchRequest, postRequest } from "@src/utils/axiosClient";
import CryptoJS from "crypto-js";
const PRESIGNED_URL_ENDPOINT = "/photo_presigned_url";
export const fetchPropertyById = async (propertyId) => {
  const response = await getRequest({
    url: `${import.meta.env.VITE_API_URL}/property/${propertyId}`,
  });

  return response.data;
};

export async function getPropertyDataByInviteCode(invitationCode) {
  try {
    const response = await postRequest({
      url: "/proposals/invitation/",
      payload: { invitation_code: invitationCode },
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function addPropertyOwner(propertyId) {
  try {
    const response = await patchRequest({
      url: `/property/${propertyId}/ownership/`,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function likeProperty(propertyId) {
  try {
    const response = await postRequest({
      url: `/properties/${propertyId}/like`,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function unlikeProperty(propertyId) {
  try {
    const response = await postRequest({
      url: `/properties/${propertyId}/unlike`,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export const fetchLikedProperties = async (params) => {
  try {
    const response = await getRequest({
      url: "/api/v1/properties/liked",
      params,
    });

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

/**
 * This takes a single property id and returns
 * the liked status
 * @param {number} propertyId
 * @returns
 */
export const fetchPropertyLikedStatus = async (propertyId) => {
  try {
    const response = await getRequest({
      url: `/properties/${propertyId}/liked`,
    });

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

/**
 * This takes an array of ids under "property_ids" and
 * returns their liked status
 * @param {object} params
 * @returns
 * Example return: {
    "liked_status": {
        "17": true,
        "20": false,
    }
  }
 */
export const fetchPropertiesLikedStatus = async (params) => {
  try {
    const response = await getRequest({
      url: "/api/v1/properties/liked_status",
      params,
    });

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export async function updatePropertyViaApi(propertyData) {
  try {
    const response = await patchRequest({
      url: `/owner_info/${propertyData.property_id}/`,
      payload: propertyData,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

const getmd5 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (fileEvent) => {
      let binary = CryptoJS.lib.WordArray.create(fileEvent.target.result);
      const md5 = CryptoJS.MD5(binary);
      resolve(md5);
    };
    reader.onerror = () => {
      reject("file reader error");
    };
    reader.readAsArrayBuffer(file);
  });
};

const getFileChecksum = async (file) => {
  const md5 = await getmd5(file);
  const checksum = md5.toString(CryptoJS.enc.Base64);
  return checksum;
};

const createPresignedUrl = async (
  fileName,
  fileType,
  byteSize,
  checksum,
  type,
  propertyId,
) => {
  // Note: we don't send all of these options at the moment because the AWS Ruby SDK doesn't take in some of them
  const content_type = fileType;
  let options = {
    filename: fileName,
    byte_size: byteSize,
    checksum: checksum,
    content_type: content_type,
    metadata: {
      type: type,
    },
    directory: type,
    propertyId: propertyId,
  };
  console.log("options", options);
  let response = postRequest({ url: "/photo_presigned_url", payload: options });
  if (response.status !== 200) return response;
  return response;
};

export const updatePropertyMainImageViaApi = async (
  file,
  type,
  propertyId = 0,
) => {
  const checksum = await getFileChecksum(file);
  if (
    file.type !== "image/png" &&
    file.type !== "image/jpg" &&
    file.type !== "image/jpeg" &&
    file.type !== "image/gif"
  ) {
    return "invalid file type";
  }
  const presignedUrlParams = await createPresignedUrl(
    file.name,
    file.type,
    file.size,
    checksum,
    type,
    propertyId,
  );

  console.log("reached here", file, type, propertyId);

  const s3options = {
    method: "PUT",
    body: file,
  };

  let s3response = await fetch(presignedUrlParams.data.url, s3options);

  if (s3response.status !== 200) return false;

  if (type === "propertycoverphotos") {
    const response = await patchRequest({
      url: `/properties/${propertyId}/quiz/`,
      payload: {
        photo: presignedUrlParams.data.get_url,
      },
    });
    if (response.status !== 200) {
      return false;
    } else {
      return presignedUrlParams.data;
    }
  }
};
