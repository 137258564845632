import { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { useMapsLibrary } from "@vis.gl/react-google-maps";
import {
  closeSearchPanel,
  setMode,
  openSearchOptionPanel,
  setSearchOptionType,
  setBedrooms,
  setBathrooms,
  setPriceRange,
  setSquareFootage,
  setTraditionalSearchString,
  setAiSearchString,
  setIsSearching,
  setIsTraditionalSearchingFailure,
  setIsAiSearchingFailure,
} from "../../store/features/search/slice.js";
import { searchProperties } from "../../store/features/search/service.js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { AnimatePresence, motion, useAnimationControls } from "framer-motion";
import { MdArrowBack, MdSearch, MdArrowForward } from "react-icons/md";
import SparkleIcon from "@src/assets/svgs/SparkleIcon";
import RangeSelector from "../RangeSelector.jsx";
import MultiSelectRadioGroup from "../MultiSelectRadioGroup.jsx";
import TextValueDisplay from "../TextValueDisplay.jsx";
import TagsValueDisplay from "../TagsValueDisplay.jsx";
import SearchPerks from "./SearchPerks.jsx";
import SearchOptionPanel from "./SearchOptionPanel.jsx";
import SearchArchitecturalStyles from "./SearchArchitecturalStyles.jsx";
import architectural_styles from "../../data/architectural_styles.js";
import property_perks from "@src/data/property_perks.js";
import SearchSpinner from "@src/components/searchSpinner/SearchSpinner.jsx";
import { formatGoogleAPIPlace } from "@src/utils/misc.js";

function SearchPanel() {
  const bedroomStrings = ["Any", "1", "2", "3", "4", "5+"];
  const bathroomStrings = ["Any", "1", "1.5", "2", "3", "4+"];
  const places = useMapsLibrary("places");
  const { properties, newResults } = useSelector((state) => state.search);

  const searchOptions = [
    {
      id: "architectural_styles",
      label: "Architectural Styles",
      component: <SearchArchitecturalStyles />,
    },
    { id: "perks", label: "Perks", component: <SearchPerks /> },
  ];

  // Default values
  const defaultPriceRangeSetting = [750000, 10000000]; // From initialValueMin and initialValueMax
  const defaultSquareFootageSetting = [500, 12000]; // From initialValueMin and initialValueMax
  const defaultBedrooms = ["Any"];
  const defaultBathrooms = ["Any"];

  const [lastSearchValues, setLastSearchValues] = useState({
    priceRangeSetting: defaultPriceRangeSetting,
    squareFootageSetting: defaultSquareFootageSetting,
    bedrooms: defaultBedrooms,
    bathrooms: defaultBathrooms,
    selectedArchitecturalStyles: [],
    selectedPropertyPerks: [],
  });

  const {
    isShowingSearchPanel,
    isSearching,
    isTraditionalSearchingFailure,
    isAiSearchingFailure,
    currentMode,
    searchOptionType,
    selectedArchitecturalStyles,
    selectedPropertyPerks,
    bedrooms,
    bathrooms,
    priceRange,
    squareFootage,
    traditionalSearchString,
    aiSearchString,
  } = useSelector((state) => state.search);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const controls = useAnimationControls();
  const [searchOptionLabel, setSearchOptionLabel] = useState("");
  const [searchOptionComponent, setSearchOptionComponent] = useState(null);
  const [showBedroomOptions, setShowBedroomOptions] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentArchitecturalStyles, setCurrentArchitecturalStyles] = useState(
    [],
  );
  const location = useLocation();
  // Initialize state with default values
  const [priceRangeSetting, setPriceRangeSetting] = useState(
    defaultPriceRangeSetting,
  );
  const [squareFootageSetting, setSquareFootageSetting] = useState(
    defaultSquareFootageSetting,
  );

  const [currentPropertyPerks, setCurrentPropertyPerks] = useState([]);

  function arraysEqual(a, b) {
    if (a.length !== b.length) return false;
    const aSorted = [...a].sort();
    const bSorted = [...b].sort();
    for (let i = 0; i < aSorted.length; i++) {
      if (aSorted[i] !== bSorted[i]) return false;
    }
    return true;
  }

  const isAnySelectorChanged = useMemo(() => {
    const isPriceRangeChanged =
      priceRangeSetting[0] !== lastSearchValues.priceRangeSetting[0] ||
      priceRangeSetting[1] !== lastSearchValues.priceRangeSetting[1];

    const isSquareFootageChanged =
      squareFootageSetting[0] !== lastSearchValues.squareFootageSetting[0] ||
      squareFootageSetting[1] !== lastSearchValues.squareFootageSetting[1];

    const isBedroomsChanged = !arraysEqual(bedrooms, lastSearchValues.bedrooms);
    const isBathroomsChanged = !arraysEqual(
      bathrooms,
      lastSearchValues.bathrooms,
    );

    const isArchitecturalStylesChanged = !arraysEqual(
      selectedArchitecturalStyles,
      lastSearchValues.selectedArchitecturalStyles,
    );
    const isPropertyPerksChanged = !arraysEqual(
      selectedPropertyPerks,
      lastSearchValues.selectedPropertyPerks,
    );

    return (
      isPriceRangeChanged ||
      isSquareFootageChanged ||
      isBedroomsChanged ||
      isBathroomsChanged ||
      isArchitecturalStylesChanged ||
      isPropertyPerksChanged
    );
  }, [
    priceRangeSetting,
    squareFootageSetting,
    bedrooms,
    bathrooms,
    selectedArchitecturalStyles,
    selectedPropertyPerks,
    lastSearchValues,
  ]);

  const handlePriceRangeChange = useCallback((indexes, indexedValues) => {
    const values = [indexedValues[indexes[0]], indexedValues[indexes[1]]];
    setPriceRangeSetting(values);
    dispatch(setPriceRange(indexes));
  }, []);

  const handleSquareFeetChange = useCallback((indexes, indexedValues) => {
    const values = [indexedValues[indexes[0]], indexedValues[indexes[1]]];
    setSquareFootageSetting(values);
    dispatch(setSquareFootage(indexes));
  }, []);

  const handleSearchPanelClose = () => {
    dispatch(closeSearchPanel());
  };

  const handleModeSwitch = (mode) => {
    dispatch(setMode(mode));
  };

  const handleSearchOptionClick = (id) => {
    dispatch(setSearchOptionType(id));
    dispatch(openSearchOptionPanel());
  };

  const handleBedroomChange = (value) => {
    const stringValues = value.map((index) => bedroomStrings[index]);
    dispatch(setBedrooms(stringValues));
  };

  const handleBathroomChange = (value) => {
    const stringValues = value.map((index) => bathroomStrings[index]);
    dispatch(setBathrooms(stringValues));
  };

  const containerVariants = {
    close: {
      x: "100%",
      transition: {
        type: "easeOut",
        damping: 0,
        duration: 0.125,
      },
    },
    open: {
      x: "0%",
      transition: {
        type: "easeOut",
        damping: 0,
        duration: 0.125,
      },
    },
  };

  const onPlaceSelected = (place) => {
    if (!place?.formatted_address) return;
    setSelectedPlace(place);
  };

  const onSearchQueryChange = async (e) => {
    dispatch(setIsTraditionalSearchingFailure(false));
    dispatch(setTraditionalSearchString(e.target.value));
  };

  useEffect(() => {
    if (isShowingSearchPanel) {
      controls.start("open");
    } else {
      controls.start("close");
    }
  }, [isShowingSearchPanel]);

  useEffect(() => {
    const currentStyles = selectedArchitecturalStyles
      .map((style) => architectural_styles.find(({ id }) => id === style).label)
      .sort()
      .join(", ");
    setCurrentArchitecturalStyles(currentStyles);

    const currentPerks = selectedPropertyPerks
      .map((perk) => property_perks.find(({ id }) => id === perk))
      .sort((a, b) => a.text.localeCompare(b.text));
    setCurrentPropertyPerks(currentPerks);
  }, [selectedArchitecturalStyles, selectedPropertyPerks]);

  useEffect(() => {
    if (searchOptionType) {
      const searchOption = searchOptions.find(
        (option) => option.id === searchOptionType,
      );
      setSearchOptionLabel(searchOption.label);
      setSearchOptionComponent(searchOption.component);
    }
  }, [searchOptionType]);

  useEffect(() => {
    if (properties.length === 1) {
      setSearchParams({ showing: properties[0].id });
      handleSearchPanelClose();
    } else if (properties.length > 1 && isShowingSearchPanel) {
      //if the user is on a page that starts with /discover, just close the search panel
      if (!location.pathname.startsWith("/discover")) {
        navigate("/discover/cards");
      }
      handleSearchPanelClose();
    }
  }, [newResults]);

  useEffect(() => {
    if (selectedPlace) {
      dispatch(setTraditionalSearchString(selectedPlace.formatted_address));
      handleSearchClick();
    }
  }, [selectedPlace]);

  useEffect(() => {
    // Set initial price range if not already set
    if (!priceRangeSetting[0] && !priceRangeSetting[1]) {
      setPriceRangeSetting(defaultPriceRangeSetting);
    }
    // Set initial square footage if not already set
    if (!squareFootageSetting[0] && !squareFootageSetting[1]) {
      setSquareFootageSetting(defaultSquareFootageSetting);
    }
  }, []);

  const handleSearchClick = async () => {
    console.log("price range", priceRangeSetting);
    console.log("square footage", squareFootageSetting);
    const searchData = formatGoogleAPIPlace({ place: selectedPlace });

    //add in price range, square footage, bedrooms, bathrooms, architectural styles, and property perks
    searchData.price_min = String(priceRangeSetting[0]);
    searchData.price_max = String(priceRangeSetting[1]);
    searchData.area_min = String(squareFootageSetting[0]);
    searchData.area_max = String(squareFootageSetting[1]);
    searchData.beds = bedrooms[0];
    searchData.baths = bathrooms[0];
    searchData.style = selectedArchitecturalStyles;

    //for each selectedPropertyPerks add to searchData[key] = true
    selectedPropertyPerks.forEach((perk) => {
      searchData[perk] = true;
    });
    console.log("searchData", searchData);

    setIsSearching(true);
    dispatch(searchProperties(searchData));
    // Update lastSearchValues
    setLastSearchValues({
      priceRangeSetting,
      squareFootageSetting,
      bedrooms,
      bathrooms,
      selectedArchitecturalStyles,
      selectedPropertyPerks,
    });
  };

  const handleAiSearchClick = async () => {
    dispatch(searchProperties({ question: aiSearchString }));
  };

  return (
    <>
      <motion.div
        initial="close"
        variants={containerVariants}
        animate={controls}
        className={`${isShowingSearchPanel ? "" : "pointer-events-none"} fixed top-0 z-30 h-dvh w-full max-w-[100vw] translate-x-full overflow-y-auto bg-white`}
      >
        <header className="sticky top-0 z-10 flex flex-row items-center border-b border-b-gray-300 bg-white pl-3 pt-2">
          <button
            className="shrink-0 rounded-full bg-transparent p-2 duration-300 md:hover:bg-gray-200"
            onClick={() => handleSearchPanelClose()}
          >
            <MdArrowBack className="h-6 w-6" />
          </button>
          <div className="relative ml-auto flex w-full flex-row items-center font-semibold text-gray-600">
            <button
              className={`flex flex-1 flex-row items-center justify-center gap-2 border-b-4 border-b-transparent p-3 ${currentMode === "ai" ? "pointer-events-none opacity-100" : "pointer-events-auto opacity-50"} transition-colors`}
              onClick={() => handleModeSwitch("ai")}
            >
              <SparkleIcon className="h-3 w-3 fill-gray-600" />
              AI Search
            </button>
            <button
              className={`flex flex-1 flex-row items-center justify-center gap-2 border-b-4 border-b-transparent p-3 ${currentMode === "traditional" ? "pointer-events-none opacity-100" : "pointer-events-auto opacity-50"} transition-colors`}
              onClick={() => handleModeSwitch("traditional")}
            >
              <MdSearch className="fill-gray-600" />
              Traditional
            </button>
            <div
              className={`absolute bottom-0 h-1 w-1/2 rounded-t-md bg-[#8E9991] ${currentMode === "ai" ? "left-0" : "left-1/2"} transition-all`}
            ></div>
          </div>
        </header>
        <div className="p-6">
          {currentMode === "ai" && (
            <div className="flex flex-col justify-self-center">
              <label className="mt-2 w-full text-center font-bold text-gray-600">
                Describe the home your looking for
              </label>
              <div className="mt-4 rounded-md ring-0 ring-transparent transition-shadow focus-within:ring-2 focus-within:ring-[#8E9991]/40 focus-within:ring-offset-2">
                <div className="relative inline-flex h-fit w-full overflow-hidden rounded-md">
                  <textarea
                    placeholder="Show me houses in Upper Arlington, Ohio near Tremont Elementary School with a max price of $500,000"
                    className="peer inline-block w-full resize-none rounded-md border border-[#8E9991] p-3 font-content outline-0 transition-all focus:border-[#8E9991]/50"
                    value={aiSearchString}
                    rows="6"
                    onKeyDown={(e) => {
                      // Check if the Enter key is pressed
                      if (e.key === "Enter") {
                        // Prevent default line break behavior
                        e.preventDefault();
                        if (e.target.value.trim().length === 0) return;
                        handleAiSearchClick();
                      }
                    }}
                    onChange={(e) => {
                      dispatch(setIsAiSearchingFailure(false));
                      dispatch(setAiSearchString(e.target.value));
                    }}
                  ></textarea>
                  <button
                    onClick={() => handleAiSearchClick()}
                    tabIndex={aiSearchString.trim().length === 0 ? "-1" : "0"}
                    className={` ${aiSearchString.trim().length === 0 ? "translate-y-full" : "translate-y-0"} absolute bottom-0 flex h-12 w-full flex-row items-center justify-center gap-2 bg-primary-500 text-white transition-transform`}
                  >
                    <span>Find my next home</span>{" "}
                    <MdArrowForward className="h-5 w-5 fill-white" />
                  </button>
                </div>
              </div>
              {isAiSearchingFailure && (
                <span className="block w-full p-1.5 px-1 text-sm text-red-500">
                  Sorry, your search returned no results. Please try again.
                </span>
              )}
            </div>
          )}
          {currentMode === "traditional" && (
            // put this back in for tradition text search   ${traditionalSearchString.trim().length > 0 ? "pr-32" : "pr-16"}
            <>
              <div className="flex flex-col gap-14">
                <div>
                  <div className="rounded-md ring-0 ring-transparent transition-shadow focus-within:ring-2 focus-within:ring-[#8E9991]/40 focus-within:ring-offset-2">
                    <div className="relative w-full overflow-hidden rounded-md">
                      <PlaceAutocomplete
                        textValue={traditionalSearchString}
                        className={`inline-block w-full rounded-md border border-[#8E9991] p-3 font-content outline-0 transition-all focus:border-[#8E9991]/50`}
                        onPlaceSelect={onPlaceSelected}
                        onChange={(e) => onSearchQueryChange(e)}
                      />
                      {selectedPlace && isAnySelectorChanged && (
                        <div
                          className={`absolute right-0 top-0 flex h-full flex-row ${
                            traditionalSearchString.trim().length > 0
                              ? "translate-x-0"
                              : "translate-x-1/2"
                          } transition-transform`}
                        >
                          <button
                            onClick={() => handleSearchClick()}
                            tabIndex={
                              traditionalSearchString.trim().length === 0
                                ? "-1"
                                : "0"
                            }
                            className="flex h-full w-16 flex-row items-center justify-center bg-primary-500"
                          >
                            <MdArrowForward className="m-4 h-6 w-6 fill-white" />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  {isTraditionalSearchingFailure && (
                    <span className="block w-full p-1.5 px-1 text-sm text-red-500">
                      Sorry, your search returned no results. Please try again.
                    </span>
                  )}
                </div>

                <RangeSelector
                  label="Price Range"
                  sliderValues={priceRange}
                  prepend="$"
                  min={50000}
                  max={10000000}
                  steps={[
                    { gt: 50000, step: 25000 },
                    { gt: 500000, step: 50000 },
                    { gt: 1000000, step: 250000 },
                  ]}
                  onChange={handlePriceRangeChange}
                  initialValueMin={defaultPriceRangeSetting[0]}
                  initialValueMax={defaultPriceRangeSetting[1]}
                />
                <RangeSelector
                  label="Square Feet"
                  sliderValues={squareFootage}
                  format=""
                  min={500}
                  max={12000}
                  steps={[
                    { gt: 500, step: 10 },
                    { gt: 5000, step: 10 },
                  ]}
                  onChange={handleSquareFeetChange}
                  initialValueMin={defaultSquareFootageSetting[0]}
                  initialValueMax={defaultSquareFootageSetting[1]}
                />
                <MultiSelectRadioGroup
                  label="Bedrooms"
                  items={bedroomStrings}
                  values={bedrooms}
                  allowMultiple={false}
                  onChange={handleBedroomChange}
                  //note={"Minimum"}
                />

                <MultiSelectRadioGroup
                  label="Bathrooms"
                  items={bathroomStrings}
                  values={bathrooms}
                  allowMultiple={false}
                  onChange={handleBathroomChange}
                  //note={"Minimum"}
                />
                <TextValueDisplay
                  label="Architectural Styles"
                  value={
                    currentArchitecturalStyles.length > 0
                      ? currentArchitecturalStyles
                      : "Any"
                  }
                  onClick={() =>
                    handleSearchOptionClick("architectural_styles")
                  }
                />
                <TagsValueDisplay
                  value={currentPropertyPerks}
                  label="Perks"
                  onClick={() => handleSearchOptionClick("perks")}
                />
              </div>
            </>
          )}
        </div>
        <AnimatePresence>
          {isSearching && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="absolute left-0 top-0 h-full w-full bg-white/90"
            >
              <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                <SearchSpinner />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
      <SearchOptionPanel
        label={searchOptionLabel}
        component={searchOptionComponent}
      />
    </>
  );
}

export default SearchPanel;

export const PlaceAutocomplete = ({
  onPlaceSelect,
  className,
  onChange,
  textValue,
  placeholder,
}) => {
  const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
  const inputRef = useRef(null);
  const places = useMapsLibrary("places");

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      fields: [
        "type",
        "geometry",
        "name",
        "formatted_address",
        "address_components",
      ],
      componentRestrictions: { country: "us" },
      types: [
        "administrative_area_level_1",
        "locality",
        "postal_code",
        "premise",
        "subpremise",
      ],
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener("place_changed", () => {
      onPlaceSelect(placeAutocomplete.getPlace());
    });
  }, [onPlaceSelect, placeAutocomplete]);

  return (
    <input
      ref={inputRef}
      value={textValue}
      onChange={onChange}
      className={className}
      placeholder={placeholder ?? "Address, city, state, or zip"}
    />
  );
};
