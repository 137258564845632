import React from "react";

function ContactUs() {
  return (
    <div className="mx-auto w-[min(calc(100%-4rem),80ch)] px-6 pt-32">
      <h1 className="py-2 font-attention text-3xl text-gray-900">Contact Us</h1>
      <p className="py-2 font-content text-sm font-normal text-gray-900">
        Have feedback? Want to learn more? Need some help using Unlisted? We’d
        love to hear from you. Our team monitors this email address daily and
        will reply within 1-2 business days.
      </p>
      <p className="py-2 font-content text-sm font-normal text-gray-900">
        Email:{" "}
        <a
          href="mailto:hello@unlistedhomes.com"
          className="text-sage-800 underline"
        >
          hello@unlistedhomes.com
        </a>
      </p>
    </div>
  );
}

export default ContactUs;
