import { getRequest, postRequest } from "@src/utils/axiosClient";
import { AUTHENTICATION_TOKEN, USER_ID } from "@src/utils/constants";

import * as Sentry from '@sentry/react';

export const sendUserOTPEmail = async ({ payload = {}, options = {} }) => {
  try {
    const response = await postRequest({
      url: "/otp/generate/",
      payload,
      options,
    });

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export async function verifyUserOTP(data) {
  try {
    const response = await postRequest({
      url: "/otp/verify/",
      payload: data,
    });

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function getLoggedInUserInfo() {
  try {
    console.log("getLoggedInUserInfo")
    const response = await getRequest({ url: "/users/me/" });
    Sentry.setUser({
      email: response.data.email,
    });

    return response.data;
  } catch (error) {
    //often this error is a 401 and we need to kill the expired cookie and reauthenticate
    localStorage.removeItem(AUTHENTICATION_TOKEN);
    localStorage.removeItem(USER_ID);
    window.location.href = '/signin'; // Adjust the path as needed
    throw error.response.data;
  }
}

export async function logOut() {
  try {
    const response = await postRequest({ url: "/logout/" });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function getHomeownerProperties(params) {
  try {
    const response = await getRequest({
      url: "/properties",
      params
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function postEmail(data) {
  try{
    const response = await postRequest({
      url: "/users/submit_email",
      payload: data,
    });

    return response;
  } catch (error) {
    throw error.response.data;
  }
}