import { useCallback, useEffect, useState } from "react";
import { MdSearch } from "react-icons/md";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

import { apiSearchProperties } from "@src/api/search";
import BaseModal from "@src/components/baseModal/BaseModal";
import { PlaceAutocomplete } from "@src/components/search/SearchPanel";
import CottageIcon from "@src/assets/svgs/CottageIcon";
import FindMyHomeIcon from "@src/assets/svgs/FindMyHomeIcon";
import { formatGoogleAPIPlace } from "@src/utils/misc";
import SunMoonAnimation from "@src/assets/24hr_spinner.gif";

const STEPS = [
  {
    title: "Claim your home",
    subTitle: "Search your address to find and own your property’s profile.",
  },
  {
    title: "Verify homeownership",
    subTitle: "Complete a simple, quick verification process.",
    numberStyles: "-mr-[10px]",
  },
  {
    title: "Show off your home",
    subTitle: "Upload photos and tell the story of your home.",
    numberStyles: "-mr-1",
  },
];

export default function FindMyHome() {
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [error, setError] = useState("");

  const onChangeValue = useCallback((event) => {
    setValue(event.target.value);
    setError("");
  }, []);

  const onPlaceSelect = useCallback((place) => {
    setSelectedPlace(place);
    setValue(place?.formatted_address);
  }, []);

  const handleSearchClick = async () => {
    const searchData = formatGoogleAPIPlace({ place: selectedPlace });

    setIsSearching(true);

    // Create a promise for the spinner delay
    const spinnerDelay = new Promise((resolve) => setTimeout(resolve, 2000));

    try {
      // Start the API call
      const apiCall = apiSearchProperties(searchData);

      // Wait for both the spinner delay and the API call to complete
      const [response] = await Promise.all([apiCall, spinnerDelay]);

      const property = response?.properties?.[0];

      if (property?.id) {
        navigate(`/profile/${property?.id}`);
      } else {
        setError("Sorry, we couldn't find your home.");
      }
    } catch (error) {
      setError("Sorry, we couldn't find your home.");
    }

    setIsSearching(false);
  };

  useEffect(() => {
    if (selectedPlace) handleSearchClick();
  }, [selectedPlace]);

  return (
    <section className="flex w-full flex-col justify-center pb-[74px] text-center">
      <div
        className={`flex w-full flex-col items-center border-b-[1px] border-grey-200 border-opacity-60 bg-[#F7F7F7] ${error ? "pb-3" : "pb-6"} pt-[74px]`}
      >
        <FindMyHomeIcon />
        <p className="mt-[5px] font-attention text-3xl">Add your home</p>
        <p className="mt-5 w-[min(calc(100%-2rem),40rem)] text-balance px-12 font-content text-neutral-700">
          Find out if buyers are interested in your property sooner.{" "}
          <span><span className="font-semibold">Search your address to begin</span>.</span>
        </p>

        <div className="mb-6 mt-9 flex h-[44px] w-[min(calc(100%-4rem),32rem)] items-center justify-center self-center rounded-full border border-gold-500 bg-[#FFFFFF61] pl-4 pr-3 md:mb-12">
          <PlaceAutocomplete
            textValue={value}
            className="disabled:transparent h-full w-full bg-transparent font-content focus:border-none focus:outline-none"
            onPlaceSelect={onPlaceSelect}
            onChange={onChangeValue}
            placeholder="123 Unlisted Dr, Anytown, OH"
          />
          <MdSearch className="ml-2 h-[1.5rem] w-[1.5rem]" color="#808080" />
        </div>
        {error ? (
          <motion.div
            key="popup-container"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.3 }}
          >
            <p className="mt-2 text-sm text-red-500">{error}</p>
          </motion.div>
        ) : null}
      </div>
      <div className="flex w-full flex-col items-center pt-8">
        <p className="mb-8 font-attention text-2xl md:pb-12 md:pt-16">
          How it works
        </p>

        <div className="flex w-[min(calc(100%-4rem),60rem)] flex-col items-center gap-20 sm:flex-row md:gap-32">
          {STEPS.map((item, index) => (
            <div
              className="flex max-w-xs flex-1 flex-col items-center"
              key={index}
            >
              <div className="mb-4 flex h-[117px] w-[117px] items-end justify-end overflow-hidden rounded-[26px] bg-gold-250">
                <p
                  className={`-mb-14 font-attention text-[166px] text-grey-700 opacity-10 ${item?.numberStyles ?? ""}`}
                >
                  {index + 1}
                </p>
              </div>
              <p className="mb-[2px] font-content text-lg">{item?.title}</p>
              <p className="mt-1 font-content text-sm text-grey-400">
                {item?.subTitle}
              </p>
            </div>
          ))}
        </div>
      </div>
      {isSearching ? (
        <BaseModal open={isSearching} contentWrapperStyles="px-[21px]">
          <div className="modal-shadow flex h-[269px] flex-col items-center justify-center rounded-2xl bg-grey-100">
            <div className="relative h-28 w-44 translate-y-2">
              <CottageIcon className="absolute bottom-6 left-1/2 -translate-x-1/2" />
              <img
                className="absolute bottom-0 left-1/2 max-w-[140%] -translate-x-1/2"
                src={SunMoonAnimation}
                alt="Sun and Moon animation"
              />
            </div>

            <p className="font-content">Finding your property...</p>
          </div>
        </BaseModal>
      ) : null}
    </section>
  );
}
