import { MdOutlineChat } from "react-icons/md";

export default function PropertyProfileChatButton({ onClick = () => {} }) {
  const onPressButton = (e) => {
    e.stopPropagation();
    onClick();
  };
  return (
    <div
      className="flex aspect-square h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-black/60 p-1.5"
      onClick={onPressButton}
    >
      <MdOutlineChat className="h-6 w-6 translate-y-[0.8px] fill-white/60" />
    </div>
  );
}
