import { configureStore } from "@reduxjs/toolkit";
import searchSlice from "./features/search/slice.js";
import propertySlice from "./features/property/slice.js";
import imageCacheSlice from "./features/imageCache/slice.js";
import userSlice from "./features/user/slice.js";

export const store = configureStore({
  reducer: {
    search: searchSlice,
    property: propertySlice,
    imageCache: imageCacheSlice,
    user: userSlice,
  },
});