// architecturalStyles.js
import artDecoImage from "../assets/architectural_styles/art_deco.jpeg";
import bungalowImage from "../assets/architectural_styles/bungalow.webp";
import capeCodImage from "../assets/architectural_styles/capecod.webp";
import colonialImage from "../assets/architectural_styles/colonial.webp";
import contemporaryImage from "../assets/architectural_styles/contemporary.webp";
import craftsmanImage from "../assets/architectural_styles/craftsman.webp";
import creoleImage from "../assets/architectural_styles/creole.webp";
import dutchColonialImage from "../assets/architectural_styles/dutch_colonial.webp";
import federalImage from "../assets/architectural_styles/federal.webp";
import frenchProvincialImage from "../assets/architectural_styles/french_provincial.webp";
import georgianImage from "../assets/architectural_styles/georgian.webp";
import gothicRevivalImage from "../assets/architectural_styles/gothic_revival.webp";
import greekRevivalImage from "../assets/architectural_styles/greek_revival.webp";
import internationalImage from "../assets/architectural_styles/international.webp";
import italianateImage from "../assets/architectural_styles/italianate.webp";
import montereyImage from "../assets/architectural_styles/monterey.webp";
import nationalImage from "../assets/architectural_styles/national.webp";
import neoclassicalImage from "../assets/architectural_styles/neoclassical.webp";
import prairieImage from "../assets/architectural_styles/prairie.webp";
import puebloImage from "../assets/architectural_styles/pueblo.webp";
import queenAnneImage from "../assets/architectural_styles/queenanne.webp";
import ranchImage from "../assets/architectural_styles/ranch.webp";
import regencyImage from "../assets/architectural_styles/regency.webp";
import saltboxImage from "../assets/architectural_styles/saltbox.webp";
import secondEmpireImage from "../assets/architectural_styles/second_empire.webp";
import shedImage from "../assets/architectural_styles/shed.webp";
import shingleImage from "../assets/architectural_styles/shingle.webp";
import shotgunImage from "../assets/architectural_styles/shotgun.webp";
import spanishEclecticImage from "../assets/architectural_styles/spanish_eclectic.webp";
import splitLevelImage from "../assets/architectural_styles/split_level.webp";
import stickImage from "../assets/architectural_styles/stick.webp";
import tudorImage from "../assets/architectural_styles/tudor.webp";
import victorianImage from "../assets/architectural_styles/victorian.webp";

const architecturalStyles = [
  // { id: "art_deco", label: "Art Deco", image: artDecoImage },
  { id: 13, label: "Bungalow", image: bungalowImage },
  { id: 14, label: "Cape Cod", image: capeCodImage },
  { id: 7, label: "Colonial", image: colonialImage },
  { id: 8, label: "Contemporary", image: contemporaryImage },
  // { id: "craftsman", label: "Craftsman", image: craftsmanImage },
  // { id: "creole", label: "Creole", image: creoleImage },
  { id: 64, label: "Dutch Colonial", image: dutchColonialImage },
  { id: 77, label: "Federal", image: federalImage },
  {
    id: 6,
    label: "French Provincial",
    image: frenchProvincialImage,
  },
  { id: 110, label: "Georgian", image: georgianImage },
  { id: 61, label: "Gothic Revival", image: gothicRevivalImage },
  // { id: "greek_revival", label: "Greek Revival", image: greekRevivalImage },
  // { id: "international", label: "International", image: internationalImage },
  // { id: "italianate", label: "Italianate", image: italianateImage },
  //{ id: "monterey", label: "Monterey", image: montereyImage },
  // { id: "national", label: "National", image: nationalImage },
  // { id: "neoclassical", label: "Neoclassical", image: neoclassicalImage },
  // { id: "prairie", label: "Prairie", image: prairieImage },
  // { id: "pueblo", label: "Pueblo", image: puebloImage },
  // { id: "queen_anne", label: "Queen Anne", image: queenAnneImage },
  { id: 11, label: "Ranch", image: ranchImage },
  // { id: "regency", label: "Regency", image: regencyImage },
  { id: 83, label: "Saltbox", image: saltboxImage },
  // { id: "second_empire", label: "Second Empire", image: secondEmpireImage },
  // { id: "shed", label: "Shed", image: shedImage },
  //{ id: "shingle", label: "Shingle", image: shingleImage },
  { id: 153, label: "Shotgun", image: shotgunImage },
  {
    id: 4,
    label: "Spanish Eclectic",
    image: spanishEclecticImage,
  },
  { id: 17, label: "Split Level", image: splitLevelImage },
  // {id: "stick", label: "Stick", image: stickImage},
  { id: 52, label: "Tudor", image: tudorImage },
  { id: 18, label: "Victorian", image: victorianImage },
];

export default architecturalStyles;
