import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  getUsersClaimedHomes,
  getUsersLikedHomes,
  verifyUser,
} from "./service";
import { AUTHENTICATION_TOKEN, USER_ID } from "@src/utils/constants";
import { version } from '@src/../package.json';

let cardsOnboardingComplete = JSON.parse(
  localStorage.getItem("cardsOnboardingComplete"),
);

const initialState = {
  user: {},
  homes: [],
  likedHomes: [],
  hasMoreLikedHomes: true,
  isLoadingLikedHomes: false,
  numberOfHomesUnliked: 0,
  isLoggedIn: false,
  isLoading: false,
  token: null,
  appVersion: version,
  appBuildDate: __BUILD_DATE__,
  isRunningServiceWorker: false,
  isRunningInStandAlone: window.navigator.standalone || window.matchMedia('(display-mode: standalone)').matches,
  isRunningInNativeApp: false,
  cardsOnboardingComplete: cardsOnboardingComplete || false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserToken: (state, { payload }) => {
      state.token = payload;
      state.isLoggedIn = true;
      localStorage.setItem(AUTHENTICATION_TOKEN, JSON.stringify(payload));
    },

    setCardsOnboardingComplete: (state, { payload }) => {
      state.cardsOnboardingComplete = payload;
      localStorage.setItem("cardsOnboardingComplete", payload);
    },

    setUserInfo: (state, { payload }) => {
      state.user = payload;
      if (payload.id) {
        localStorage.setItem(USER_ID, payload.id);
      }
    },
    removeUserInfo: (state) => {
      state.user = initialState.user;
      state.homes = initialState.homes;
      state.isLoggedIn = false;
      state.token = initialState.token;
      localStorage.removeItem(AUTHENTICATION_TOKEN);
      localStorage.removeItem(USER_ID);
    },
    setIsRunningServiceWorker: (state, { payload }) => {
      state.isRunningServiceWorker = payload;
    },
    setIsRunningInNativeApp: (state, { payload }) => {
      state.isRunningInNativeApp = payload;
    },
    changeStatusInLikedHome: (state, { payload }) => {
      const index = state.likedHomes.findIndex(
        (item) => item?.id === payload.propertyId,
      );
      if (state.likedHomes[index]) {
        if (!payload.liked) {
          state.numberOfHomesUnliked += 1;
        } else {
          state.numberOfHomesUnliked -= 1;
        }

        state.likedHomes[index] = {
          ...state.likedHomes[index],
          liked: payload.liked,
        };
      }
    },
    resetNumberOfHomesUnliked: (state) => {
      state.numberOfHomesUnliked = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyUser.fulfilled, (state, { payload }) => {
        state.token = payload.token;
        state.isLoggedIn = true;
        state.isLoading = false;
        localStorage.setItem(
          AUTHENTICATION_TOKEN,
          JSON.stringify(payload.token),
        );
        state.user = { ...state.user, ...payload.userInfo };
        if (payload.userInfo?.id) {
          localStorage.setItem(USER_ID, payload.userInfo.id);
        }
      })
      .addCase(verifyUser.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(
        getUsersClaimedHomes.fulfilled,
        (state, { payload, meta: { arg } }) => {
          if (arg.page === 1) state.homes = payload.properties;
          else state.homes = [...state.homes, ...payload.properties];
        },
      )
      .addCase(getUsersLikedHomes.pending, (state) => {
        state.isLoadingLikedHomes = true;
      })
      .addCase(
        getUsersLikedHomes.fulfilled,
        (state, { payload, meta: { arg } }) => {
          state.isLoadingLikedHomes = false;
          if (arg.offset === 0) {
            state.likedHomes = payload.properties;
          } else {
            state.likedHomes = [...state.likedHomes, ...payload.properties];
          }

          if (
            (state.likedHomes.length - state.numberOfHomesUnliked) >=
            payload.count
          ) {
            state.hasMoreLikedHomes = false;
          }
          else state.hasMoreLikedHomes = true;
        },
      )
      .addCase(getUsersLikedHomes.rejected, (state) => {
        state.isLoadingLikedHomes = false;
      });
  },
});

// Define the checkAppPlatformCookie function
const checkAppPlatformCookie = () => {
  if (typeof document !== "undefined") {
    const cookies = document.cookie.split("; ");
    const appPlatformCookie = cookies.find((cookie) =>
      cookie.startsWith("app-platform="),
    );
    if (appPlatformCookie) {
      const value = appPlatformCookie.split("=")[1];
      return value === "iOS App Store";
    }
  }
  return false;
};

// Create the thunk action
export const initializeIsRunningInNativeApp = createAsyncThunk(
  "user/initializeIsRunningInNativeApp",
  async (_, { dispatch }) => {
    // Wait for a short delay (e.g., 100ms) to ensure cookies are available
    await new Promise((resolve) => setTimeout(resolve, 100));
    const isRunningInNativeApp = checkAppPlatformCookie();
    dispatch(setIsRunningInNativeApp(isRunningInNativeApp));
  }
);


export const { setUserToken, setUserInfo, removeUserInfo, changeStatusInLikedHome, resetNumberOfHomesUnliked, setIsRunningServiceWorker, setIsRunningInNativeApp, setCardsOnboardingComplete } = userSlice.actions;
export default userSlice.reducer;
