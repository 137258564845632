import { useEffect, useLayoutEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMapsLibrary } from "@vis.gl/react-google-maps";
import ListItem from "./ListItem.jsx";
import DiscoverIcon from "@src/assets/svgs/DiscoverIcon";
import { openSearchPanel } from "@src/store/features/search/slice.js";
import { fetchRandomProperties } from "@src/store/features/search/service.js";
import { MdSearch } from "react-icons/md";
import SearchSpinner from "@src/components/searchSpinner/SearchSpinner.jsx"; // Import from the Google Maps library

function List() {
  const dispatch = useDispatch();
  const { randomProperties, properties, isFetchingRandomProperties } =
    useSelector((state) => state.search);
  const [streetViewUrls, setStreetViewUrls] = useState({}); // Store street view URLs for each property
  const [cards, setCards] = useState([]);
  const maps = useMapsLibrary("maps"); // Get access to Google Maps libraries

  // Fetch random properties on component mount
  useEffect(() => {
    if (properties.length === 0 && randomProperties.length === 0) {
      dispatch(fetchRandomProperties());
    }
  }, []);

  useLayoutEffect(() => {
    //when the redux store value of randomProperties changes, set the cards state to the new properties
    //we want to be dealing with the first 5 randomProperties at a time so we need to slice the properties array

    if (properties.length > 0) {
      setCards(properties);
    } else if (randomProperties.length > 0) {
      setCards(randomProperties);
    }
  }, [randomProperties, properties]);

  // If maps is null, render a loading state or wait for the library to be ready
  if (!maps) {
    return <div>Loading Google Maps library...</div>;
  }

  if (isFetchingRandomProperties) {
    return (
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-full">
        <SearchSpinner />
      </div>
    );
  }

  return (
    <div className="pt-18 flex w-full flex-1 flex-col justify-stretch overflow-y-auto">
      <div className="relative h-full w-full gap-6 overflow-y-auto px-4 pb-4 pt-20 md:pb-8 md:pt-24">
        <div className="search-list mx-auto max-w-6xl">
          {cards &&
            cards.length > 1 &&
            cards.map((property) => (
              <ListItem key={property.id} property={property} />
            ))}
        </div>

        {/*{cards && cards.length <= 1 && (*/}
        {/*  <div className="flex h-full flex-col items-center justify-center">*/}
        {/*    <div className="flex -translate-y-12 flex-col items-center">*/}
        {/*      <div className="flex h-28 w-28 items-center justify-center rounded-full bg-neutral-100">*/}
        {/*        <DiscoverIcon className="h-16 w-16 translate-y-1 fill-neutral-400" />*/}
        {/*      </div>*/}

        {/*      <h2 className="mt-2 text-lg font-light text-neutral-400">*/}
        {/*        No search results showing.*/}
        {/*      </h2>*/}
        {/*      <button*/}
        {/*        className="text-shadow-md mt-8 flex items-center gap-2 rounded-full bg-primary-500/90 p-3 px-8 pl-5 font-semibold text-white transition-colors md:hover:bg-primary-500"*/}
        {/*        onClick={() => {*/}
        {/*          dispatch(openSearchPanel());*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        <MdSearch className="h-[1.25rem] w-[1.25rem] fill-current opacity-80" />*/}
        {/*        <span>Start searching</span>*/}
        {/*      </button>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
    </div>
  );
}

export default List;
