import React from "react";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getProposalData } from "@src/api/chat";
import { USER_ID, PROPERTY_TO_SAVE } from "@src/utils/constants";
import OverlayLoader from "@src/components/overlayLoader/OverlayLoader";

const ChatViewAccess = ({ children }) => {
  const navigate = useNavigate();
  const { proposal_id } = useParams();
  const userID = JSON.parse(localStorage.getItem(USER_ID));
  const propertyId = JSON.parse(localStorage.getItem(PROPERTY_TO_SAVE));
  const [isLoading, setIsLoading] = useState(true);
  const [hasAccess, setHasAccess] = useState(false);
  const [proposalDetails, setProposalDetails] = useState(null);

  useEffect(() => {
    const checkAccess = async () => {
      try {
        const proposalDetails = await getProposalData(proposal_id);
        if (
          (proposalDetails.proposal_data.buyer_id == userID ||
            proposalDetails.proposal_data.owner_id == userID ||
            propertyId)
        ) {
          setHasAccess(true);
          setProposalDetails(proposalDetails);
        }
      } catch (error) {
        if (!propertyId) {
          navigate("/");
        }
        else
        {
          setHasAccess(true);
        }
      }
      setIsLoading(false);
    };
    checkAccess();
  }, [isLoading, proposal_id, userID]);

  if (isLoading) {
    return <OverlayLoader />;
  }

  if (hasAccess) {
    return React.cloneElement(children, { proposalDetails });
  }
  navigate("/");
  return null;
};

export default ChatViewAccess;
