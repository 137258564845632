import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App.jsx";
import "./index.css";
import { store } from "./store/store.js";
import * as Sentry from "@sentry/react";
// import Hotjar from "@hotjar/browser";
import TagManager from "react-gtm-module";
import ToastProvider from "./providers/Toast/Toast";
// import ReloadPrompt from "@src/components/appUpdater.jsx";
import { Initializer } from "@src/wrappers";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .getRegistrations()
    .then((registrations) => {
      for (let registration of registrations) {
        registration.unregister().then((boolean) => {
          if (boolean) {
            console.log("Service worker unregistered:", registration);
            // Optionally reload the page
            // window.location.reload();
          } else {
            console.log("Service worker unregistration failed:", registration);
          }
        });
      }
    })
    .catch((error) => {
      console.error("Error getting service worker registrations:", error);
    });
}

if ("caches" in window) {
  caches
    .keys()
    .then((cacheNames) => {
      cacheNames.forEach((cacheName) => {
        caches.delete(cacheName).then((boolean) => {
          if (boolean) {
            console.log("Cache deleted:", cacheName);
          } else {
            console.log("Cache not deleted:", cacheName);
          }
        });
      });
    })
    .catch((error) => {
      console.error("Error getting cache names:", error);
    });
}

Sentry.init({
  dsn: "https://ba159982f78cc7c36204b929f2cbe077@o4507894837280768.ingest.us.sentry.io/4507897884639232",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.feedbackIntegration({
      autoInject: false,
      colorScheme: "system",
      formTitle: "Give Feedback",
      enableScreenshot: true,
      addScreenshotButtonLabel: "Add Screenshot",
      messagePlaceholder:
        "Please describe the bug, update, or feature you would like to see.",
      successMessageText: "Thank you for your submission!",
      submitButtonLabel: "Submit",
      messageLabel: "Feedback",
      themeLight: {
        //accentForeground: "red",
        accentBackground: "rgb(142 153 145)",
      },
      themeDark: {
        //accentForeground: "red",
        accentBackground: "rgb(104 115 107)",
      },
    }),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception && event.event_id) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }
    return event;
  },
});

// const siteId = 5123673;
// const hotjarVersion = 6;

// Initializing with `debug` option:
// Hotjar.init(siteId, hotjarVersion, {
//   // debug: true
// });

const tagManagerArgs = {
  gtmId: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID,
};

//if not production use dev GTM environment
if (import.meta.env.MODE != "production") {
  tagManagerArgs.auth = "rM4Xlf_V64VZoZ-0Zv3ejA";
  tagManagerArgs.preview = "env-3";
}

TagManager.initialize(tagManagerArgs);

// setTimeout(() => {
//   const newPage = "/new";
//
//   Hotjar.stateChange(newPage);
//   const actionName = "subscribed";
//   Hotjar.event(actionName);
// }, 5000);

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <Initializer>
      {/*<ReloadPrompt />*/}
      <App />
      <ToastProvider />
    </Initializer>
  </Provider>,
);
