// import React, { useEffect } from "react";
// import { useDispatch } from "react-redux";
// import {
//   setIsRunningServiceWorker,
//   initializeIsRunningInNativeApp,
// } from "./store/features/user/slice";
import { APIProvider } from "@vis.gl/react-google-maps";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import {
  About,
  FAQs,
  FindMyHome,
  MainLayout,
  PropertyProfile,
  VerifyUser,
  CaliforniaPrivacyPolicy,
  Chat,
  ChatList,
  ContactUs,
  CreateProposal,
  Discover,
  InviteHomeOwner,
  LikedHomes,
  More,
  NotFound,
  PrivacyPolicy,
  ReportAbuse,
  SignIn,
  TermsOfUse,
} from "@src/pages";
import List from "./pages/discover/List.jsx";
import Map from "./pages/discover/Map.jsx";
import Cards from "./pages/discover/Cards.jsx";

import {
  ChatViewAccess,
  LoggedInRedirect,
  LoggedOutRedirect,
  SaveProperty,
} from "@src/wrappers";
import Header from "./components/header/Header.jsx";
import SearchPanel from "./components/search/SearchPanel.jsx";
// import ReloadPrompt from "./components/appUpdater.jsx";
import PageViewTracker from "./components/PageViewTracker";
import { SIGNIN_MODAL_TYPE } from "./utils/constants";

function App() {
  // const dispatch = useDispatch();
  //
  // // Dispatch the thunk action after store creation
  // dispatch(initializeIsRunningInNativeApp());

  // //keep isRunningServiceWorker updated if any changes
  // useEffect(() => {
  //   if ("serviceWorker" in navigator) {
  //     const updateIsRunningServiceWorker = () => {
  //       const isRunning = !!navigator.serviceWorker.controller;
  //       dispatch(setIsRunningServiceWorker(isRunning));
  //     };
  //
  //     // Initial check
  //     updateIsRunningServiceWorker();
  //
  //     // Listen for controllerchange events
  //     navigator.serviceWorker.addEventListener(
  //       "controllerchange",
  //       updateIsRunningServiceWorker,
  //     );
  //
  //     // Cleanup on unmount
  //     return () => {
  //       navigator.serviceWorker.removeEventListener(
  //         "controllerchange",
  //         updateIsRunningServiceWorker,
  //       );
  //     };
  //   }
  // }, [dispatch]);

  return (
    <APIProvider
      apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}
      libraries={["places", "maps"]}
    >
      <HelmetProvider>
        <Helmet>
          <title>Unlisted</title>
          <meta
            name="description"
            content="For the first time ever, easily explore off-market homes"
          />

          <meta property="og:title" content="Unlisted" />
          <meta
            property="og:description"
            content="For the first time ever, easily explore off-market homes"
          />
          <meta
            property="og:image"
            content="/default-property-share-image.jpg"
          />
          <meta property="og:type" content="website" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Unlisted" />
          <meta
            name="twitter:description"
            content="For the first time ever, easily explore off-market homes"
          />
          <meta
            name="twitt
          er:image"
            content="/default-property-share-image.jpg"
          />
        </Helmet>
        <div className="flex h-dvh flex-col overflow-x-clip overscroll-y-none overscroll-x-contain">
          <Router>
            <PageViewTracker />
            <Header />
            <SearchPanel />
            <Routes>
              <Route element={<MainLayout />}>
                <Route path="/" element={<Navigate to="/discover/cards" />} />
                <Route path="/about" element={<About />} />
                <Route path="/findmyhome" element={<FindMyHome />} />
                <Route path="/discover" element={<Discover />}>
                  <Route path="/discover/cards" element={<Cards />} />
                  <Route path="/discover/list" element={<List />} />
                  <Route path="/discover/map" element={<Map />} />
                </Route>
                <Route path="/faqs" element={<FAQs />} />
                <Route path="/contactus" element={<ContactUs />} />
                <Route path="/reportabuse" element={<ReportAbuse />} />
                <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                <Route
                  path="/terms-of-use"
                  element={
                    <div className="px-6 pb-12 pt-32">
                      <TermsOfUse />
                    </div>
                  }
                />
                <Route
                  path="/californiaprivacypolicy"
                  element={<CaliforniaPrivacyPolicy />}
                />
                <Route
                  path="/users/verify"
                  element={
                    <LoggedInRedirect>
                      <VerifyUser />
                    </LoggedInRedirect>
                  }
                />
                <Route
                  path="/signin"
                  element={
                    <LoggedInRedirect>
                      <SignIn />
                    </LoggedInRedirect>
                  }
                />
                <Route
                  path="/conversation/:proposal_id"
                  element={
                    <SaveProperty modalType={SIGNIN_MODAL_TYPE.CHAT}>
                      <ChatViewAccess>
                        <Chat />
                      </ChatViewAccess>
                    </SaveProperty>
                  }
                />
                <Route
                  path="/conversation_list"
                  element={
                    <LoggedOutRedirect>
                      <ChatList />
                    </LoggedOutRedirect>
                  }
                />
                <Route path="/more" element={<More />} />
                <Route
                  path="/profile/:id"
                  element={
                    <SaveProperty modalType={SIGNIN_MODAL_TYPE.PROPERTY}>
                      <PropertyProfile />
                    </SaveProperty>
                  }
                />
                <Route path="/invitations" element={<InviteHomeOwner />} />
                <Route
                  path="/invitations/:source"
                  element={<InviteHomeOwner isMassMailer />}
                />
                <Route
                  path="/conversations/create/:id"
                  element={
                    <LoggedOutRedirect>
                      <CreateProposal />
                    </LoggedOutRedirect>
                  }
                />
                <Route
                  path="/liked"
                  element={
                    <LoggedOutRedirect>
                      <LikedHomes />
                    </LoggedOutRedirect>
                  }
                />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </Router>
        </div>
      </HelmetProvider>
    </APIProvider>
  );
}

export default App;
