import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import property_perks from "../../data/property_perks.js";
import SelectableTag from "../SelectableTag.jsx";
import { togglePropertyPerk } from "../../store/features/search/slice.js";
import { setPropertyPropValue } from "../../store/features/property/slice.js";

function UpdatePropertyProfilePerks({ property, onChange }) {
  const dispatch = useDispatch();
  const [selectedPropertyPerks, setSelectedPropertyPerks] = useState([]);
  const [ownerPerks, setOwnerPerks] = useState(property.owner_perks);

  useEffect(() => {
    setSelectedPropertyPerks(
      property_perks
        .filter(
          (prop) =>
            ownerPerks?.[prop.id] === true ||
            (property[prop.id] === true && ownerPerks?.[prop.id] !== false),
        )
        .map((perk) => perk.id),
    );
    dispatch(setPropertyPropValue({ key: "owner_perks", value: ownerPerks }));
  }, [ownerPerks]);

  const handleTagChange = async (id) => {
    let newOwnerPerks = { ...ownerPerks };

    if (newOwnerPerks[id] === undefined && property[id] === true) {
      newOwnerPerks[id] = true;
    }

    if (newOwnerPerks[id] === undefined) {
      newOwnerPerks[id] = true;
    } else if (newOwnerPerks[id] === true) {
      newOwnerPerks[id] = false;
    } else if (ownerPerks[id] === false) {
      newOwnerPerks[id] = true;
    }

    setOwnerPerks(newOwnerPerks);
    onChange();
  };

  const isPerkSelected = (id) => {
    return selectedPropertyPerks.includes(id);
  };

  return (
    <div className="">
      <div className="flex flex-row flex-wrap items-start justify-center gap-4 gap-x-3 p-10">
        {property_perks.map((perk, index) => {
          return (
            <SelectableTag
              id={perk.id}
              name={`update_${perk.id}`}
              emoji={perk.emoji}
              text={perk.text}
              key={index}
              selectable={true}
              selected={isPerkSelected(perk.id)}
              onChange={handleTagChange}
            />
          );
        })}
      </div>
    </div>
  );
}

export default UpdatePropertyProfilePerks;
