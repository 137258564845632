import { getRequest, postRequest } from "@src/utils/axiosClient";

export const apiSearchProperties = async (searchData) => {
  let response;
  if (searchData.address) {
    response = await postRequest({
      url: `${import.meta.env.VITE_API_URL}/properties/search`,
      payload: searchData,
    });
    return { properties: [response.data] };
  } else if (searchData.state || searchData.city || searchData.zip) {
    response = await postRequest({
      url: `${import.meta.env.VITE_API_URL}/properties/searchv1`,
      payload: searchData,
    });
    return response.data;
  } else if (searchData.question) {
    response = await getRequest({
      url: `${import.meta.env.VITE_API_URL}/properties/searchv2`,
      params: {
        question: searchData.question,
      },
    });
    return response.data;
  } else {
    response = await postRequest({
      url: `${import.meta.env.VITE_API_URL}/properties/searchv1`,
      payload: {
        page: 0,
        exact_lat: searchData.exact_lat,
        exact_long: searchData.exact_long,
        radius: "5",
      },
    });
    return response.data;
  }
};

export const apiFetchRandomProperties = async () => {
  function getRandomUSCoordinates() {
    // Latitude range for the contiguous US is approximately 24.396308 to 49.384358
    const minLat = 24.396308;
    const maxLat = 49.384358;

    // Longitude range for the contiguous US is approximately -125.000000 to -66.934570
    const minLng = -125.0;
    const maxLng = -66.93457;

    // Generate random latitude and longitude within the specified ranges
    const latitude = Math.random() * (maxLat - minLat) + minLat;
    const longitude = Math.random() * (maxLng - minLng) + minLng;

    return { latitude, longitude };
  }

  const { latitude, longitude } = getRandomUSCoordinates();

  let response = await getRequest({
    url: `${import.meta.env.VITE_API_URL}/properties/random`,
    params: {
      page: 0,
      exact_lat: 41.8781136,
      exact_long: -87.6297982,
      radius: "5",
    },
  });

  return response.data;
};
