import React from "react";

function PropertyProfileHeadline({ property, className }) {
  return (
    <h2 className={className}>
      {property.owner_headline ? (
        <p>{property.owner_headline}</p>
      ) : (
        <p className="capitalize">{`${property.city ? property.city.toLowerCase() + ", " : " "}${property.state ? property.state + " " : ""}${property?.zip ? property.zip : ""}`}</p>
      )}
    </h2>
  );
}

export default PropertyProfileHeadline;
