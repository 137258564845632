import React, { useState, useRef, useEffect } from "react";
import architectural_styles from "../../data/architectural_styles.js";
import { MdCheck } from "react-icons/md";

function SelectArchitecturalStyles({
  value,
  onChange,
  prefix,
  allowMultiple = false,
}) {
  console.log("value", value);
  const [selectedArchitectureStyles, setSelectedArchitectureStyles] =
    useState(value);

  const toggleArchitecturalStyle = (styleId) => {
    let newStyles = [];
    if (!allowMultiple) {
      if (selectedArchitectureStyles.includes(styleId)) {
        newStyles = [];
      } else {
        newStyles = [styleId];
      }
    } else {
      if (selectedArchitectureStyles.includes(styleId)) {
        newStyles = selectedArchitectureStyles.filter((id) => id !== styleId);
      } else {
        newStyles = [...selectedArchitectureStyles, styleId];
      }
    }

    setSelectedArchitectureStyles(newStyles);
    if (onChange) {
      const styleIds = newStyles.map((id) => {
        const style = architectural_styles.find((style) => style.id === id);
        return style.id;
      });

      onChange(styleIds);
    }
  };

  const onStyleChanged = (e) => {
    const styleId = parseInt(e.target.dataset.styleId);
    toggleArchitecturalStyle(styleId);
  };

  const isSelectedStyle = (styleId) => {
    return selectedArchitectureStyles.includes(styleId);
  };

  return (
    <div className="flex flex-col items-center">
      <div className="grid w-[min(calc(100%-4rem),64rem)] auto-rows-[minmax(6rem,_1fr)] grid-cols-[repeat(auto-fit,_minmax(9rem,_1fr))] gap-3 gap-y-5 py-6 sm:pt-16 md:grid-cols-[repeat(auto-fit,_minmax(10rem,_1fr))] md:gap-8 md:gap-y-10">
        {architectural_styles.map((style) => (
          <div
            key={style.id}
            className="relative h-full w-fit overflow-hidden rounded-2xl text-center"
          >
            <input
              data-style-id={style.id}
              checked={isSelectedStyle(style.id)}
              id={`${prefix}_${style.id}`}
              className="peer absolute hidden"
              type="checkbox"
              onChange={(e) => onStyleChanged(e)}
            />
            <label
              htmlFor={`${prefix}_${style.id}`}
              className="max-w-30 relative block flex w-full cursor-pointer flex-col items-center justify-center gap-2 p-6 pb-2 pt-3 font-content"
            >
              <img
                src={style.image}
                alt={style.label}
                className="w-full mix-blend-darken"
              />
              <span className="text-xs leading-5 text-neutral-700">
                {style.label}
              </span>
            </label>
            <div className="pointer-events-none absolute left-0 top-0 z-10 h-full w-full bg-primary-500 opacity-0 mix-blend-multiply shadow-custom-inset transition-all peer-checked:opacity-20"></div>
            <div className="pointer-events-none absolute right-2 top-2 h-fit w-fit rounded-full bg-primary-500/20 p-1 opacity-0 peer-checked:opacity-100">
              <MdCheck className="opacity-60" />
            </div>
          </div>
        ))}
        {/*<div>*/}
        {/*  {chunkedStyles.map((chunk, index) => (*/}
        {/*    <div key={index} style={sheetStyle}>*/}
        {/*      {chunk.map((style) => (*/}
        {/*        <div*/}
        {/*          key={style.id}*/}
        {/*          className="relative h-full w-fit overflow-hidden rounded-2xl"*/}
        {/*        >*/}
        {/*          <input*/}
        {/*            data-style-id={style.id}*/}
        {/*            checked={isSelectedStyle(style.id)}*/}
        {/*            id={`${prefix}_${style.id}`}*/}
        {/*            className="peer absolute hidden"*/}
        {/*            type="checkbox"*/}
        {/*            onChange={(e) => onStyleChanged(e)}*/}
        {/*          />*/}
        {/*          <label*/}
        {/*            htmlFor={`${prefix}_${style.id}`}*/}
        {/*            className="max-w-30 relative flex w-full cursor-pointer flex-col items-center justify-center gap-2 p-6 pb-2 pt-3 font-content"*/}
        {/*          >*/}
        {/*            <img*/}
        {/*              src={style.image}*/}
        {/*              alt={style.label}*/}
        {/*              className="w-full mix-blend-darken"*/}
        {/*            />*/}
        {/*            <span className="text-sm text-gray-500">{style.label}</span>*/}
        {/*          </label>*/}
        {/*          <div className="pointer-events-none absolute left-0 top-0 z-10 h-full w-full bg-primary-500 opacity-0 mix-blend-multiply shadow-custom-inset transition-all peer-checked:opacity-20"></div>*/}
        {/*          <div className="pointer-events-none absolute right-2 top-2 h-fit w-fit rounded-full bg-primary-500/20 p-1 opacity-0 peer-checked:opacity-100">*/}
        {/*            <MdCheck className="opacity-60" />*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      ))}*/}
        {/*    </div>*/}
        {/*  ))}*/}
        {/*</div>*/}
        {/* Dots indicator */}
        {/*<div className="sticky bottom-0 left-0 flex h-14 w-full items-center justify-center gap-2">*/}
        {/*  {chunkedStyles.map((_, index) => (*/}
        {/*    <div*/}
        {/*      key={index}*/}
        {/*      onClick={() => {*/}
        {/*        setActiveSheet(index);*/}
        {/*        containerRef.current.scrollTo({*/}
        {/*          left: window.innerWidth * index,*/}
        {/*          behavior: "smooth",*/}
        {/*        });*/}
        {/*      }}*/}
        {/*      className={`h-2 w-2 rounded-full ${*/}
        {/*        index === activeSheet*/}
        {/*          ? "bg-primary-500 ring-1 ring-primary-500/50 ring-offset-1"*/}
        {/*          : "bg-neutral-300"*/}
        {/*      }`}*/}
        {/*    ></div>*/}
        {/*  ))}*/}
        {/*</div>*/}
      </div>
    </div>
  );
}

export default SelectArchitecturalStyles;
