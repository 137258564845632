import Header from "@src/components/header/Header";
import PropertyProfileContent from "@src/components/propertyProfile/propertyProfileContent/PropertyProfileContent";

function PropertyProfileModal({ property }) {
  return (
    <div className="fixed h-full w-full overflow-y-auto bg-white">
      <Header theme="dark" mode="modal" />
      <PropertyProfileContent property={property} />
    </div>
  );
}

export default PropertyProfileModal;
