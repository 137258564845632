export default function TermsOfUse() {
  const sectionTitleStyles = "text-xl mb-4 font-attention text-gray-900";
  const sectionDescriptionStyles = "mb-4 font-content text-gray-900";

  return (
    <div className="mx-auto w-[min(calc(100%-4rem),80ch)]">
      <p className="md:leading-[4rem]d py-2 font-attention text-3xl leading-tight md:text-4xl">
        Terms & Conditions
      </p>
      <p className="mb-4 font-medium uppercase">
        PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY. BY ACCESSING AND USING
        THIS WEBSITE, YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS BELOW.
        THESE TERMS AND CONDITIONS ARE SUBJECT TO CHANGE. ANY CHANGES WILL BE
        POSTED TO THIS WEBSITE FROM TIME TO TIME. IF YOU DO NOT AGREE TO THE
        FOLLOWING TERMS AND CONDITIONS, PLEASE LEAVE THIS WEBSITE IMMEDIATELY.
      </p>
      <p className={sectionDescriptionStyles}>
        You are currently viewing and using a website or mobile app (the “Site”)
        owned and administered by Unlisted Software Inc., located in Ohio
        (“Unlisted,” “we,” “us,” “our”). This Site and any services we provide
        in connection with this Site (“Services”) are subject to the following
        terms and conditions (“Terms of Use”). By accessing the Site, you
        (“User”) acknowledge that you have read, understand, and agree to be
        bound by these Terms of Use.
      </p>

      <p className={sectionTitleStyles}>Changes and Modification</p>
      <p className={sectionDescriptionStyles}>
        Unlisted reserves the right, in its sole discretion, to temporarily or
        permanently modify these Terms of Use for any reason and without advance
        notice to you. Please review these Terms of Use from time to time,
        because your continued use of the Site after any modifications have
        become effective shall be deemed your conclusive acceptance of the
        modified Terms of Use.
      </p>
      <p className={sectionTitleStyles}>General Eligibility</p>

      <p className={sectionDescriptionStyles}>
        This Site is intended for the sole use of individuals who are residents
        of the United States and the age of majority in their state of
        residence. If you are not a United States resident, or you are under the
        age of 18 or any higher age of majority in your state of residence, your
        access and use of the Site violates these Terms of Use and you must
        leave the Site now. By using the Site, you represent that you are a
        United States resident and the age of majority in your state of
        residence and you agree to abide by all terms and conditions in these
        Terms of Use. Unauthorized use of the Site or Services, including
        unauthorized access of Unlisted systems and misuse of passwords or Site
        information is strictly prohibited.
      </p>

      <p className={sectionTitleStyles}>Privacy</p>

      <p className={sectionDescriptionStyles}>
        Your privacy is very important to us. Please carefully read Unlisted’s
        Online Privacy Policy.
      </p>

      <p className={sectionTitleStyles}>Service Rights and Restrictions</p>
      <p className={sectionDescriptionStyles}>
        You understand and agree that we are not recommending, endorsing, or
        referring you to any particular third party in connection with any
        Services we provide through the Site or otherwise. You are solely
        responsible for deciding whether to use the services of any third party
        and for the terms and conditions that apply to your relationship with
        any third party. All material and content on this Site, including but
        not limited to text, data, articles, designs, software, photos, images,
        and other 1 Annotation information (collectively the “Platform Content”)
        are the proprietary property of Unlisted, with all rights reserved,
        except as otherwise noted on the Site. Platform Content may not be
        copied, reproduced, distributed, republished, displayed, posted,
        transmitted, or sold in any form or by any means without Unlisted’s
        express prior written consent. You acknowledge that all Platform Content
        is and shall remain the sole property of Unlisted, except for any
        content that we have identified as belonging to other parties. You may
        access the Site and use the Services only for their intended purpose.
        Any use of the Site or Unlisted’s Services that is not expressly
        authorized by the Terms of Use is strictly prohibited.
      </p>
      <p className={sectionTitleStyles}>Acceptable Use</p>
      <div className={sectionDescriptionStyles}>
        <p className={sectionDescriptionStyles}>
          You agree to abide by all applicable laws and regulations in your use
          of the Site and the Services available through the Site. You accept
          sole responsibility for all of your activities using the Site. You
          agree not to engage in any of the following activities, whether on or
          off the Site or our Services:
        </p>
        <ul className="mb-6 list-disc pl-6">
          <li>
            You agree not to use technology or other means not authorized by us
            to access the Site Information or our systems;
          </li>
          <li>
            You agree not to use or launch any manual or automated device or
            system, including “robots,” “spiders,” or “offline readers,” to (i)
            retrieve, index, “scrape,” “data mine,” access or otherwise gather
            any Site Information or our systems, (ii) reproduce or circumvent
            the navigational structure or presentation of the Site, or (iii)
            otherwise harvest or collect information about users of the Site;
          </li>
          <li>
            You agree not to use the Site or our products or Services in a
            manner that could damage, disable, or overburden or impair the Site
            or interfere with any other party’s use of the Site, including their
            ability to engage in real time activities through the Site
          </li>
          <li>
            You agree not to reverse engineer, decompile or disassemble any
            portion of the Site, except where such restriction is expressly
            permitted by applicable law;
          </li>
          <li>
            You agree not to attempt to introduce viruses or any other computer
            code, files, or programs that interrupt, destroy, or limit the
            functionality of any software, hardware, or telecommunications
            equipment;
          </li>
          <li>
            You agree not to attempt to gain unauthorized access to our computer
            network or user accounts;
          </li>
          <li>
            You agree not to encourage conduct that would constitute a criminal
            offense or that would give rise to civil liability;
          </li>
          <li>
            You agree not to harass, abuse, stalk, threaten, defame, or
            otherwise infringe or violate the rights of us, other users, or any
            other party (including rights of publicity or other proprietary
            rights);
          </li>
          <li>
            You agree not to impersonate any person or entity, or falsely state
            or otherwise misrepresent yourself, your age, or your affiliation
            with any person or entity;
          </li>
          <li>
            You agree not to spam, solicit money from or defraud any users;
          </li>
          <li>
            You agree not to engage in unlawful, fraudulent, or deceptive
            conduct;
          </li>
          <li>
            You agree not to attempt to damage, disable, overburden, or impair
            our servers or networks;
          </li>
          <li>
            You agree not to reproduce, modify, adapt, translate, create
            derivative works of, sell, rent, lease, loan, distribute or
            otherwise exploit any portion of (or any use of) the Site except as
            expressly authorized herein, without our express prior written
            consent;
          </li>
          <li>
            You agree not to violate any applicable third-party terms; and
          </li>
          <li>
            You agree not to engage in conduct that otherwise violates these
            Terms.
          </li>
        </ul>
      </div>
      <p className={sectionTitleStyles}>
        Safety and Interactions with Other Users
      </p>
      <div className={sectionDescriptionStyles}>
        <p className={sectionDescriptionStyles}>
          Though Unlisted strives to encourage a respectful user experience by
          connecting users and allowing users to communicate with each other
          only after both parties have expressed interest, Unlisted is not
          responsible for the conduct of any user, on or off of the Service.
          <p className="mb-2 font-content font-bold">
            You agree to use caution in all interactions with other users,
            users, particularly if you decide to communicate off the Service or
            meet in person. You agree that you will not provide your financial
            information (for example, your credit card or bank account
            information), or wire or otherwise send money to other users.
          </p>
        </p>
        <p className="uppercase">
          <p className="mb-2 font-content font-bold">
            YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER USERS.
            YOU UNDERSTAND THAT UNLISTED DOES NOT CONDUCT CRIMINAL BACKGROUND
            CHECKS ON ITS USERS OR OTHERWISE INQUIRE INTO THE BACKGROUND OF ITS
            USERS.
          </p>{" "}
          UNLISTED RESERVES THE RIGHT TO CONDUCT— AND YOU AUTHORIZE UNLISTED TO
          CONDUCT—ANY CRIMINAL BACKGROUND CHECK OR OTHER SCREENINGS (SUCH AS SEX
          OFFENDER REGISTER SEARCHES) AT ANY TIME USING AVAILABLE PUBLIC RECORDS
          OBTAINED BY IT OR WITH THE ASSISTANCE OF A CONSUMER REPORTING AGENCY,
          AND YOU AGREE THAT ANY INFORMATION YOU PROVIDE MAY BE USED FOR THAT
          PURPOSE.
        </p>
      </div>
      <p className={sectionTitleStyles}>Consent to Communications</p>
      <p className={sectionDescriptionStyles}>
        Providing your telephone contact information to us, and accepting these
        Terms of Use by your access to and use of the Site and our Services,
        means that you have consented in writing to receive SMS communications
        (text messages) from us. When consenting to messaging notifications, you
        authorize us and our assigns and successors to send SMS Notifications
        (as defined below) to any phone number provided to us or to our service
        providers, assigns, and successors. As used in this text messaging
        disclosure, “SMS Account Notifications” means any SMS (text message)
        communications from us to you pertaining to your account that is sent to
        the phone number provided.
      </p>
      <p className={sectionDescriptionStyles}>
        How to Unsubscribe: You may withdraw your consent to receive SMS Account
        Notifications by replying with “STOP, END, CANCEL. UNSUBSCRIBE, or QUIT”
        or by emailing us at unsubscribe@unlistedinc.com at any time. We may
        treat your provision of an invalid mobile phone number, or the
        subsequent malfunction of a previously valid mobile phone number, as a
        withdrawal of your consent to receive SMS Account Notifications. Any
        withdrawal of your consent to use SMS Account Notifications will be
        effective only after we have a reasonable period of time to process your
        withdrawal.
      </p>
      <p className={sectionDescriptionStyles}>
        To request additional information, contact us by email at
        hello@unlistedinc.com To access, view, and retain SMS Account
        Notifications that we make available to you, you must have: (i) a
        SMS-capable mobile phone, (ii) an active mobile phone account with a
        communication service provider; and (iii) sufficient storage capacity on
        your mobile phone.
      </p>
      <p className={sectionDescriptionStyles}>
        All SMS Account Notifications in electronic format from us to you will
        be considered “in writing.”
      </p>
      <p className={sectionDescriptionStyles}>
        We do not assess a service fee for sending SMS Account Notifications,
        but you are responsible for any and all charges imposed by your own
        communications service provider (including but not limited to fees
        associated with text messaging). Please consult your mobile service
        carrier’s pricing plan to determine the charges for sending and
        receiving text messages. These charges will appear on your phone bill.
        Message frequency depends on account status or settings. We may modify
        or terminate our text messaging services from time to time, for any
        reason, and without notice, including the right to terminate text
        messaging with or without notice, without liability to you.
      </p>
      <p className={sectionDescriptionStyles}>
        You accept and agree to comply with this Agreement by submitting a
        property address and/or valid email address or otherwise indicating your
        agreement to abide by these Terms. As an authorized user, you hereby
        consent to the registration and future use of your preferences and email
        address for Unlisted Software, Inc.’s business and promotional purposes.
        You may withdraw consent at any time by sending an email to:
        compliance@unlistedinc.com.
      </p>

      <p className={sectionTitleStyles}>Links to Third-Party Sites</p>
      <p className={sectionDescriptionStyles}>
        We are not responsible for the information practices employed by
        websites linked to or from the Site. In most cases, links to third-party
        websites are provided solely as pointers to information on topics that
        may be useful to our users. Since third-party websites may have
        different privacy policies and/or security standards governing their
        websites, we advise you to review the privacy policies and the terms and
        conditions of these websites before providing any personal information.
      </p>
      <p className={sectionTitleStyles}>Potential Disruption of Service</p>

      <p className={sectionDescriptionStyles}>
        The Site may be unavailable or may not work properly due to factors that
        include but not limited to the following: software or hardware failure;
        severe weather; earthquakes; wars; insurrection; riots; civil commotion;
        acts of God; accident; damage; natural disasters; interruption of power;
        governmental or regulatory restrictions; or other causes beyond
        Unlisted’s reasonable control.
      </p>

      <p className={sectionTitleStyles}>Disclaimer of Warranties; Errors</p>

      <p className={sectionDescriptionStyles}>
        UNLISTED PROVIDES THE WEBSITE AND SERVICES ON AN “AS IS” AND “AS
        AVAILABLE” BASIS, WITHOUT ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING
        BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE, AND NON-INFRINGEMENT. ADDITIONALLY, UNLISTED MAKES
        NO CLAIM OR GUARANTEE AS TO THE ACCURACY OF ANY INFORMATION PROVIDED OR
        WEBSITE CONTENT.
      </p>
      <p className={sectionDescriptionStyles}>
        UNLISTED DISCLAIMS AND TAKES NO RESPONSIBILITY FOR ANY CONDUCT OF YOU OR
        ANY OTHER USER, ON OR OFF THE SERVICE.
      </p>
      <p className={sectionDescriptionStyles}>
        We do not warrant the accuracy, adequacy, or completeness of the
        information provided on the Site and we expressly disclaim liability for
        any errors or omissions in such information. We do not guarantee
        continuous, uninterrupted, or secure access to the Site or any of our
        Services, and the operation of the Site may be interfered with by
        numerous factors outside our control. We do not guarantee or promise any
        specific results from use of the Site and/or our products and services.
        You agree that Unlisted may promptly correct any error that we discover.
      </p>
      <p className={sectionTitleStyles}>Limitation of Liabilities</p>

      <p className={sectionDescriptionStyles}>
        IN NO EVENT WILL UNLISTED OR ITS DIRECTORS, OFFICERS, EMPLOYEES, OR
        AGENTS BE LIABLE TO YOU OR TO ANY OTHER PARTY FOR LOST PROFITS OR ANY
        OTHER SPECIAL, CONSEQUENTIAL, INCIDENTAL, OR INDIRECT DAMAGES ARISING
        FROM YOUR USE OF THE WEBSITE OR SERVICES, EVEN IF UNLISTED IS AWARE OR
        HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. TO THE EXTENT
        ALLOWED BY APPLICABLE LAW, UNLISTED’S LIABILITY TO YOU FOR ANY CAUSE OF
        ACTION REGARDLESS OF FORM WILL AT ALL TIMES BE LIMITED TO THE AMOUNT
        PAID BY YOU TO UNLISTED, IF ANY, AND IN NO CASE SHALL UNLISTED’S
        LIABILITY TO YOU EXCEED $1,000.00.
      </p>

      <p className={sectionTitleStyles}>Intellectual Property</p>

      <p className={sectionDescriptionStyles}>
        You agree that the Site and all logos related to our products and
        Services are ours, and relevant licensors’ trademarks or registered
        trademarks, respectively. Nothing on the Site should be construed as
        granting the User any license or right to use any trademark, whether
        owned by Unlisted or a third party, displayed on the Site, without prior
        written approval of the trademark owner. You may not copy, duplicate,
        display, distribute, modify, reproduce, imitate or use any trademark
        found on the Site without prior written consent of the trademark’s
        owner. In addition, all page headers, custom graphics, button icons, and
        scripts are our service marks, trademarks, and/or trade dress. All
        right, title and interest in and to the Site, any content thereon, our
        products and services, the technology related to our products and
        services, and any and all technology and any content created or derived
        from any of the foregoing is our or our licensors’ exclusive property.
      </p>
      <p className={sectionTitleStyles}>Indemnification</p>

      <p className={sectionDescriptionStyles}>
        You agree to indemnify, defend, and hold harmless Unlisted and their
        respective affiliates, officers, directors, employees, agents, and
        representatives for damages, losses, liabilities, settlements and
        expenses (including without limitation costs and attorneys’ fees),
        arising in connection with any claim, suit, proceeding, or other action
        arising from your use of the Site or Services, your conduct in
        connection with your use of the Site or Services, or any violation of
        these Terms of Use or of any law or the rights of any third party.
      </p>

      <p className={sectionTitleStyles}>Termination</p>

      <p className={sectionDescriptionStyles}>
        Subject to applicable law and the terms of any Services, we reserve the
        right, in our reasonable discretion, to terminate your use of the Site,
        your user account, or any Service provided to you and to assert our
        legal rights with respect to content or use of the Site that we
        reasonably believe is, or might be, in violation of these Terms of Use,
        including actions or communications that occur on or off the Site or
        Service. Upon such termination, you will not be entitled to any refund
        for purchases.
      </p>
      <p className={sectionTitleStyles}>Miscellaneous</p>
      <div className={sectionDescriptionStyles}>
        <p className="mb-2 font-attention">Choice of Law </p>
        <div className={sectionDescriptionStyles}>
          <p>
            These Terms of Use shall be governed by the laws of the State of
            Ohio, without regard to any conflicts of law provisions.
          </p>
        </div>
        <p className="mb-2 font-attention">Entire Agreement</p>
        <div className={sectionDescriptionStyles}>
          <p>
            These Terms of Use constitute the entire agreement and understanding
            between the parties with respect to their subject matter and may not
            be contradicted by evidence of any prior or contemporaneous oral or
            written agreement. These Terms of Use create no third party
            beneficiary rights.
          </p>
        </div>
        <p className="mb-2 font-attention">Severability</p>
        <div className={sectionDescriptionStyles}>
          <p>
            If any provision of these Terms of Use is held by a court of
            competent jurisdiction to be unenforceable for any reason, such
            provision shall be changed and interpreted so as to best accomplish
            the objectives of the original provision to the fullest extent
            allowed by law and the remaining provisions of these Terms of Use
            shall remain in full force and effect
          </p>
        </div>
        <p className="mb-2 font-attention">Waiver</p>
        <div className={sectionDescriptionStyles}>
          <p>
            Unlisted’s failure or delay in exercising any right, power, or
            remedy under these Terms of Use shall not operate as a waiver of any
            such right, power, or remedy.
          </p>
        </div>
        <p className="mb-2 font-attention">Contacting Us</p>
        <div className={sectionDescriptionStyles}>
          <p>
            If you have questions, comments, or complaints regarding these Terms
            of Use, the Site, or Services, you may email us at
            hello@unlistedinc.com.
          </p>
        </div>
      </div>
    </div>
  );
}
