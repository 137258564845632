import React, { useState, useEffect } from "react";

function PropertyProfileEditorIncrementer({ prop, onChange }) {
  const {
    value,
    labelSingular,
    labelPlural,
    icon: Icon,
    min,
    interval = 1,
    roundTo,
  } = prop;
  const [inputValue, setInputValue] = useState(value);

  // Sync the local state with the prop value
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleIncrement = () => {
    let newValue = inputValue + interval;
    if (roundTo) {
      newValue = Math.round(newValue / roundTo) * roundTo;
    }
    setInputValue(newValue);
    if (onChange) {
      onChange(newValue); // Call the parent onChange with new value
    }
  };

  const handleDecrement = () => {
    let newValue = inputValue > 0 ? inputValue - interval : 0; // Prevent negative values
    if (roundTo) {
      newValue = Math.round(newValue / roundTo) * roundTo; // Round to the nearest roundTo value
    }
    newValue = newValue < min ? min : newValue;
    setInputValue(newValue); // Prevent values below the minimum
    if (onChange) {
      onChange(newValue); // Call the parent onChange with new value
    }
  };

  return (
    <div className="flex w-full items-center justify-between gap-2">
      <div className="flex items-center gap-5">
        {Icon && <Icon className="h-8 w-8 fill-neutral-400" />}
        <span className="text-lg font-semibold capitalize text-neutral-700">
          {inputValue} {inputValue <= 1 ? labelSingular : labelPlural}
        </span>
      </div>
      <div className="flex select-none">
        <button
          onClick={handleDecrement}
          className="h-12 w-12 rounded-bl-lg rounded-tl-lg border border-neutral-300 border-r-transparent bg-neutral-100 font-content text-2xl font-light text-neutral-500"
        >
          -
        </button>
        <button
          onClick={handleIncrement}
          className="h-12 w-12 rounded-br-lg rounded-tr-lg border border-neutral-300 bg-neutral-100 font-content text-2xl font-light text-neutral-600"
        >
          +
        </button>
      </div>
    </div>
  );
}

export default PropertyProfileEditorIncrementer;
