import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setArchitecturalStyles,
  toggleArchitecturalStyle,
} from "../../store/features/search/slice.js";
import SelectArchitecturalStyles from "@src/components/selectArchitecturalStyles/SelectArchitecturalStyles.jsx";

function SearchArchitecturalStyles() {
  const { selectedArchitecturalStyles } = useSelector((state) => state.search);
  const dispatch = useDispatch();

  return (
    <SelectArchitecturalStyles
      allowMultiple={true}
      prefix="search"
      value={selectedArchitecturalStyles}
      onChange={(styles) => dispatch(setArchitecturalStyles(styles))}
    />
  );
}

export default SearchArchitecturalStyles;
